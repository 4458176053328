import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'website'
})
export class WebsitePipe implements PipeTransform {
  transform(websiteUrl: string): string {
    if (websiteUrl === null || websiteUrl.toString().length == 0) return '';
    const value = websiteUrl.toString().trim().replace(/^\+/, '');
    return `<a href="${value}" target="_blank"><i class="fa-light fa-link"></i> ${value}</a>`;
  }
}
