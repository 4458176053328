import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateLocationCommand,
  CreateLocationCommandResult,
  LocationService,
  ReadLocationCommandResult,
  UpdateLocationCommand,
  UpdateLocationCommandResult
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength, ZipCodeRegex } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { patternValidator } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-locations-detail',
  templateUrl: './locations-detail.component.html',
  styleUrls: ['./locations-detail.component.scss']
})
export class LocationsDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readLocationCommandResult: ReadLocationCommandResult = {};
  dataModel: ReadLocationCommandResult;
  updateLocationCommand: UpdateLocationCommand = {};
  createLocationCommand: CreateLocationCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  get address1() {
    return this.form.get('address1');
  }

  get address2() {
    return this.form.get('address2');
  }

  get city() {
    return this.form.get('city');
  }

  get stateProvince() {
    return this.form.get('stateProvince');
  }

  get postalCode() {
    return this.form.get('postalCode');
  }

  get country() {
    return this.form.get('country');
  }

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private locationService: LocationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.LOCATIONS, injector);
  }

  public ngOnInit() {
    this._createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      organizationId: [''],
      name: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      address1: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      address2: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      city: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      stateProvince: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      postalCode: ['', [patternValidator(ZipCodeRegex)]],
      country: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });

    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadLocationCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.locationService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadLocationCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, result.name);
        this.logger.debug(result);
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateLocationCommand = this.form.value;
      this.logger.debug(this.updateLocationCommand);
      this.locationService
        .update(this.updateLocationCommand.id.toString(), this.updateLocationCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: UpdateLocationCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createLocationCommand = this.form.value;
      this.logger.debug(this.createLocationCommand);
      this.locationService
        .create(this.createLocationCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: CreateLocationCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this location?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
