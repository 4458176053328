import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  CancelEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
  SelectAllCheckboxState,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  CreateSupplyCommand,
  EnumListResult,
  EnumValue,
  ListInventoryItemLocationQuantitiesForInventoryItemCommand,
  ListInventoryItemLocationQuantitiesForInventoryItemCommandResult,
  LocationService,
  OrganizationService,
  ReadSupplyCommandResult,
  RoomService,
  SupplyLocationQuantityService,
  SupplyService,
  UpdateSupplyLocationQuantityCommand,
  UpdateSupplyCommand,
  UpdateSupplyLocationQuantityCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { CachedDataService, ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-room-inventory-item-stock-list',
  templateUrl: './room-inventory-item-stock-list.component.html',
  styleUrls: ['./room-inventory-item-stock-list.component.scss']
})
export class RoomInventoryItemStockListComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    public locationService: LocationService,
    public buildingService: BuildingService,
    public roomService: RoomService,
    public bedService: BedService,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private SupplyService: SupplyService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    private _breadcrumbService: BreadcrumbsService,
    private _title: Title,
    private loadingBar: LoadingBarService,
    private qrLabelService: QrLabelService,
    injector: Injector
  ) {
    super(EntityGlobals.SUPPLIES, injector);
    this.editMode = false;
    this.$inventoryItemTypes = this.SupplyService.listSupplyTypes();
  }

  ReadSupplyCommandResult: ReadSupplyCommandResult = {};
  dataModel: ReadSupplyCommandResult;
  UpdateSupplyCommand: UpdateSupplyCommand = {};
  CreateSupplyCommand: CreateSupplyCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  inventoryItemTaskTypes: EnumValue[] = [];
  public $inventoryItemTypes: Observable<EnumListResult>;
  public formGroup: FormGroup;
  public locationsAndStocks: Observable<GridDataResult>;
  private editedRowIndex: number;
  qrLink: string;
  isQrModalVisible = false;
  public listInventoryItemLocationQuantitiesForInventoryItemCommandResult: Observable<ListInventoryItemLocationQuantitiesForInventoryItemCommandResult>;
  gridData: GridDataResult = { data: [], total: 0 };
  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'locationName' }];

  ngOnInit() {
    this.fromKey = 'FormModel';
    if (this.editMode) {
    } else {
      this.showForm = true;
    }
    this.refreshDataOnPage();
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.logger.debug('dataItem', dataItem);

    const objectKeys = Object.keys(dataItem) as string[];

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id)
    });

    objectKeys.forEach((key: string) => {
      this.formGroup.addControl(key, new FormControl(dataItem[key]));
    });

    this.editedRowIndex = args.rowIndex;

    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  refreshDataOnPage(callback?: () => void) {
    this.loadingBar.useRef('router').start();

    const cmd: ListInventoryItemLocationQuantitiesForInventoryItemCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort,
      id: this.id
    };
    this.SupplyLocationQuantityService.forInventoryItem(cmd).subscribe(
      (result: ListInventoryItemLocationQuantitiesForInventoryItemCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.data[0].supplyId, `View Locations & Stocks for "${result.data[0].supplyName}"`);
        this.gridData.data = result.data;
        this.gridData.total = result.totalRowRowCount;
        this.logger.log('Updated data', this.gridData);
        this.loadingBar.useRef('router').complete();
        if (callback) {
          callback();
        }
      }
    );
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    this.logger.debug(sender, rowIndex, formGroup);
    const formData = formGroup.value;
    const request: UpdateSupplyLocationQuantityCommand = {
      id: formData.id,
      supplyId: formData.invent,
      roomId: formData.roomId,
      quantity: formData.quantity,
      trackInventoryInThisRoom: formData.trackInventoryInThisRoom.toString().toLowerCase() == 'true',
      dimension1: formData.dimension1,
      dimension2: formData.dimension2,
      dimension3: formData.dimension3,
      stockingQuantity: formData.stockingQuantity,
      stockingQuantityUnitOfMeasurement: formData.stockingQuantityUnitOfMeasurement
    };
    this.logger.debug('Update Request', request);
    this.SupplyLocationQuantityService.update(formData.id, request).subscribe((result: UpdateSupplyLocationQuantityCommandResult) => {
      this.logger.log('Update Result', result);
      this.refreshDataOnPage(() => sender.closeRow(rowIndex));
    });
  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source,
    // `editService` in this example
    //this.editService.remove(args.dataItem);
  }

  public onQrClick(supplyLocationQuantityId: number): void {
    this.qrLink = `${location.origin}/supplies/qr/supply-location-quantity/${supplyLocationQuantityId}`;
    this.isQrModalVisible = true;
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  saveRowEdit(dataItem: any) {
    this.logger.debug('dataItem', dataItem);
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.id)) {
        this.selectedIds.push(row.dataItem.id);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.data.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.data.map(item => item.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.Supply;
    const selectedItems = this.gridData.data.filter(gridRow => this.selectedIds.includes(gridRow.id));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.SUPPLIES.baseRoute}/qr/supply-location-quantity/${item.id}`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
