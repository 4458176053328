import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  // https://medium.com/quick-code/set-up-analytics-on-an-angular-app-via-google-tag-manager-5c5b31e6f41
  constructor(
    private logger: NGXLogger,
    private _router: Router) {}

    pageView(overridePath?: string) {
      this.logger.debug('Sending GA Pageview');
      (window as any).dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: overridePath || this._router.url,
        virtualPageTitle: overridePath || this._router.url
      });
      /**
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      */
    }
}
