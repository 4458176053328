<div class="float-end">
    <kendo-buttongroup>
        <button kendoButton type="button" (click)='savePatients()' [primary]='true'>Save Patients</button>
        <button type="button" kendoButton (click)='addPatient()'>Add Patient</button>
    </kendo-buttongroup>
</div>
<div class="clearfix"></div>
<kendo-sortable emptyText="Please add a new patient." [navigatable]="true" [animation]="true" [data]="patientList" (dataAdd)="onDataAdd($event)" (dataRemove)="onDataRemove($event)" (dragEnd)="onDragEnd($event)" (dragOver)="onDragOver($event)" (dragStart)="onDragStart($event)">
    <ng-template let-item="item" let-i="index">
        <div class="drawer">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <span class="navbar-brand">Patient {{i + 1}}</span>
                <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
                <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                    <button kendoButton type="button" class="btn btn-primary" (click)="deleteRow(i)" [icon]="'delete'">Delete</button>
                </div>
            </nav>
            <div class="patient row">
                <input type="hidden" [(ngModel)]="item.sortOrder" value="{{item.sortOrder}}" />
                <div class=" col-md-4">
                    <label for="{{i}}_firstName" class="col-form-label col-form-label-sm">First Name</label>
                    <input class="form-control" kendoTextBox type="text" [(ngModel)]="item.firstName" id='{{i}}_firstName' placeholder="First Name" />
                </div>
                <div class=" col-md-4">
                    <label for="{{i}}_middleName" class="col-form-label col-form-label-sm">Middle Name</label>
                    <input class="form-control" kendoTextBox type="text" [(ngModel)]="item.middleName" id='{{i}}_middleName' placeholder="Middle Name" />
                </div>
                <div class=" col-md-4">
                    <label for="{{i}}_lastName" class="col-form-label col-form-label-sm">Last Name</label>
                    <input class="form-control" kendoTextBox type="text" [(ngModel)]="item.lastName" id='{{i}}_lastName' placeholder="Last Name" />
                </div>
                <div class=" col-md-4">
                    <label for="{{i}}_dateOfBirth" class="col-form-label col-form-label-sm">Date of Birth</label>
                    <kendo-datepicker class="form-control" type="text" [(ngModel)]="item.dateOfBirth" id='{{i}}_dateOfBirth' placeholder="Date of Birth"></kendo-datepicker>
                </div>
                <div class=" col-md-8">
                    <label for="{{i}}_manikinInventoryItemId" class="col-form-label col-form-label-sm">Manikin Supply</label>
                    <kendo-dropdownlist class="form-control" [(ngModel)]="item.manikinInventoryItemId" [valuePrimitive]="true" [defaultItem]="defaultMakeAndModel" [data]="manikinMakesAndModels" [valueField]="'id'" [textField]="'label'" [filterable]="false" (valueChange)="manikinInventorySelectorChanged($event,i)">
                    </kendo-dropdownlist>
                </div>
                <div class=" col-md-8">
                    <label for="{{i}}_allergies" class="col-form-label col-form-label-sm">Allergies</label>
                    <textarea class="form-control" [(ngModel)]="item.allergies" id="{{i}}_allergies"></textarea>
                </div>
            </div>
        </div>
    </ng-template>
</kendo-sortable>