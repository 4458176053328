import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})

export class IdleService implements OnDestroy {
  private timeoutId: NodeJS.Timeout;
  private readonly idleTimeInMinutes = 120;
  private readonly idleTimeInMilliseconds = this.idleTimeInMinutes * 60 * 1000;
  private events = ['mousemove', 'keydown', 'click', 'touchstart'];

  constructor(
    private logger: NGXLogger,
    private router: Router, 
    private ngZone: NgZone) {
    this.startTracking();
  }

  private startTracking(): void {
    this.logger.debug(`IdleService: starting. User will be logged out after ${this.idleTimeInMinutes} minute(s) of inactivity.`);
    this.resetTimer();

    this.events.forEach(event => {
      window.addEventListener(event, this.resetTimer);
    });
  }

  private resetTimer = (): void => {
    clearTimeout(this.timeoutId);
    //this.logger.debug(`IdleService:timer reset. User will be logged out after ${this.idleTimeInMinutes} minute(s) of inactivity.`);
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => this.logoutUser(), this.idleTimeInMilliseconds);
    });
  };

  private logoutUser(): void {
    this.ngZone.run(() => {
      this.logger.debug(`IdleService: Logging out. User inactive for ${this.idleTimeInMinutes} minute(s).`);
      this.router.navigate(['/oauth/logout']);
    });
  }

  ngOnDestroy(): void {
    this.events.forEach(event => {
      window.removeEventListener(event, this.resetTimer);
    });
    clearTimeout(this.timeoutId);
  }
}