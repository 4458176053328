import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  BedService,
  CreateBedCommand,
  CreateBedCommandResult,
  ListBuildingsCommand,
  ListRoomsCommand,
  ListRoomsCommandResult,
  OrganizationService,
  ReadBedCommandResult,
  RoomService,
  UpdateBedCommand,
  UpdateBedCommandResult
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, takeUntil } from 'rxjs';
@Component({
  selector: 'app-bed-detail',
  templateUrl: './bed-detail.component.html',
  styleUrls: ['./bed-detail.component.scss']
})
export class BedDetailComponent extends EntityBaseComponent implements OnInit {
  readBedCommandResult: ReadBedCommandResult = {};
  dataModel: ReadBedCommandResult;
  listBuildingsCommand: ListBuildingsCommand = {};
  updateBedCommand: UpdateBedCommand = {};
  createBedCommand: CreateBedCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  roomId: any;
  public $rooms: Observable<ListRoomsCommandResult>;

  get room() {
    return this.form.get('roomId');
  }

  get customId() {
    return this.form.get('customId');
  }

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    public cachedDataService: CachedDataService,
    private roomService: RoomService,
    private bedService: BedService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.BEDS, injector);
    const query: ListRoomsCommand = {
      skip: 0,
      take: 1000,
      sort: []
    };
    this.$rooms = this.roomService.list(query);
  }

  public ngOnInit() {
    this._createForm();
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      roomId: ['', [Validators.required]],
      customId: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });

    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadBedCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.bedService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadBedCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, result.customId);
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this.updateBedCommand = this.form.value;
      this.logger.debug(this.updateBedCommand);
      this.bedService.update(this.updateBedCommand.id.toString(), this.updateBedCommand).subscribe((result: UpdateBedCommandResult) => {
        this.logger.debug(result);
        if (closeOnSuccess) {
          this.routeToEntityList();
        } else {
          this._convertServerDataToFormModel(result);
          this.toastService.success('Success!', 'This item has been saved.');
        }
      });
    } else {
      this.createBedCommand = this.form.value;
      this.logger.debug(this.createBedCommand);
      this.bedService.create(this.createBedCommand).subscribe((result: CreateBedCommandResult) => {
        this._convertServerDataToFormModel(result);
        this.routeToEntityList();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this bed?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
