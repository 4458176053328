import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { timer } from 'rxjs';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  secondsBetweenAttempts = 10;
  maxAttemptsBeforeFail = 6;
  public attemptCounter = 1;
  public failedPermanently = false;
  public timer: any;
  public countDown;
  public count = 60;
  subscribe: any;
  ticks = 10;
  constructor(private logger: NGXLogger, private http: HttpClient, private router: Router) {}

  tryConnection() {
    if (this.attemptCounter <= this.maxAttemptsBeforeFail) {
      this.logger.debug('Trying application connection attempt ' + this.attemptCounter);
      this.http.get('http://localhost:5000').subscribe(
        data => {
          this.router.navigate(['/']);
        },
        (error: HttpErrorResponse) => {
          if (error.status !== 0) {
            this.stopTimer();
            this.router.navigate(['/']);
          }
        }
      );
    } else {
      this.stopTimer();
    }
    this.attemptCounter++;
  }

  stopTimer() {
    this.failedPermanently = true;
    this.logger.debug('Application connection has failed permanently.');
    this.timer.unsubscribe();
  }

  ngOnInit() {
    this.logger.debug('Initializing Offline Component');
    this.timer = timer(1000, 1000).subscribe(t => {
      --this.ticks;
      if (this.ticks === 0) {
        this.tryConnection();
        this.ticks = 10;
      }
    });
  }
}
