<autopilot-grid>
  <div kendoTooltip class="tooltip-container" showOn="none" filter="th .k-column-title" [tooltipTemplate]="template">
    <kendo-grid 
    kendoGridSelectBy="supplyLocationQuantityId" 
    [data]="gridData" 
    [selectedKeys]="selectedIds"
    [selectable]="{ checkboxOnly: true }" 
    [style.maxHeight.%]="100" 
    [pageSize]="state.take" 
    [skip]="state.skip"
    [filterable]="false"
    [sortable]="false" 
    [sort]="sort" 
    [loading]="isLoading" [pageable]="true" 
    class="stock-information"
    (selectionChange)="onSelectionChange($event)"
    (pageChange)="pageChangeEvent($event)"
    (dataStateChange)="dataStateChangeEvent($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
          <form class="col-auto me-auto" role="search">
            <button kendoButton type="button" class="btn btn-secondary text-light" routerLink="/supplies/edit-list">
              <i class="fa-light fa-gears"></i> Manage {{ this.GetPluralEntityName() }}
            </button>
            <button kendoButton type="button" class="btn btn-secondary text-light" [disabled]="selectedIds.length == 0"
              title="To print labels, select one or more from below." (click)="onPrintLabels()">
              <i class="fa-light fa-qrcode"></i> Print Labels
            </button>
          </form>
          <div class="text-end">
            <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF">
              <i class="fa-light fa-file-pdf"></i>
            </button>
            <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white">
              <i class="fa-light fa-file-excel"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <kendo-grid-checkbox-column class="printer-checkbox-item">
        <ng-template kendoGridHeaderTemplate>
          <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing"
            (click)="clickSelectAllCheckbox()">
            <i class="fa-light fa-print"
              [ngClass]="{ 'text-purple': selectAllState === 'checked', 'fw-bold': selectAllState === 'checked' }"></i>
            <input #selectAllCheckbox class="d-none k-checkbox k-checkbox-md k-rounded-md" type="checkbox"
              title="Select item(s) to print label(s)" kendoCheckbox kendoGridSelectAllCheckbox [state]="selectAllState"
              (selectAllChange)="onSelectAll($event)" />
          </button>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="wingmanId" title="Wingman ID">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false">
          </kendo-grid-string-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [routerLink]="'/supplies/room-inventory-item-stock/' + dataItem.supplyId" kendoButton
            class="btn-link d-inline link" title="View Locations & Stocks">
            <i class="fa-light fa-arrow-up-right-from-square pe-2"></i>
            <span class="d-inline">{{ dataItem.wingmanId }}</span>
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="supplyName" title="Item Name">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false">
          </kendo-grid-string-filter-menu>
        </ng-template>
        <!--<ng-template kendoGridCellTemplate let-dataItem>
          <a href="#" class="link" [routerLink]="'/supplies/edit/' + dataItem.supplyId"
            >{{ dataItem.supplyName }} <i class="fa-light fa-arrow-up-right-from-square"></i
          ></a>
        </ng-template>-->
      </kendo-grid-column>
      <kendo-grid-column field="customId" title="Custom ID">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false">
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manufacturerNumber" title="Manufacturer No.">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false">
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="quantity" title="Quantity">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false">
          </kendo-grid-numeric-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-quantity-display-component [dataItem]="dataItem"></app-quantity-display-component>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column-group title="Stocking Levels">
        <kendo-grid-column field="stockingQuantity" title="Quantity">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false">
            </kendo-grid-numeric-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockingQuantityUnitOfMeasurement" title="U of M">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false">
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Storage Room">
        <kendo-grid-column field="fullLocationName" title="Location">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false">
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fullDimensions" title="Dimensions">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false">
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true"
        [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"> </kendo-grid-excel>
    </kendo-grid>
  </div>
</autopilot-grid>

<ng-template #template let-anchor>
  {{ getTooltipText(anchor.nativeElement.innerText) }}
</ng-template>