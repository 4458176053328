import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, MultipleSortSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ListUsersCommand, OrganizationService, SetUserAccountStatusCommand, UserService, UserViewModel } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends EntityBaseComponent implements OnInit {
  constructor(
    injector: Injector,
    private toastService: ToastService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    super(EntityGlobals.USERS, injector);
    this.state.sort = [{ dir: 'asc', field: 'username' }];
    this.reloadData();
  }

  gridData: GridDataResult = { data: [], total: 0 };
  public reloadData() {
    this.loadingBar.useRef('router').start();
    const cmd: ListUsersCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort
    };
    this.userService.list(cmd).subscribe((result: any) => {
      this.loadingBar.useRef('router').complete();
      this.logger.debug(result);
      this.gridData = result.data.map(item => ({
        ...item,
        createdDate: new Date(item.created),
        lastUpdatedDate: new Date(item.lastUpdated)
      }));
    });
  }

  ngOnInit() {
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  toggleUserStatus(user: UserViewModel) {
    const isEnabled = user.enabled;

    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: `Are you sure you want to ${isEnabled ? 'disable' : 'enable'} this user? The user will immediately ${
        isEnabled ? 'lose' : 'gain'
      } access to the application.`,
      actions: [
        { text: 'No', actionResult: false },
        { text: 'Yes', primary: true, actionResult: true }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      const doAction = result['actionResult'] as boolean;
      if (doAction) {
        const cmd: SetUserAccountStatusCommand = {
          username: user.username,
          enabled: !user.enabled
        };
        this.userService.setAccountStatus(cmd).subscribe((result: any) => {
          this.toastService.success('Success!', "This user's status has been updated.");
          this.reloadData();
        });
      }
    });
  }

  deleteUser(dataItem: any) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this bed?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
