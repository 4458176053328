import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-multi-date-and-time-selector',
  templateUrl: './multi-date-and-time-selector.component.html',
  styleUrls: ['./multi-date-and-time-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiDateAndTimeSelectorComponent),
      multi: true,
    },
  ],
})
export class MultiDateAndTimeSelectorComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  inputData: any[] = [];
  @Input() timePickerMin;
  @Input() timePickerMax;
  @Input() set data(value) {
    this._data.next(value);
  }
  private _data = new BehaviorSubject<any[]>([]);
  constructor(private logger: NGXLogger) {}
  ngOnInit() {}

  writeValue(value: any) {
    if (value !== undefined) {
      this.setupInitialValues(value);
    }
  }

  setupInitialValues(value: any) {
    if (value) {
      this.inputData = this.convertArray(value);
    }
  }

  convertArray(array): any[] {
    const dateFormatUTC = 'YYYY-MM-DD HH:mm:ssZ';
    const newArray = [];
    for (const item of array) {
      item.dateTimeStart = new Date(item.dateTimeStart);
      item.dateTimeEnd = new Date(item.dateTimeEnd);
      newArray.push(item);
    }
    return newArray;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  valuesHaveChanged() {
    this.propagateChange(this.inputData);
  }
  registerOnTouched() {}

  get data() {
    return this._data.getValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['data']) {
      //this.logger.debug(this.data);
    }
  }

  removeItem(item) {
    this.inputData.splice(item, 1);
    this.valuesHaveChanged();
  }

  addItem() {
    const today = this.todayAtTime('8:00');
    const newItem = {
      dateTimeStart: today,
      dateTimeEnd: today,
    };
    this.inputData.push(newItem);
    this.valuesHaveChanged();
  }

  todayAtTime(time: string): Date {
    return new Date(
      moment(moment().format('YYYY-MM-DD') + ' ' + time).valueOf()
    );
  }

  datePickerValueChange(event, item) {
    this.valuesHaveChanged();
  }

  onTimePickerValueChange($event, item) {
    this.valuesHaveChanged();
  }
}
