import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { Title } from '@angular/platform-browser';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, MultipleSortSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { BedService, ListBedsCommand, ListBedsCommandResult, ListBedsCommandViewModel, ListRoomsCommand } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';

@Component({
  selector: 'app-bed-listing',
  templateUrl: './bed-listing.component.html',
  styleUrls: ['./bed-listing.component.scss']
})
export class BedListingComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private bedService: BedService,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.BEDS, injector);
  }

  gridData: GridDataResult = { data: [], total: 0 };
  listRoomsCommand: ListRoomsCommand = {};
  public view: GridDataResult;
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'roomName' }];

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    const cmd: ListBedsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      //filter: this.state.filter,
      sort: this.state.sort
    };
    this.bedService.list(cmd).subscribe((result: ListBedsCommandResult) => {
      this.loadingBar.useRef('router').complete();
      if (result && result.data) {
        this.gridData.data = result.data.map(item => ({
          ...item,
          createdDate: new Date(item.created),
          lastUpdatedDate: new Date(item.lastUpdated)
        }));
        this.gridData.total = result.totalRowRowCount;
      }
    });
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.bedService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }
}
