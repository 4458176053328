import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { NGXLogger } from 'ngx-logger';
import { forkJoin, switchMap, take } from 'rxjs';

import {
  BedService,
  BuildingService,
  EquipmentLineItemService,
  EquipmentService,
  EquipmentViewModel,
  ListBedsCommand,
  ListBedsCommandViewModel,
  ListBuildingsCommand,
  ListBuildingsCommandViewModel,
  ListEquipmentsCommand,
  ListLocationsCommand,
  ListRoomsCommand,
  LocationService,
  LocationViewModel,
  ReadEquipmentLineItemCommandResult,
  RoomService,
  RoomViewModel
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';

@Component({
  selector: 'equipment-qr-landing',
  templateUrl: './equipment-qr-landing.component.html'
})
export class EquipmentQrLandingComponent extends EntityBaseComponent implements OnInit {
  public buildings: ListBuildingsCommandViewModel[];
  public equipment: EquipmentViewModel[];
  public equipmentLineItem: ReadEquipmentLineItemCommandResult;
  public filteredBuildings: ListBuildingsCommandViewModel[];
  public filteredRooms: RoomViewModel[];
  public filteredStations: ListBedsCommandViewModel[];
  public isDialogVisible = false;
  public locations: LocationViewModel[];
  public rooms: RoomViewModel[];
  public selectedBuilding?: ListBuildingsCommandViewModel;
  public selectedLocation?: LocationViewModel;
  public selectedRoom?: RoomViewModel;
  public selectedStation?: ListBedsCommandViewModel;
  public stations: ListBedsCommandViewModel[];

  constructor(
    private _breadcrumbService: BreadcrumbsService,
    private bedService: BedService,
    private buildingService: BuildingService,
    private equipmentService: EquipmentService,
    private equipmentLineItemService: EquipmentLineItemService,
    impersonationService: ImpersonationService,
    private locationService: LocationService,
    private logger: NGXLogger,
    private roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    injector: Injector
  ) {
    super(EntityGlobals.EQUIPMENT, injector);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    const listEquipmentsCommand: ListEquipmentsCommand = {
      skip: 0,
      take: 1000
    };
    const equipmentObservable = this.equipmentService.list(listEquipmentsCommand);

    const locationCommand: ListLocationsCommand = {
      skip: 0,
      take: 1000
    };
    const locationsObservable = this.locationService.list(locationCommand);

    const buildingsListCommand: ListBuildingsCommand = {
      skip: 0,
      take: 1000
    };
    const buildingsObservable = this.buildingService.list(buildingsListCommand);

    const roomsListCommand: ListRoomsCommand = {
      skip: 0,
      take: 1000
    };
    const roomsObservable = this.roomService.list(roomsListCommand);

    const listBedsCommand: ListBedsCommand = {
      skip: 0,
      take: 1000
    };
    const stationObservable = this.bedService.list(listBedsCommand);

    const readEquipmentObservable = this.route.params.pipe(
      switchMap(params => {
        return this.equipmentLineItemService.read(+params['equipmentLineItemId']);
      }),
      take(1)
    );

    forkJoin([
      equipmentObservable,
      locationsObservable,
      buildingsObservable,
      roomsObservable,
      stationObservable,
      readEquipmentObservable
    ]).subscribe(([equipment, locations, buildings, rooms, stations, equipmentLineItem]) => {
      this.equipment = equipment.data;
      this.locations = locations.data;
      this.buildings = buildings.data;
      this.rooms = rooms.data;
      this.stations = stations.data;
      this.equipmentLineItem = equipmentLineItem;

      this.refreshDropdowns();
    });
  }

  private refreshDropdowns(): void {
    this.selectedLocation = this.locations.find(l => l.id === this.equipmentLineItem.currentLocationLocationId);
    this.selectedBuilding = this.buildings.find(b => b.id === this.equipmentLineItem.currentLocationBuildingId);
    this.selectedRoom = this.rooms.find(r => r.id === this.equipmentLineItem.currentLocationRoomId);
    this.selectedStation = this.stations.find(s => s.id === this.equipmentLineItem.currentLocationBedId);

    this.filteredBuildings = this.buildings.filter(b => b.locationId === this.selectedLocation.id);
    this.filteredRooms = this.rooms.filter(r => r.buildingId === this.selectedBuilding.id);
    this.filteredStations = this.prependNoneStationOption(this.stations.filter(s => s.roomId === this.selectedRoom.id));
  }

  public getEquipmentName(): string {
    return this.equipment?.find(e => e.id === this.equipmentLineItem.equipmentId)?.name;
  }

  public getLocationName(): string {
    return this.locations?.find(l => l.id === this.equipmentLineItem.currentLocationLocationId)?.name;
  }

  public getBuildingName(): string {
    return this.buildings?.find(b => b.id === this.equipmentLineItem.currentLocationBuildingId)?.name;
  }

  public getRoomName(): string {
    return this.rooms?.find(r => r.id === this.equipmentLineItem.currentLocationRoomId)?.name;
  }

  public getStationName(): string {
    return this.stations?.find(s => s.id === this.equipmentLineItem.currentLocationBedId)?.customId || 'Unknown';
  }

  public onSelectLocation(event: any) {
    if (this.selectedLocation?.id !== event.id) {
      this.selectedLocation = event;
      this.selectedBuilding = undefined;
      this.selectedRoom = undefined;
      this.selectedStation = undefined;
      this.filteredBuildings = this.buildings.filter(b => b.locationId === event.id);
    }
  }

  public onSelectBuilding(event: any) {
    if (this.selectedBuilding?.id !== event.id) {
      this.selectedBuilding = event;
      this.selectedRoom = undefined;
      this.selectedStation = undefined;
      this.filteredRooms = this.rooms.filter(r => r.buildingId === event.id);
    }
  }

  public onSelectRoom(event: any) {
    if (this.selectedRoom?.id !== event.id) {
      this.selectedRoom = event;
      this.selectedStation = undefined;
      this.filteredStations = this.prependNoneStationOption(this.stations.filter(s => s.roomId === event.id));
    }
  }

  public onSelectStation(event: any) {
    this.selectedStation = event;
  }

  private prependNoneStationOption(stations: ListBedsCommandViewModel[]): ListBedsCommandViewModel[] {
    stations.unshift({
      customId: 'None',
      value: null
    } as ListBedsCommandViewModel);
    return stations;
  }

  public toggleDialog(): void {
    this.isDialogVisible = !this.isDialogVisible;
  }

  public isRequiredFieldsDefined(): boolean {
    return !!this.selectedLocation && !!this.selectedBuilding && !!this.selectedRoom;
  }

  public onConfirm(): void {
    this.equipmentLineItemService
      .update(this.equipmentLineItem.id.toString(), {
        ...this.equipmentLineItem,
        currentLocationLocationId: this.selectedLocation.id,
        currentLocationBuildingId: this.selectedBuilding.id,
        currentLocationRoomId: this.selectedRoom.id,
        currentLocationBedId: this.selectedStation?.id
      })
      .subscribe(_result => {
        this.fetchData();
        this.toggleDialog();
      });
  }
}
