import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  ListSupplyLocationQuantityHistorysCommand,
  ListSupplyLocationQuantityHistorysCommandResult,
  OrganizationService,
  SupplyLocationQuantityHistoryService,
  SupplyLocationQuantityService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-supply-history',
  templateUrl: './supply-history.component.html',
  styleUrls: ['./supply-history.component.scss']
})
export class SupplyHistoryComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private supplyHistoryService: SupplyLocationQuantityHistoryService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    private _breadcrumbService: BreadcrumbsService,
    private loadingBar: LoadingBarService,
    private _title: Title,
    injector: Injector
  ) {
    super(EntityGlobals.SUPPLIES, injector);
    this.editMode = false;
  }

  dataResult: ListSupplyLocationQuantityHistorysCommandResult = null;

  ngOnInit(): void {
    this.reloadData();
  }

  gridData: GridDataResult = { data: [], total: 0 };
  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    this.logger.debug('Getting History for item ' + this.id);
    const cmd: ListSupplyLocationQuantityHistorysCommand = {
      supplyLocationQuantityId: this.id,
      take: this.state.take,
      sort: this.state.sort,
      skip: this.state.skip
    };
    this.supplyHistoryService.list(cmd).subscribe((result: ListSupplyLocationQuantityHistorysCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.UpdateBreadcrumbPageTitle(result.supplyId, `View Locations & Stocks History for "${result.supplyName}"`);

      this.dataResult = result;
      this.gridData.data = result.data.map(item => ({
        ...item,
        createdDate: new Date(item.created),
        lastUpdatedDate: new Date(item.lastUpdated)
      }));
      this.gridData.total = result.totalRowRowCount;
    });
  }
}
