import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateScenarioCommand,
  CreateScenarioCommandResult,
  OrganizationService,
  ReadScenarioCommandResult,
  ScenarioService,
  UpdateScenarioCommand,
  UpdateScenarioCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-scenario-detail',
  templateUrl: './scenario-detail.component.html',
  styleUrls: ['./scenario-detail.component.scss']
})
export class ScenarioDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readScenarioCommandResult: ReadScenarioCommandResult = {};
  dataModel: ReadScenarioCommandResult;
  updateScenarioCommand: UpdateScenarioCommand = {};
  createScenarioCommand: CreateScenarioCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  public patients: any[];
  public drugs: any[];

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private scenarioService: ScenarioService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(EntityGlobals.SCENARIOS, injector);
    this.createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  convertServerDataToFormModel(result: ReadScenarioCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.scenarioService.read(this.id).subscribe((result: ReadScenarioCommandResult) => {
      this.convertServerDataToFormModel(result);
      if (result) this.showForm = true;
      this.patients = result.patients ? result.patients : [];
      this.drugs = result.drugs ? result.drugs : [];
    });
  }

  ngOnInit() {}

  resetForm() {
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      organizationId: [''],
      organizationName: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      patients: [''],
      drugs: [''],
      /*supplies: [''],
      equipment: [''],*/
      learningObjectives: [''],
      roomSetting: [''],
      lengthInMinutes: [''],
      patientMonitorDisplay: [''],
      specialInstructions: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateScenarioCommand = this.form.value;
      this.scenarioService
        .update(this.updateScenarioCommand.id.toString(), this.updateScenarioCommand)
        .subscribe((result: UpdateScenarioCommandResult) => {
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createScenarioCommand = this.form.value;
      this.scenarioService.create(this.createScenarioCommand).subscribe((result: CreateScenarioCommandResult) => {
        this.convertServerDataToFormModel(result);
        this.routeToEntityList();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this scenario?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }

  onNotificationOfDrugs(drugs) {
    this.logger.debug(drugs);
    this.form.controls['drugs'].setValue(drugs);
    this.onSubmit(false);
  }

  onNotificationOfPatients(patients) {
    this.form.controls['patients'].setValue(patients);
    this.onSubmit(false);
  }
}
