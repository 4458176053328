import { Component, ComponentRef, ViewEncapsulation } from '@angular/core';
import { TimesheetDayGroupViewModel, TimesheetEntryMiniViewModel } from '@wo-api/index';
import { CachedDataService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
//import { IModalDialog, IModalDialogButton, IModalDialogOptions } from 'ngx-modal-dialog';

@Component({
  selector: 'app-timesheet-cell-modal',
  templateUrl: './timesheet-cell-modal.component.html',
  styleUrls: ['./timesheet-cell-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimesheetCellModalComponent //implements IModalDialog 
{
  //actionButtons: IModalDialogButton[];
  public Entries: TimesheetEntryMiniViewModel[] = [];
  public timesheetDayGroup: TimesheetDayGroupViewModel = {};
  public durationInMinutes: number;
  // @Output() modalPopupSaved = new EventEmitter();

  constructor(
    private logger: NGXLogger,
    private cacheDataService: CachedDataService
  ) {}

  dialogInit(
    //reference: ComponentRef<IModalDialog>,
    //options: Partial<IModalDialogOptions<TimesheetDayGroupViewModel>>
  ) {
    // no processing needed
   /* this.timesheetDayGroup = options.data;
    this.Entries = options.data.entries;
    this.actionButtons = [
      {
        text: 'Save',
        onAction: () => {
          this.saveItems();
          return true;
        },
      },
      {
        text: 'Cancel',
        onAction: () => true,
      },
    ];*/
  }

  public saveItems() {
    this.cacheDataService._timesheetEntryTemporaryCache.next(this.Entries);
  }

  public dataForPopupChanged($event: TimesheetEntryMiniViewModel[]) {
    this.logger.debug('dataForPopupChanged', $event);
  }

  public processChildValueChange($event: TimesheetEntryMiniViewModel[]) {
    this.logger.debug('processChildValueChange', $event);
  }

  public createTimesheetDayGroupViewModel(
    entry: TimesheetEntryMiniViewModel
  ): TimesheetDayGroupViewModel {
    const timesheetDayGroup: TimesheetDayGroupViewModel = {
      dateOfEntry: entry.dateOfEntry,
      entries: [entry],
    };
    return timesheetDayGroup;
  }

  public deleteEntry(entry: TimesheetEntryMiniViewModel): void {
    this.logger.debug('Attempting to delete entry:', entry);
    this.cacheDataService._timesheetEntryTemporaryRecycleBin.next(entry);
  }
}
