<div class="float-end" [hidden]="emitToParentComponent">
    <kendo-buttongroup>
        <button kendoButton type="button" (click)='saveInventoryItems()' [primary]='true'>Save Items</button>
    </kendo-buttongroup>
</div>
<div class="row">
    <div class=" col-sm-12">
        <div class="row" *ngFor="let row of filteredInventoryItems; let i = index" [attr.data-index]="i">
            <app-inventory-item-list-builder-row [index]="i" class="col-sm-9" [objectData]="row" (inventoryItemListBuilderRowChange)='onNotificationOfSupply($event)'></app-inventory-item-list-builder-row>
            <div class="col-sm-3">
                <button kendoButton type="button" class="form-control" (click)="removeRow(row)" [primary]="true" [icon]="'minus'">Remove Item</button>
            </div>
        </div>
    </div>
</div>
<button kendoButton type="button" (click)="addNewRow()" [primary]="true" >Add New Row</button>