import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject, map } from 'rxjs';
import { ApplicationOfflineService } from './application-offline.service';

export function configurationServiceInitializerFactory(configurationService: ConfigLoaderService): Function {
  // a lambda is required here, otherwise `this` won't work inside ConfigurationService::load
  return () => configurationService.initialize();
}

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {
  public Config = new ReplaySubject<any>();
  public Environment = '';
  public ApplicationUrl = '';
  public ApplicationVersion = '';

  constructor(private applicationOfflineService: ApplicationOfflineService, private logger: NGXLogger, private http: HttpClient) {}

  private getLocalConfigFile() {
    //TODO: test this path now that the service has moved directories
    return this.http.get('../../assets/app-config.json');
  }

  public getConfigUrl(): Observable<any> {
    return this.getLocalConfigFile().pipe(
      map((response: any) => {
        return response.ConfigFileUrl;
      })
    );
  }

  initialize(): Observable<any> {
    this.logger.debug('Initializing ConfigLoaderService');
    return this.getConfigUrl()
      .pipe(
        map((configUrl: string) => {
          return this.http.get(configUrl);
        })
      )
      .pipe(
        map(configUrlObservable => {
          const ApiPublicConfig$ = configUrlObservable.subscribe(
            config => {
              this.Config.next(config);
              this.Environment = config['environment'];
              this.ApplicationUrl = config['applicationURL'];
              this.ApplicationVersion = config['applicationVersion'];
              return config;
            },
            err => {
              //this.logger.debug('HTTP Error', err);
              //this.applicationOfflineService.showOfflineMessage(component.offlineModule);
            },
            () => {
              this.logger.debug('HTTP request completed.');
            }
          );
          return ApiPublicConfig$;
        })
      );
  }
}
