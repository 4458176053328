import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  BuildingService,
  CreateRoomCommand,
  CreateRoomCommandResult,
  ListBuildingsCommand,
  ListBuildingsCommandResult,
  ListBuildingsCommandViewModel,
  OrganizationService,
  ReadRoomCommandResult,
  RoomService,
  UpdateRoomCommand,
  UpdateRoomCommandResult
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.scss']
})
export class RoomDetailComponent extends EntityBaseComponent implements OnInit {
  readRoomCommandResult: ReadRoomCommandResult = {};
  dataModel: ReadRoomCommandResult;
  listBuildingsCommand: ListBuildingsCommand = {};
  updateRoomCommand: UpdateRoomCommand = {};
  createRoomCommand: CreateRoomCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  buildingsForOrganization: ListBuildingsCommandViewModel[];
  buildingId: number;
  public $buildings: Observable<ListBuildingsCommandResult>;

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  get building() {
    return this.form.get('buildingId');
  }

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    public cachedDataService: CachedDataService,
    private roomService: RoomService,
    private buildingService: BuildingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.ROOMS, injector);
    const query: ListBuildingsCommand = {
      skip: 0,
      take: 1000,
      sort: []
    };
    this.$buildings = this.buildingService.list(query);
  }

  public ngOnInit() {
    this._createForm();
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      bedCount: [0],
      isInventoryStorageRoom: [false],
      organizationId: [''],
      buildingId: ['', [Validators.required]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadRoomCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.roomService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadRoomCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, result.name);
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this.updateRoomCommand = this.form.value;
      this.logger.debug('updateRoomCommand', this.updateRoomCommand);
      //this.updateRoomCommand.buildingId = this.buildingId;
      this.roomService
        .update(this.updateRoomCommand.id.toString(), this.updateRoomCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: UpdateRoomCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      this.createRoomCommand = this.form.value;
      this.logger.debug(this.createRoomCommand);
      this.roomService
        .create(this.createRoomCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: CreateRoomCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this room?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
