import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomEmmitterService {
  inventoryItemRoomSelectionChange = new EventEmitter<any>();

  constructor() { }

  public emitInventoryItemRoomSelectionEvent(any) {
    this.inventoryItemRoomSelectionChange.emit(any);
  }
  getInventoryItemRoomSelectionEmitter() {
    return this.inventoryItemRoomSelectionChange;
  }
}