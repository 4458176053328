<autopilot-grid>
  <kendo-grid
    [data]="gridData"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    (dataStateChange)="dataStateChange($event)"
    (pageChange)="pageChangeEvent($event)"
    class="stock-information">
        <ng-template kendoGridToolbarTemplate>
            <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
                <form class="col-auto me-auto" role="search">
                    <button kendoButton type="button"  class="btn btn-secondary text-white" routerLink="/rooms"><i class="fa-light fa-arrow-left-long"></i> View All {{this.GetPluralEntityName()}}</button>
                </form>
            </div>
        </ng-template>
        <kendo-grid-column field="supplyName" title="Item Name">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="#" class="link" [routerLink]="'/supplies/edit/' + dataItem.supplyId">{{ dataItem.supplyName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customId" title="Custom ID"></kendo-grid-column>
        <kendo-grid-column field="quantity" title="Quantity">
            <ng-template kendoGridCellTemplate let-dataItem>
                <app-quantity-display-component [dataItem]="dataItem"></app-quantity-display-component>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column-group title="Stocking Levels">
            <kendo-grid-column field="stockingQuantity" title="Quantity"></kendo-grid-column>
            <kendo-grid-column field="stockingQuantityUnitOfMeasurement" title="Unit of Measurement"></kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column-group title="Storage Room Location">
            <kendo-grid-column field="dimension1" title="Dimension 1"></kendo-grid-column>
            <kendo-grid-column field="dimension2" title="Dimension 2"></kendo-grid-column>
            <kendo-grid-column field="dimension3" title="Dimension 3"></kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
        </kendo-grid-pdf>
        <kendo-grid-excel [fileName]="excelFileName">
        </kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>
