import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, GridDataResult, MultipleSortSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { EquipmentService, ListEquipmentsCommand, ListEquipmentsCommandResult, VendorService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-inventoryitems',
  templateUrl: './inventoryitems.component.html',
  styleUrls: ['./inventoryitems.component.scss']
})
export class InventoryItemsComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private equipmentService: EquipmentService,
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    injector: Injector
  ) {
    super(EntityGlobals.EQUIPMENT, injector);
    this.allData = this.allData.bind(this);
  }

  gridData: GridDataResult = { data: [], total: 0 };
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'id' }];

  ngOnInit() {
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public allData(): ExcelExportData {
    this.logger.debug('this.gridData', this.gridData);
    const result: ExcelExportData = {
      data: process(this.gridData.data, {
        sort: this.sort
      }).data
    };
    return result;
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    const cmd: ListEquipmentsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort
    };
    this.equipmentService.list(cmd).subscribe((result: ListEquipmentsCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.gridData.data = result.data;
      this.gridData.total = result.totalRowRowCount;
    });
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.equipmentService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }
}
