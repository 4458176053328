import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { StorageMap } from '@ngx-pwa/local-storage';
import { State } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  ChecklistService,
  CourseService,
  EnumListResult,
  EnumValue,
  GetChecklistTypesCommandResult,
  GetLabMaintenanceTaskTypesCommandResult,
  GetLabRequestStatusTypesCommandResult,
  GetTimesheetEntryStatusesCommandResult,
  GetTimesheetEntryTypesCommandResult,
  LabMaintenanceTaskService,
  LabRequestService,
  ListBedsCommand,
  ListBedsCommandResult,
  ListBedsCommandViewModel,
  ListBuildingsCommand,
  ListBuildingsCommandResult,
  ListBuildingsCommandViewModel,
  ListCourseViewModel,
  ListCoursesCommand,
  ListCoursesCommandResult,
  ListLocationsCommand,
  ListManikinMakeAndModelProcedureFrequencyCommandResult,
  ListOrganizationViewModel,
  ListOrganizationsCommand,
  ListOrganizationsCommandResult,
  ListRolesCommandResult,
  ListRoomsCommand,
  ListRoomsCommandResult,
  ListSimMaintenanceTaskTypesCommandResult,
  LocationService,
  LocationViewModel,
  ManikinMakeAndModelProcedureService,
  ManikinMakeAndModelService,
  OrganizationService,
  RoleService,
  RoomService,
  RoomViewModel,
  SimMaintenanceTaskService,
  SupplyService,
  TimesheetEntryMiniViewModel,
  TimesheetService
} from '@wo-api/index';
import { AppGlobals } from '@wo-app/app.global';
import { CacheExpiration } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject, Subscription, interval, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ImpersonationService } from './impersonation.service';
import { RoleProviderService } from './role-provider.service';
@Injectable()
export class CachedDataService {
  private _organizationData = new BehaviorSubject<ListOrganizationViewModel[]>(Array<ListOrganizationViewModel>());
  public readonly organizations: Observable<ListOrganizationViewModel[]> = this._organizationData
    .asObservable()
    .pipe(filter(value => value !== null));

  private _timesheetEntryTypes = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly timesheetEntryTypes: Observable<EnumValue[]> = this._timesheetEntryTypes
    .asObservable()
    .pipe(filter(value => value !== null));

  private _timesheetEntryStatuses = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly timesheetEntryStatuses: Observable<EnumValue[]> = this._timesheetEntryStatuses
    .asObservable()
    .pipe(filter(value => value !== null));

  private _applicationRoles = new BehaviorSubject<ListRolesCommandResult>({});
  public readonly applicationRoles: Observable<ListRolesCommandResult> = this._applicationRoles
    .asObservable()
    .pipe(filter(value => value !== null));

  private _labMaintenanceTaskTypes = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly labMaintenanceTaskTypes: Observable<EnumValue[]> = this._labMaintenanceTaskTypes
    .asObservable()
    .pipe(filter(value => value !== null));

  private _inventoryItemTypes = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly inventoryItemTypes: Observable<EnumValue[]> = this._inventoryItemTypes
    .asObservable()
    .pipe(filter(value => value !== null));

  private _simMaintenanceTaskTypes = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly simMaintenanceTaskTypes: Observable<EnumValue[]> = this._simMaintenanceTaskTypes
    .asObservable()
    .pipe(filter(value => value !== null));

  private _manikinMakeAndModelProcedureFrequencies = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly manikinMakeAndModelProcedureFrequencies: Observable<EnumValue[]> = this._manikinMakeAndModelProcedureFrequencies
    .asObservable()
    .pipe(filter(value => value !== null));

  private _checklistTypes = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly checklistTypes: Observable<EnumValue[]> = this._checklistTypes.asObservable().pipe(filter(value => value !== null));

  private _labRequestStatuses = new BehaviorSubject<EnumValue[]>(Array<EnumValue>());
  public readonly labRequestStatuses: Observable<EnumValue[]> = this._labRequestStatuses
    .asObservable()
    .pipe(filter(value => value !== null));

  // Items that are cached based on the Organization
  public _locations = new BehaviorSubject<LocationViewModel[]>(Array<EnumValue>());
  public readonly locations: Observable<LocationViewModel[]> = this._locations.asObservable();

  private _rooms = new BehaviorSubject<RoomViewModel[]>(Array<RoomViewModel>());
  public readonly rooms: Observable<RoomViewModel[]> = this._rooms.asObservable();

  private _beds = new BehaviorSubject<ListBedsCommandViewModel[]>(Array<EnumValue>());
  public readonly beds: Observable<ListBedsCommandViewModel[]> = this._beds.asObservable();

  private _buildings = new BehaviorSubject<ListBuildingsCommandViewModel[]>(Array<EnumValue>());
  public readonly buildings: Observable<ListBuildingsCommandViewModel[]> = this._buildings.asObservable();

  private _courses = new BehaviorSubject<ListCourseViewModel[]>(Array<ListCourseViewModel>());
  public readonly courses: Observable<ListCourseViewModel[]> = this._courses.asObservable();

  // End of Items Cached based on the Organization
  public _timesheetEntryTemporaryCache = new BehaviorSubject<TimesheetEntryMiniViewModel[]>(Array<TimesheetEntryMiniViewModel>());
  public readonly timesheetEntryTemporaryCache: Observable<TimesheetEntryMiniViewModel[]> =
    this._timesheetEntryTemporaryCache.asObservable();

  public _timesheetEntryTemporaryRecycleBin = new BehaviorSubject<TimesheetEntryMiniViewModel>({});
  public readonly timesheetEntryTemporaryRecycleBin: Observable<TimesheetEntryMiniViewModel> = this._timesheetEntryTemporaryRecycleBin
    .asObservable()
    .pipe(filter(value => value !== null));

  private readonly minutesToCacheData: number = 60;
  private requestedDataItems = -1;
  private completedDataItems = -1;
  private AllDataRefreshIntervalSubscription: Subscription;
  private CheckForCompletedDataSubscription: Subscription;

  state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private destroy$ = new Subject<void>();
  subscription: any;
  authenticated = false;

  constructor(
    private checklistService: ChecklistService,
    private manikinMakeAndModelProcedureService: ManikinMakeAndModelProcedureService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private simMaintenanceTasksService: SimMaintenanceTaskService,
    private SupplyService: SupplyService,
    private labMaintenanceTaskService: LabMaintenanceTaskService,
    private courseService: CourseService,
    private buildingService: BuildingService,
    private rolesService: RoleService,
    private bedService: BedService,
    private roomService: RoomService,
    private timesheetService: TimesheetService,
    private locationService: LocationService,
    private organizationService: OrganizationService,
    impersonationService: ImpersonationService,
    private labRequestService: LabRequestService,
    private http: HttpClient,
    private logger: NGXLogger,
    private tokenService: NbTokenService,
    private authService: NbAuthService,
    private roleProvider: RoleProviderService,
    protected localStorage: StorageMap
  ) {
    this.subscription = authService.isAuthenticatedOrRefresh().subscribe((authenticated: boolean) => {
      this.logger.debug('User is authenticated', authenticated);
      if (authenticated === true) {
        this.initialize();
      }
    });
  }

  public destroy(): void {
    this.logger.debug('Destroying Cached Data Service');
    if (this.AllDataRefreshIntervalSubscription !== null && this.AllDataRefreshIntervalSubscription !== undefined) {
      this.AllDataRefreshIntervalSubscription.unsubscribe();
    }
    if (this.CheckForCompletedDataSubscription !== null && this.CheckForCompletedDataSubscription !== undefined) {
      this.CheckForCompletedDataSubscription.unsubscribe();
    }
    this.localStorage.clear().subscribe(() => {});
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async initialize() {
    this.logger.debug('Initializing Cached Data Service');
    //this.refreshAllData();
    this.AllDataRefreshIntervalSubscription = interval(this.minutesToCacheData * 60000).subscribe(() => {
      try {
        //this.refreshAllData();
      } catch (err) {
        this.logger.error(err);
      }
    });
  }

  public SetItemToTempCache(key: string, value: any): void {
    key = `TempCacheItem_${key}`;
    this.localStorage.set(key, value);
  }

  public GetItemFromTempCache(key: string): any {
    key = `TempCacheItem_${key}`;
    const value = this.localStorage.get(key);
    return value;
  }

  refreshAllData(onlyGetDataIfExpired = true) {
    this.requestedDataItems = 0;
    this.completedDataItems = 0;
    this.logger.info('Loading the Cached Data Service items');
    this.refreshOrganizations('Organizations', onlyGetDataIfExpired);
    this.refreshTimesheetEntryStatus('TimesheetEntryStatuses', onlyGetDataIfExpired);
    this.refreshTimesheetEntryTypes('TimesheetEntryTypes', onlyGetDataIfExpired);
    this.refreshApplicationRoles('ApplicationRoles', onlyGetDataIfExpired);
    this.refreshLabMaintenanceTaskTypes('LabMaintenanceTaskTypes', onlyGetDataIfExpired);
    this.refreshInventoryItemTypes('InventorItemTypes', onlyGetDataIfExpired);
    this.refreshSimMaintenanceTaskTypes('SimMaintenanceTaskTypes', onlyGetDataIfExpired);
    this.refreshManikinMakeAndModelProcedureFrequencies('ManikinMakeAndModelProcedureFrequencies', onlyGetDataIfExpired);
    this.refreshChecklistTypes('ChecklistTypes', onlyGetDataIfExpired);
    this.refreshLabRequestStatuses('LabRequestStatuses', onlyGetDataIfExpired);
    this.getDataForOrganization();
  }

  getDataForOrganization(onlyGetDataIfExpired = true) {
    this.refreshLocations('Locations', onlyGetDataIfExpired);
    this.refreshBuildings('Buildings', onlyGetDataIfExpired);
    this.refreshRooms('Rooms', onlyGetDataIfExpired);
    this.refreshBeds('Beds', onlyGetDataIfExpired);
    this.refreshCourses('Courses', onlyGetDataIfExpired);
  }

  startCheckingForCompletedData() {
    const secondsForInterval = 1;
    this.CheckForCompletedDataSubscription = interval(secondsForInterval * 1000).subscribe(() => {
      if (this.requestedDataItems > 0) {
        this.logger.info(
          'CachedDataService: ' +
            this.requestedDataItems +
            ' items have been requested. ' +
            this.completedDataItems +
            ' items have been completed.'
        );
      }
      if (this.requestedDataItems === this.completedDataItems) {
        this.CheckForCompletedDataSubscription.unsubscribe();
        if (this.requestedDataItems > 0) {
          //this.toastrService.info('The application has just been updated with new data.', 'Cached Data Refreshed');
        }
        this.requestedDataItems = -1;
        this.completedDataItems = -1;
      }
    });
  }

  refreshLocations(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    cacheKey = `Organization_${cacheKey}`;
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        this.logger.debug('Location Data from LocalStorage has been loaded.');
        this._locations.next(data as LocationViewModel[]);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        isCacheExpired = true;
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListLocationsCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;
          this.locationService.list(cmd).subscribe(returnData => {
            this.logger.debug('Location Data returned to Cache Data Service', returnData);
            this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, returnData?.data).subscribe(() => {
              this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
              this.logger.debug('Location Data from DB has been stored in Local Storage.');
              this._locations.next(returnData?.data as LocationViewModel[]);
              this.completedDataItems++;
            });
          });
        }
      });
    });
  }

  refreshRooms(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    cacheKey = `Organization_${cacheKey}`;
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        this._rooms.next(data as RoomViewModel[]);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListRoomsCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;
          this.roomService
            .list(cmd)
            .toPromise()
            .then(returnData => {
              const model = returnData as ListRoomsCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._rooms.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshBeds(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    cacheKey = `Organization_${cacheKey}`;
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as ListBedsCommandViewModel[];
        this._beds.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListBedsCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;
          this.bedService
            .list(cmd)
            .toPromise()
            .then((returnData): void => {
              const model = returnData as ListBedsCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._beds.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshBuildings(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    cacheKey = `Organization_${cacheKey}`;
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as ListBuildingsCommandViewModel[];
        this._buildings.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListBuildingsCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;
          this.buildingService
            .list(cmd)
            .toPromise()
            .then(returnData => {
              const model = returnData as ListBuildingsCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._buildings.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshCourses(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    cacheKey = `Organization_${cacheKey}`;
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as ListCourseViewModel[];
        this._courses.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListCoursesCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;
          this.courseService
            .list(cmd)
            .toPromise()
            .then(returnData => {
              const model = returnData as ListCoursesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._courses.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshTimesheetEntryTypes(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._timesheetEntryTypes.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.timesheetService
            .getTimesheetEntryTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as GetTimesheetEntryTypesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._timesheetEntryTypes.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshApplicationRoles(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as ListRolesCommandResult;
        this._applicationRoles.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.rolesService
            .list()
            .toPromise()
            .then(returnData => {
              const model = returnData as ListRolesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, returnData).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._applicationRoles.next(model);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshLabMaintenanceTaskTypes(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._labMaintenanceTaskTypes.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.labMaintenanceTaskService
            .getLabMaintenanceTaskTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as GetLabMaintenanceTaskTypesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._labMaintenanceTaskTypes.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshInventoryItemTypes(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._inventoryItemTypes.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.SupplyService.listSupplyTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as EnumListResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._inventoryItemTypes.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshChecklistTypes(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._checklistTypes.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.checklistService
            .getChecklistTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as GetChecklistTypesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._checklistTypes.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshSimMaintenanceTaskTypes(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._simMaintenanceTaskTypes.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.simMaintenanceTasksService
            .listSimMaintenanceTaskTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as ListSimMaintenanceTaskTypesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._simMaintenanceTaskTypes.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshManikinMakeAndModelProcedureFrequencies(
    cacheKey: string,
    onlyGetDataIfExpired = true,
    cacheLimitOverride: number = this.minutesToCacheData
  ) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._manikinMakeAndModelProcedureFrequencies.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.manikinMakeAndModelProcedureService
            .listManikinMakeAndModelProcedureFrequencyTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as ListManikinMakeAndModelProcedureFrequencyCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._manikinMakeAndModelProcedureFrequencies.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshTimesheetEntryStatus(cacheKey: string, onlyGetDataIfExpired = true, cacheLimitOverride: number = this.minutesToCacheData) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._timesheetEntryStatuses.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.timesheetService
            .getTimesheetEntryStatuses()
            .toPromise()
            .then(returnData => {
              const model = returnData as GetTimesheetEntryStatusesCommandResult;
              this.logger.debug(returnData);
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), cacheLimitOverride));
                this._timesheetEntryStatuses.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  refreshOrganizations(cacheKey: string, onlyGetDataIfExpired = true) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as ListOrganizationViewModel[];
        this._organizationData.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          const cmd: ListOrganizationsCommand = {};
          cmd.skip = this.state.skip;
          cmd.take = this.state.take;
          cmd.sort = this.state.sort;

          this.organizationService.list(cmd).subscribe((model: ListOrganizationsCommandResult) => {
            this.logger.debug(model);
            this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
              this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), this.minutesToCacheData));
              this._organizationData.next(model.data!);
              this.completedDataItems++;
            });
          });
        }
      });
    });
  }

  refreshLabRequestStatuses(cacheKey: string, onlyGetDataIfExpired = true) {
    this.localStorage.get(AppGlobals.CACHE_KEY_PREFIX + cacheKey).subscribe(data => {
      if (data !== null) {
        const model = data as EnumValue[];
        this._labRequestStatuses.next(model);
      }
      this.isCacheExpired(cacheKey, data).subscribe((isCacheExpired: boolean) => {
        if (isCacheExpired || onlyGetDataIfExpired === false) {
          this.requestedDataItems++;
          this.labRequestService
            .getLabRequestStatusTypes()
            .toPromise()
            .then(returnData => {
              const model = returnData as GetLabRequestStatusTypesCommandResult;
              this.localStorage.set(AppGlobals.CACHE_KEY_PREFIX + cacheKey, model.data).subscribe(() => {
                this.updateCacheKeyExpiration(cacheKey, this.addMinutesToDate(new Date(), this.minutesToCacheData));
                this._labRequestStatuses.next(model.data!);
                this.completedDataItems++;
              });
            });
        }
      });
    });
  }

  getHostName(url) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return null;
    }
  }

  /** Updates the Cache Key Expiration for a Cache Key to a Specific Date */
  updateCacheKeyExpiration(cacheKey: string, expiration: Date) {
    cacheKey = AppGlobals.CACHE_KEY_PREFIX + cacheKey + '_Expiration';
    this.localStorage.get(cacheKey + '_Expiration').subscribe(cacheExpiration => {
      cacheExpiration = new CacheExpiration(cacheKey, expiration.getTime());
      this.localStorage.set(cacheKey, cacheExpiration).subscribe(() => {});
    });
  }

  isCacheExpired(cacheKey: string, currentData: any): Observable<boolean> {
    return of(true);
    cacheKey = AppGlobals.CACHE_KEY_PREFIX + cacheKey;
    const expirationCacheKey = cacheKey + '_Expiration';
    const isDataNull = currentData == null;
    return this.localStorage.get(expirationCacheKey).pipe(
      mergeMap(c => {
        const cacheExpiration = c as CacheExpiration;
        let isCacheExpired = false;
        if (isDataNull) {
          isCacheExpired = true;
        } else if (!cacheExpiration) {
          isCacheExpired = true;
        } else {
          isCacheExpired = cacheExpiration.Expiration < new Date().getTime();
        }
        this.logger.info(cacheKey + (isCacheExpired ? ' IS' : ' IS NOT') + ' expired');
        return of(isCacheExpired);
      })
    );
  }

  addMinutesToDate(date: Date, minutesToAdd: number) {
    return new Date(date.getTime() + minutesToAdd * 60000);
  }

  public dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
