import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { EntityInformation, MenuGroupType } from '@wo-app/app.global';
import { MenuItem } from '@wo-app/shared/models/menu';
import { RouteData } from '@wo-app/shared/models/route';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SideMenuService {
  constructor(private logger: NGXLogger, private router: Router) {}

  public getMenuItemsOfType(groupType: MenuGroupType): MenuItem[] {
    const menuItems = this.getAllMenuItems().filter((x: MenuItem) => {
      return x.menuGroups && x.menuGroups.filter(x => x.groupType == groupType).length > 0;
    });

    const parents = menuItems
      .filter(x => x.menuGroups.some(y => y.nestingLevel == 0))
      .sort((a: MenuItem, b: MenuItem) => {
        const aSortValue = a.menuGroups.filter(x => x.groupType == groupType)[0].sortOrder;
        const bSortValue = b.menuGroups.filter(x => x.groupType == groupType)[0].sortOrder;
        return aSortValue - bSortValue;
      });

    parents.forEach(parent => {
      const allChildren = menuItems.filter(x => x != parent && x.path.startsWith(parent.path));
      parent.children = allChildren;
    });

    return parents;
  }

  private getAllMenuItems(): MenuItem[] {
    const allRoutes: Route[] = this.getAllRoutes(this.router.config);
    if (!allRoutes) return [];

    const menuItems = allRoutes
      .filter(route => route.data)
      .map(route => {
        const routeData = route.data as RouteData;
        if (!routeData.menuGroups) return null;

        const entity = this.getEntityInformation(route.path, allRoutes);

        const menuItem: MenuItem = {
          canActivate: route.canActivate,
          path: `/${route.path}`,
          basePath: entity?.baseRoute,
          menuGroups: routeData.menuGroups,
          title: entity?.pluralName,
          icon: entity?.icon,
          description: entity?.description,
          children: []
        };

        return menuItem;
      })
      .filter(x => x != null);

    return menuItems;
  }

  private getAllRoutes(routes, p = '') {
    return routes.reduce((r, { children = [], ...o }) => {
      const path = `${p + (p.length == 0 ? '' : '/')}${o['path']}`;
      return r.concat(Object.assign({}, o, { path }), this.getAllRoutes(children, path));
    }, []);
  }

  private getEntityInformation(childRoute: string, routes: any[]): EntityInformation | null {
    const parentRoute = childRoute.substring(0, childRoute.indexOf('/'));
    const foundRoute = routes.filter(x => x.path == parentRoute);
    return foundRoute ? foundRoute[0].entityInformation : null;
  }
}
