<autopilot-grid>
  <kendo-grid
    [data]="view"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    (dataStateChange)="dataStateChange($event)">
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto me-auto" role="search">
                  <button kendoButton class="btn btn-primary" type="button" (click)="addNew()" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}}</button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-column field="name" title="Name">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.name}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="created" title="Date Created">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.created | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastUpdated" title="Date Last Updated">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.lastUpdated | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Actions" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton [primary]="true" class="btn btn-primary me-1" [icon]="'pencil'" (click)="routeToEditWithOrganization(dataItem.id)">Edit</button>
          </ng-template>
      </kendo-grid-column>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
