<form [formGroup]="form"
      [hidden]="!showForm"
      class="entity-form"
      (ngSubmit)="onSubmit(false)">
  <div class="row">
    <div class="col-sm-12 col-md-6" [hidden]="editMode">
      <kendo-buttongroup>
        <button kendoButton
          type="submit"
          [disabled]="!form.valid"
          [primary]="true"
          [icon]="'save'">
          Create {{ this.GetSingleEntityName() }}
        </button>
        <button kendoButton
            type="button"
            (click)="resetForm()"
            [primary]="false"
            [icon]="'cancel-circle'">
            Cancel
          </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <kendo-buttongroup>
        <!--
          <button kendoButton
            type="submit"
            [disabled]="!form.valid"
            [primary]="true"
            [icon]="'save'">
            Save and Continue
          </button>
        -->
        <button kendoButton
          type="button"
          (click)="submitAndClose()"
          [primary]="true"
          [disabled]="!form.valid"
          [icon]="'save'">
          Save and Close
        </button>
        <button kendoButton
          type="button"
          (click)="resetForm()"
          [primary]="false"
          [icon]="'cancel-circle'">
            Cancel
          </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <div class="float-end">
        <kendo-buttongroup>
        <!--
          <button kendoButton
            type="button"
            (click)="disable()"
            [icon]="'cancel-circle'">
            Disable {{this.GetSingleEntityName()}}
          </button>
          <button kendoButton
            type="button"
            (click)="delete()"
            [icon]="'delete'">
            Delete {{this.GetSingleEntityName()}}
          </button>
        -->
        </kendo-buttongroup>
        <div kendoDialogContainer></div>
      </div>
    </div>
    <div class="col-sm-12" [hidden]="true">
      <kendo-buttongroup>
        <button kendoButton
          type="button"
          [primary]="true"
          [icon]="'user'">
          View Users
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12">
      <hr />
    </div>
  </div>

  <app-validation-errors></app-validation-errors>
  <input type="hidden" id="id" formControlName="id" />

  <kendo-tabstrip>
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="row">
          <div class="col-sm-12">
            <label for="name" class="col-form-label col-form-label-sm">
              Location Name
            </label>
            <input kendoTextBox add-required-asterisk
              formControlName="name"
              id="name"
              class="form-control"
              placeholder="Location Name" />
            <validation-error-message [control]="name"></validation-error-message>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="address1" class="col-form-label col-form-label-sm">
              Address 1
            </label>
            <input
              kendoTextBox
              formControlName="address1"
              id="address1"
              class="form-control"
              placeholder="Address 1" />
            <validation-error-message [control]="address1"></validation-error-message>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="address2" class="col-form-label col-form-label-sm">
              Address 2
            </label>
            <input
              kendoTextBox
              formControlName="address2"
              id="address2"
              class="form-control"
              placeholder="Address 2" />
            <validation-error-message [control]="address2"></validation-error-message>
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="name" class="col-form-label col-form-label-sm">
              City
            </label>
            <input kendoTextBox
              formControlName="city"
              id="city"
              class="form-control"
              placeholder="City" />
            <validation-error-message [control]="city"></validation-error-message>
          </div>
          <div class="col-sm-12 col-md-6">
            <app-country-and-province-selector></app-country-and-province-selector>
          </div>
          <div class="col-sm-12 col-md-2">
            <label for="name" class="col-form-label col-form-label-sm">
              Postal Code
            </label>
            <input
              kendoTextBox
              formControlName="postalCode"
              id="postalCode"
              class="form-control"
              placeholder="Postal Code" />
            <validation-error-message [control]="postalCode"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="description" class="col-form-label col-form-label-sm">
              Description
            </label>
            <textarea
              kendoTextBox
              type="text"
              formControlName="description"
              id="description"
              class="form-control"
              placeholder="Description">
            </textarea>
            <validation-error-message [control]="description"></validation-error-message>
          </div>
          <input formControlName="organizationId" type="hidden" />
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
      <ng-template kendoTabContent>
        <app-metadata [metadata]="dataModel"></app-metadata>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
