import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import {
  BluetoothPrinterService,
  ImpersonationService,
  CachedDataService,
  LabelPrintStatusWrapper,
  LabelPrintStatus
} from '@wo-app/core/services';
import { Label, LabelType, QrLabelService } from '@wo-app/core/services/qr-label.service';
import { LabelaryImageService } from '@wo-app/core/services/zplpreview-image.service';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-print-labels',
  templateUrl: './print-labels.component.html',
  styleUrls: ['./print-labels.component.scss']
})
export class PrintLabelsComponent extends EntityBaseComponent implements OnInit, OnDestroy {
  constructor(
    private qrLabelService: QrLabelService,
    public bluetoothPrinterService: BluetoothPrinterService,
    public readonly ble: BluetoothCore,
    private router: Router,
    private route: ActivatedRoute,
    impersonationService: ImpersonationService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.PRINTERS, injector);
  }

  private countOfLabelsForSupplies = 0;
  private countOfLabelsForManikinsAndTrainers = 0;
  private countOfLabelsForEquipment = 0;

  labelTypeOptions: LabelTypeRadioOption[] = [];
  selectedLabelType = new BehaviorSubject<LabelTypeRadioOption>(null);
  selectedLabelType$ = this.selectedLabelType.asObservable();
  labelData = new BehaviorSubject<Label[]>([]);
  labelData$ = this.labelData.asObservable();
  progressBarValue = 0;
  startButtonDisabled = false;
  urlSelectedLabelType: LabelType | null = null;

  override ngOnDestroy(): void {
    this.selectedLabelType.unsubscribe();
    this.labelData.unsubscribe();
    this.progressBarValue = 0;
    this.startButtonDisabled = false;
    this.urlSelectedLabelType = null;
  }

  ngOnInit(): void {
    try {
      const labelType = this.route.snapshot.paramMap.get('labelType');
      this.logger.log('urlSelectedLabelType = ', labelType);
      this.urlSelectedLabelType = LabelType[labelType];
      if (this.urlSelectedLabelType === undefined) {
        this.urlSelectedLabelType = null;
      }
      this.logger.log('this.urlSelectedLabelType = ', this.urlSelectedLabelType);
    } catch {}

    this.bluetoothPrinterService.PrinterLabelStatus$.subscribe((value: LabelPrintStatusWrapper[]) => {
      this.logger.log('PrinterLabelStatus$ value changed');
      const countOfQueuedItems = value.filter((value: LabelPrintStatusWrapper) => value.LabelPrintStatus == LabelPrintStatus.Queued).length;
      this.progressBarValue = (1 - countOfQueuedItems / value.length) * 100;
      this.progressBarValue = isNaN(this.progressBarValue) ? 0 : this.progressBarValue;

      if (this.progressBarValue == 100) {
        this.PrintingIsCompleted(value);
      }
    });
    this.checkForExistenceOfLabels();
    this.selectedLabelType$.subscribe((value: LabelTypeRadioOption | null) => {
      if (value != null) {
        this.logger.log('selectLabelType$ value changed', value);
        if (value != null && value != undefined) {
          this.getLabelDataForLabelType(LabelType[value.value]);
        }
      }
    });

    const defaultOption = new LabelTypeRadioOption('Select an option below...', '-1');
    this.labelTypeOptions.push(defaultOption);
    this.selectedLabelType.next(defaultOption);
  }

  public doAnyLabelsExistInBrowserStorage(): boolean {
    return this.countOfLabelsForSupplies > 0 || this.countOfLabelsForManikinsAndTrainers > 0 || this.countOfLabelsForEquipment > 0;
  }

  getLabelDataForLabelType(labelType: LabelType) {
    this.qrLabelService.getQrLabelsFromBrowserStorage(labelType).then(value => {
      this.labelData.next(value);
    });
  }

  checkForExistenceOfLabels() {
    this.qrLabelService.countOfLabelsInStorage(LabelType.Supply).then((value: number) => {
      this.countOfLabelsForSupplies = value;
      if (this.countOfLabelsForSupplies) {
        this.AddValueToLabelTypeOptions(LabelType.Supply, this.countOfLabelsForSupplies);
      } else {
        this.RemoveValueToLabelTypeOptions(LabelType.Supply);
      }
    });
    this.qrLabelService.countOfLabelsInStorage(LabelType.ManikinAndTrainer).then((value: number) => {
      this.countOfLabelsForManikinsAndTrainers = value;
      if (this.countOfLabelsForManikinsAndTrainers) {
        this.AddValueToLabelTypeOptions(LabelType.ManikinAndTrainer, this.countOfLabelsForManikinsAndTrainers);
      } else {
        this.RemoveValueToLabelTypeOptions(LabelType.ManikinAndTrainer);
      }
    });
    this.qrLabelService.countOfLabelsInStorage(LabelType.Equipment).then((value: number) => {
      this.countOfLabelsForEquipment = value;
      if (this.countOfLabelsForEquipment) {
        this.AddValueToLabelTypeOptions(LabelType.Equipment, this.countOfLabelsForEquipment);
      } else {
        this.RemoveValueToLabelTypeOptions(LabelType.Equipment);
      }
    });
  }

  ConvertLabelTypeToLabelTypeRadioOption(labelType: LabelType, labelCount: number | null = null): LabelTypeRadioOption {
    let nameValue = labelType.toString();
    if (labelCount) {
      const countLabel = ` (${labelCount} label${labelCount > 0 ? 's' : ''})`;
      nameValue += countLabel;
    }
    return new LabelTypeRadioOption(nameValue, labelType.toString());
  }

  ConvertLabelTypeRadioOptionToLabelType(labelTypeRadioOption: LabelTypeRadioOption): LabelType {
    return LabelType[labelTypeRadioOption.value];
  }

  AddValueToLabelTypeOptions(labelType: LabelType, labelCount: number) {
    const index = this.labelTypeOptions.indexOf(this.ConvertLabelTypeToLabelTypeRadioOption(labelType, labelCount));
    if (index == -1) {
      this.labelTypeOptions.push(this.ConvertLabelTypeToLabelTypeRadioOption(labelType, labelCount));
    }
  }

  RemoveValueToLabelTypeOptions(labelType: LabelType) {
    const index = this.labelTypeOptions.indexOf(this.ConvertLabelTypeToLabelTypeRadioOption(labelType));
    if (index > -1) {
      this.labelTypeOptions.splice(index, 1);
    }
  }

  PrintingIsCompleted(value: LabelPrintStatusWrapper[]) {
    this.logger.log('Printing is completed');

    //remove items from browser storage that completed successfully
    const printedItems = value
      .filter((value: LabelPrintStatusWrapper) => value.LabelPrintStatus == LabelPrintStatus.Printed)
      .map((value: LabelPrintStatusWrapper) => value.Label);

    this.selectedLabelType$.subscribe((value: LabelTypeRadioOption | null) => {
      if (value != null) {
        const selectedLabelType = LabelType[value.value];
        this.qrLabelService.getQrLabelsFromBrowserStorage(selectedLabelType).then(labelsInStorage => {
          const newStorageItems = labelsInStorage.filter(function (el) {
            return printedItems.includes(el);
          });
          this.logger.log('New Storage items', newStorageItems, printedItems);
          this.qrLabelService.updateQrLabelsInBrowserStorage(selectedLabelType, newStorageItems);
        });
      }
    });
  }

  startPrinting($event: PointerEvent) {
    this.logger.log('Sending Data to Printer', $event);
    this.startButtonDisabled = true;
    this.selectedLabelType$.subscribe((value: LabelTypeRadioOption | null) => {
      if (value != null) {
        this.logger.log('startPrinting selectLabelType', value);
        const selectedLabelType = LabelType[value.value];
        this.bluetoothPrinterService.printLabels(this.labelData.getValue(), selectedLabelType);
      }
    });
  }

  restartPage($event: PointerEvent) {
    this.startButtonDisabled = false;
    this.labelData.next([]);
    this.selectedLabelType.next(null);
    this.progressBarValue = 0;
    this.labelTypeOptions = [];
    this.checkForExistenceOfLabels();
  }
}

export class LabelTypeRadioOption {
  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
  public name: string;
  public value: string;
}
