import { AfterContentChecked, Component, ContentChild, HostListener, Input, NgZone } from '@angular/core';
import { ColumnBase, GridComponent, GridModule } from '@progress/kendo-angular-grid';
import { take } from 'rxjs';

@Component({
  selector: 'autopilot-grid',
  templateUrl: './grid.component.html',
  standalone: true,
  imports: [GridModule]
})
export class AutoPilotGrid implements AfterContentChecked {
  @ContentChild(GridComponent) public grid: GridComponent;
  @Input() public autoSize = true;
  @Input() public columnsNotToAutosize: string[] = [];

  public actionColumn: ColumnBase;

  constructor(private ngZone: NgZone) { }

  public ngAfterContentChecked(): void {
    this.initAutoSize();
  }

  private initAutoSize(): void {
    if (!this.autoSize) return;

    this.grid.resizable = true;
    this.fitColumns();
    this.grid.dataStateChange.subscribe(() => this.fitColumns());
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        const columnsToSize = [];
        this.grid.columnList.forEach(column => {
          if (column !== this.actionColumn && !this.columnsNotToAutosize.includes(column.title) && !column.isColumnGroup) columnsToSize.push(column);
        })
        this.grid.autoFitColumns(columnsToSize);
      });
  }
}
