  <div id="nav-container" class="container-fluid bg-dark">
    <app-side-menu></app-side-menu>
  </div>

  <div id="content-container" class="h-100">
    <div class="container-fluid">
      <!-- Site Header -->
      <div class="row bg-dark" id="site-header">
        <div class="col-12 g-0" #headerStickyMenu [class.sticky]="headerMenuIsSticky">
          <div class="container-fluid">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-start">
              <app-breadcrumb class="d-flex align-items-center text-dark text-decoration-none"
                [allowBootstrap]="true"></app-breadcrumb>
              <div class="nav col-auto me-auto mb-2 justify-content-center"></div>
              <!-- Mobile Hamburger -->
              <button #offcanvasButton
                      class="navbar-toggler text-white d-md-none hamburger hamburger--spin"
                      type="button"
                      (click)="toggleOffCanvasMenu()">
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
              <app-account-menu additionalClasses="d-none" [darkMode]="true"></app-account-menu>
            </div>
          </div>
        </div>
        <!-- Progress Bar/Spinner -->
        <div class="col-12 g-0">
          <ngx-loading-bar class="mb-1 loading-bar" ref="router"
            [color]="ngxLoadingBarOptions.color"
            [fixed]="ngxLoadingBarOptions.fixed"
            [height]="ngxLoadingBarOptions.height"
            [diameter]="ngxLoadingBarOptions.diameter"
            [includeBar]="ngxLoadingBarOptions.includeBar"
            [includeSpinner]="ngxLoadingBarOptions.includeSpinner">
        </ngx-loading-bar>
        </div>
      </div>
      <!-- Site Content -->
      <div id="content-router-wrapper" class="p-3">
          <app-dashboard-reports *ngIf="router.url === '/'"></app-dashboard-reports>
          <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div id="footer" class="row g-0 bg-dark mt-auto text-white position-fixed w-100 bottom-0">
    <div class="col-12 d-none d-md-block">
      <p class="text-body-secondary m-0 py-2">
        &copy; {{CurrentDate | date:'yyyy'}} Wingman Operations, LLC. All Rights Reserved.
      </p>
    </div>
    <!-- Mobile Footer -->
    <div class="col-12 d-md-none">
      <div class="row py-2">
        <div class="col-4 text-center">
          <button type="button" [routerLink]="'/'" class="footer-btn text-light">
            <i class="fa-light fa-dashboard"></i><br />
            <span>Dashboard</span>
          </button>
        </div>
        <div class="col-4 text-center">
          <button type="button" [routerLink]="'/qr-code-scanner'" class="footer-btn text-light">
            <i class="fa-light fa-scanner-touchscreen"></i><br />
            <span>Scan Inventory</span>
          </button>
        </div>
        <div class="col-4 text-center">
          <button type="button" [routerLink]="'/pick-orders'" class="footer-btn text-light">
            <i class="fa-light fa-boxes-stacked"></i><br />
            <span>Pick Orders</span>
          </button>
        </div>
      </div>
    </div>
  </div>

<ng-template #offCanvasMenu>
  <div class="offcanvas-body">
    <div>
      <h5 class="offcanvas-title">
        Quick Actions
      </h5>
      <div class="quick-action-items row">
        <div class="col-6 col-sm-3 text-center py-3">
          <button class="mx-auto" type="button" [routerLink]="'/supplies'"
                  (click)="onQuickActionClick()">
            <i class="fa-light fa-inventory"></i>
          </button>
          <span>View Supplies</span>
        </div>
        <div class="col-6 col-sm-3 text-center py-3">
          <button class="mx-auto" type="button" [routerLink]="'/equipment'"
                  (click)="onQuickActionClick()">
            <i class="fa-light fa-wheelchair"></i>
          </button>
          <span>View Equipment</span>
        </div>
        <div class="col-6 col-sm-3 text-center py-3">
          <button class="mx-auto" type="button" [routerLink]="'/manikin-make-and-model'"
                  (click)="onQuickActionClick()">
            <i class="fa-light fa-users-cog"></i>
          </button>
          <span>View Manikins & Trainers</span>
        </div>
        <div class="col-6 col-sm-3 text-center py-3">
          <button class="mx-auto" type="button" [routerLink]="'/pick-orders'"
                  (click)="onQuickActionClick()">
            <i class="fa-light fa-boxes-stacked"></i>
          </button>
          <span>Pick Orders</span>
        </div>
      </div>
    </div>
    <!-- Mobile Menu -->
    <div>
      <h5>Menu</h5>
      <app-side-menu [showBrandLogo]="false" [slideAnimationActive]="true"></app-side-menu>
    </div>
  </div>
</ng-template>
