<div class="import-job" *ngIf="readImportJobCommandResult | async as job">
  <kendo-tabstrip>
    <!-- General Tab -->
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
        <label class="col-form-label col-form-label-sm">Import Job Type</label>
        <span class="form-control" [innerHtml]="job.importJobType"></span>

        <label class="col-form-label col-form-label-sm">Import Status</label>
        <span class="form-control" [innerHtml]="job.importJobStatus"></span>

        <label class="col-form-label col-form-label-sm">File Record Count</label>
        <span class="form-control" [innerHtml]="job.totalRecordCount"></span>

        <a target="_blank" class="btn btn-primary my-2" [attr.href]="job.importFileSignedUrl">
          View Import File <i class="fa-solid fa-eye"></i>
        </a>

        <!-- Import Errors -->
        <div class="col-sm-12" *ngIf="job.jobLineItems.length > 0 && !readyForImport">
          <h3>Errors in the Import</h3>
          <kendo-grid
            [data]="gridData | async"
            [loading]="isLoading" [pageable]="true"
            [sortable]="false"
            [navigable]="true"
            [sort]="gridState.sort"
            [skip]="gridState.skip"
            [pageSize]="gridState.take"
            (edit)="editHandler($event)"
            (save)="saveHandler($event)"
            (cancel)="cancelHandler($event)"
            (remove)="removeHandler($event)"
            (dataStateChange)="onStateChange($event)">
            <!-- Status -->
            <kendo-grid-column field="importJobLineItemStatus" title="Status">
            </kendo-grid-column>
            <!-- Editable columns -->
            <ng-container *ngFor="let item of columnsToCreate | async">
              <ng-container *ngIf="item.columnField === 'editable_buildingid'; else standardColumn">
                <!-- Building column -->
                <kendo-grid-column [field]="item.columnField" [title]="item.columnTitle">
                  <ng-template #validationErrorTitle>
                    <i class="fa-solid fa-circle-exclamation me-2"></i>
                    <span>Validation Errors</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="validation-icon"
                          [hidden]="!hasValidationErrors(item.columnField, dataItem.validationErrors)">
                      <i class="fa-solid fa-circle-exclamation me-2" triggers="mouseenter:mouseleave"
                          [ngbPopover]="getPopoverContent(item.columnField, dataItem.validationErrors)"
                          [popoverTitle]="validationErrorTitle">
                      </i>
                    </span>
                    <span>{{ getBuildingName(dataItem[item.columnField]) | default }}</span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
                    <kendo-dropdownlist add-required-asterisk
                      textField="name"
                      valueField="id"
                      [formControl]="formGroup.get('editable_buildingid')"
                      [data]="($buildings | async)?.data"
                      [valuePrimitive]="true">
                    </kendo-dropdownlist>
                  </ng-template>
                </kendo-grid-column>
              </ng-container>
              <!-- All other editable columns -->
              <ng-template #standardColumn>
                <kendo-grid-column [field]="item.columnField" [title]="item.columnTitle">
                  <ng-template #validationErrorTitle>
                    <i class="fa-solid fa-circle-exclamation me-2"></i>
                    <span>Validation Errors</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="validation-icon"
                          [hidden]="!hasValidationErrors(item.columnField, dataItem.validationErrors)">
                      <i class="fa-solid fa-circle-exclamation me-2" triggers="mouseenter:mouseleave"
                          [ngbPopover]="getPopoverContent(item.columnField, dataItem.validationErrors)"
                          [popoverTitle]="validationErrorTitle">
                      </i>
                    </span>
                    <span>{{ dataItem[item.columnField] | default }}</span>
                  </ng-template>
                </kendo-grid-column>
              </ng-template>
            </ng-container>
            <!-- Action Buttons -->
            <kendo-grid-command-column title="Actions" [width]="200">
              <ng-template kendoGridCellTemplate let-isNew="isNew">
                <button kendoGridEditCommand [primary]="true">Edit</button>
                <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
                  {{ isNew ? 'Add' : 'Update' }}
                </button>
                <button kendoGridCancelCommand>
                  {{ isNew ? 'Discard changes' : 'Cancel' }}
                </button>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </div>

        <!-- Ready for Import -->
        <div class="col-sm-12" *ngIf="readyForImport">
          <div *ngIf="job.importJobStatus === 'Complete'">
            <p>This file has been imported.</p>
          </div>
          <div *ngIf="job.importJobStatus !== 'Complete'">
            <p>This file is now ready for import.</p>
            <button type="button" class="btn btn-primary btn-lg" (click)="beginImport()">Begin Import</button>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <!-- Metadata Tab -->
    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
      <ng-template kendoTabContent>
        <app-metadata [metadata]="job"></app-metadata>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
