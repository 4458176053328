import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  AddUserToOrganizationCommand,
  ListOrganizationsCommand,
  ListRolesCommandResult,
  OrganizationService,
  RoleService,
  UserService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-users-in-organization',
  templateUrl: './users-in-organization.component.html',
  styleUrls: ['./users-in-organization.component.scss']
})
export class UsersInOrganizationComponent extends EntityBaseComponent implements OnInit {
  @Input() userId: string;
  @Input() username: string;
  public usersInOrganizationGridView: GridDataResult;
  public organizationsGridView: GridDataResult;
  listOrganizationsCommand: ListOrganizationsCommand = {};
  addUserToOrganizationCommand: AddUserToOrganizationCommand = {};
  notificationDefaults: any = {
    timeOut: 1000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  };
  roles: any[];

  constructor(
    injector: Injector,
    private userService: UserService,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    private roleService: RoleService
  ) {
    super(EntityGlobals.USERS, injector);
  }

  ngOnInit() {
    this.bindGridViews();
    this.bindRoles();
  }

  bindRoles() {
    this.roleService.list().subscribe((result: ListRolesCommandResult) => {
      //this.roles = result.data;
    });
  }

  bindGridViews() {
    this.getAssignedOrganizations();
    this.getallOrganizations();
  }

  removeFromOrganization(organizationId) {
    this.userService.removeUserFromOrganization(this.userId, organizationId).subscribe((result: any) => {
      this.toastService.success('Success!', 'User has been removed from the organization.');
      this.bindGridViews();
    });
    this.toastService.error('Error', 'There was a problem making this change. Please try again.');
  }

  addToOrganization(organizationId) {
    this.addUserToOrganizationCommand.userId = this.userId;
    this.addUserToOrganizationCommand.roleId = 0;

    this.userService.addToOrganization(this.addUserToOrganizationCommand).subscribe((result: any) => {
      this.toastService.success('Success!', 'User has been removed from the organization');
      this.bindGridViews();
    });
  }

  getallOrganizations() {
    this.listOrganizationsCommand.skip = 0;
    this.listOrganizationsCommand.take = 100;
    //this.listOrganizationsCommand.sort = null;
    //this.listOrganizationsCommand.filter = null;

    this.organizationService.list(this.listOrganizationsCommand).subscribe((result: any) => {
      this.organizationsGridView = {
        data: result.data,
        total: result.data.length
      };
    });
  }

  getAssignedOrganizations() {
    this.userService.listOrganizationsForUser(this.userId).subscribe((result: any) => {
      this.usersInOrganizationGridView = {
        data: result.data,
        total: result.data.length
      };
    });
  }
}
