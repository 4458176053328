<autopilot-grid>
    <kendo-grid 
        [data]="gridData" 
        [style.maxHeight.%]="100"
        [loading]="isLoading"
        [pageSize]="state.take" 
        [skip]="state.skip" 
        [sort]="state.sort" 
        [filterable]="false" 
        [sortable]="false" 
        [loading]="isLoading" [pageable]="true" 
        [filter]="filter"
        (filterChange)="filterChange($event)" 
        (dataStateChange)="dataStateChange($event)"
        [navigable]="true">
        <kendo-grid-column field="supplyName" title="Item Name">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="#" class="link" [routerLink]="'/supplies/edit/' + dataItem.supplyId">{{ dataItem.supplyName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customId" title="Custom ID">
        </kendo-grid-column>
        <kendo-grid-column field="manufacturerNumber" title="Manufacturer #">
        </kendo-grid-column>
        <kendo-grid-column field="quantity" title="Quantity">
            <ng-template kendoGridCellTemplate let-dataItem>
                <app-quantity-display-component [dataItem]="dataItem"></app-quantity-display-component>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="quantityReserved" title="Quantity Reserved">
        </kendo-grid-column>
        <kendo-grid-column field="quantityAvailable" title="Quantity Available">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="type" title="Type">
        </kendo-grid-column> -->
        <div *kendoGridDetailTemplate="let dataItem">
            <app-building-inventory-detail [item]="dataItem"></app-building-inventory-detail>
        </div>
        <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
        </kendo-grid-pdf>
        <kendo-grid-excel [fileName]="excelFileName">
        </kendo-grid-excel>
    </kendo-grid>
</autopilot-grid>