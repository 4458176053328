<div>
    <h1>{{item.labRequestName}}</h1>
</div>
<div class="row">
    <div class="col-sm-8">
        <div class="row">
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <i class="fa-light fa-tasks"></i> Tasks
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of tasks">
                            <app-task-check-box [isChecked]="item.taskStatus === 'Completed'" [taskId]="item.id" [organizationId]="organizationId" [dueDate]="item.dateTimeDue" [taskName]="item.name"></app-task-check-box>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Inventory Changes</h5>
                        <h6 class="card-subtitle mb-2">Text explaining what this does</h6>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of inventoryChanges">
                            <a style="display:block;" [routerLink]="['/supplies/', organizationId, 'view-change', item.groupId]">View Inventory Changes from {{item.timestamp | amCalendar}}</a>
                        </li>
                    </ul>
                    <div class="card-footer">
                        <a [routerLink]="['/supplies/', organizationId, 'create-change-request', id]"><i class="fas fa-plus"></i> Create Inventory Alignment</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Requestor Information</h5>
                <div>{{item.requestorName}}</div>
                <div><a href="mailto:{{item.requestorEmail}}">{{item.requestorEmail}}</a></div>
                <div>{{item.requestorPhone}}</div>
                <div>Course: {{item.courseName}}</div>
            </div>
        </div>
        <hr>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Dates and Times</h5>
                <h6 class="card-subtitle mb-2 text-body-secondary">The scheduled dates and times for this scenario</h6>
                <kendo-grid [data]="labRequestDatesAndTimes">
                    <kendo-grid-column field="dateTimeStart" title="Start Date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.dateTimeStart | amLocal | amDateFormat: 'MM/DD/YYYY h:mm:ss a'}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="dateTimeEnd" title="Start Date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.dateTimeEnd | amLocal | amDateFormat: 'MM/DD/YYYY h:mm:ss a'}}
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>