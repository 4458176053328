export default class ArrayUtilities {
  static SearchArrayForValue(items: any[], columnName: string, columnValue: any): any {
    if (!items) return null;
    const foundItem = items.find(x => x[columnName] == columnValue);
    if (foundItem) {
      return foundItem;
    }
    return null;
  }
}
