import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimesheetDayGroupViewModel, TimesheetEntryMiniViewModel } from '@wo-api/index';
import { Nullable } from '@wo-app/shared/models/nullable-type';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-timesheet-cell-editor',
  templateUrl: './timesheet-cell-editor.component.html',
  styleUrls: ['./timesheet-cell-editor.component.scss']
})
export class TimesheetCellEditorComponent implements OnInit {
  @Input() public Disabled: boolean;
  @Input() public dateOfEntry: Date;
  @Input() public timesheetDayGroupViewModel: TimesheetDayGroupViewModel;
  @Input() public HideDetailsIndicator: Nullable<boolean> = null;
  @Output() minutesForEntryChanged = new EventEmitter();
  @Output() dataForPopupChanged = new EventEmitter();
  public model: string;
  public isValid: Nullable<boolean> = null;
  public Entries: TimesheetEntryMiniViewModel[] = [];

  get hasMultipleEntries(): boolean {
    return this.Entries.length > 1;
  }

  constructor(private logger: NGXLogger) {
    this.model = '';
    this.isValid = true;
  }

  public setMyClasses(): any {
    return {
      hasMultipleEntries: false,
      'is-valid': this.isValid,
      'is-invalid': !this.isValid
    };
  }

  public cellHasBeenClick(): void {
    if (this.Entries.length > 1) {
      this.showEntryPopup();
    }
  }

  public showEntryPopup() {
    this.dataForPopupChanged.emit(this.Entries);
  }

  initializeControl() {
    if (this.timesheetDayGroupViewModel && this.timesheetDayGroupViewModel.entries) {
      this.Entries = this.timesheetDayGroupViewModel.entries;
    } else {
      this.Entries = [];
    }
    if (this.timesheetDayGroupViewModel === null) {
      // No Value from the Database
      this.model = '';
      const item: TimesheetEntryMiniViewModel = {
        durationInMinutes: 0
        //dateOfEntry: this.dateOfEntry
      };
      this.Entries.push(item);
      if (this.HideDetailsIndicator === null) {
        this.HideDetailsIndicator = true;
      }
    } else if (this.Entries.length === 1) {
      this.model = this.Entries[0].durationInMinutes.toString() + ' m';
      this.processValue(null);

      if (this.HideDetailsIndicator === null) {
        this.HideDetailsIndicator = false;
      }
    } else if (this.Entries.length > 1) {
      if (this.HideDetailsIndicator === null) {
        this.HideDetailsIndicator = false;
      }
      //this.myClasses.hasMultipleEntries = true;
      let entryDurationSum = 0;
      for (let x = 0; x < this.Entries.length; x++) {
        entryDurationSum += this.Entries[x].durationInMinutes;
      }
      this.model = entryDurationSum + ' m';
      this.logger.debug('entryDurationSum: ', entryDurationSum);
      this.processValue(null);
    }
  }

  ngOnInit(): void {
    this.initializeControl();
  }

  public processValue($event) {
    this.logger.debug($event);
    if (this.model.length > 0) {
      this.model = this.model.toLowerCase();
      const timeWithColon =
        /^([0-2]{0,1})([0-9]{1})([Hh])\s*(([0-6]{0,1})([0-9]{0,1})([Mm])?)|([0-9]{1,2})\.([0-9]{0,2})([Hh]{0,1})|([0-9]{0,4})([Mm]{1})/gm;
      this.isValid = timeWithColon.test(this.model);
      this.logger.debug(`'Value has changed to \'${this.model}\'. This value ${this.isValid ? 'IS' : 'IS NOT'} valid.'`);

      let hours = 0;
      let minutes = 0;
      if (this.isValid === true) {
        const indexOfH = this.model.indexOf('h');
        const indexOfM = this.model.indexOf('m');
        if (indexOfH > -1) {
          const hourSplit = this.model.split('h');
          hours = this.splitOnHours(this.model);
          if (hourSplit.length > 1) {
            minutes = this.splitOnMinutes(hourSplit[1]);
          }
        } else if (indexOfM > -1) {
          minutes = this.splitOnMinutes(this.model);
        } else {
          hours = parseFloat(this.model);
        }
        const minutesForEntry = hours * 60 + minutes;
        this.model = this.convertToCorrectFormat(minutesForEntry);

        // If the event is null, don't bubble up the changes
        if ($event != null) {
          if (this.Entries.length === 0) {
            const newItem: TimesheetEntryMiniViewModel = {
              dateOfEntry: this.timesheetDayGroupViewModel.dateOfEntry,
              durationInMinutes: minutesForEntry,
              entryStatus: this.timesheetDayGroupViewModel.entryStatus,
              entryType: this.timesheetDayGroupViewModel.entryType,
              timesheetEntryTypeForeignKeyValue: this.timesheetDayGroupViewModel.timesheetEntryTypeForeignKeyValue
            };
            this.Entries.push(newItem);
          } else {
            this.Entries[0].durationInMinutes = minutesForEntry;
          }
          this.minutesForEntryChanged.emit(this.Entries);
        }
      }
    }
  }

  convertToCorrectFormat(value: number): string {
    const leftover = value % 60;
    const format = `${Math.floor(value - leftover) / 60}h ${leftover}m`;
    return format;
  }

  splitOnMinutes(value: string): number {
    let minutes = 0;
    const minuteSplit = value.split('m');
    minutes = parseFloat(minuteSplit[0]);
    if (isNaN(minutes)) minutes = 0;
    return minutes;
  }

  splitOnHours(value: string): number {
    let hours = 0;
    const hourSplit = value.split('h');
    hours = parseFloat(hourSplit[0]);
    if (Number.isNaN(hours)) hours = 0;
    return hours;
  }
}

export class SmallEntry {
  constructor(minutesForEntry: number, notes: string) {
    this.MinutesForEntry = minutesForEntry;
    this.Notes = notes;
  }
  public Notes: string;
  public MinutesForEntry: number;
}
