import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[add-required-asterisk]'
})
export class MarkAsteriskDirective implements OnInit {
  public static requiredAsteriskHtml = '<span class="required-asterisk">*</span>';

  constructor(private renderer: Renderer2, private el: ElementRef, private control: NgControl) {}

  ngOnInit() {
    const abstractControl = this.control.control;
    if (abstractControl.hasValidator(Validators.required)) {
      const parent = this.renderer.parentNode(this.el.nativeElement);
      if (parent.getElementsByTagName('LABEL').length && !parent.getElementsByClassName('required-asterisk').length) {
        parent.getElementsByTagName('LABEL')[0].innerHTML += MarkAsteriskDirective.requiredAsteriskHtml;
      }
    }
  }
}
