<autopilot-grid>
  <kendo-grid
      [data]="gridData"
      [style.maxHeight.%]="100"
      [pageSize]="state.take"
      [filterable]="false"
      [sortable]="false"
      [sort]="sort"
      [loading]="isLoading" [pageable]="true"
      [skip]="state.skip"
      (pageChange)="pageChangeEvent($event)"
  (dataStateChange)="dataStateChangeEvent($event)">
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto me-auto" role="search">
                    <button kendoButton type="button"  class="btn btn-secondary text-white" routerLink="/equipment"><i class="fa-light fa-arrow-left-long"></i> View All {{this.GetPluralEntityName()}}</button>
                  <button kendoButton type="button"  class="btn btn-secondary text-white" (click)="addNewWithOrganizationId(organizationId)"><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}}</button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-column title="Actions" [width]="158" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton
                    class="btn btn-primary text-white default-action-button"
                    title="View Locations & Stocks"
                    [routerLink]="'/equipment/' + dataItem.id + '/line-items'">
              <i class="fa-inventory fa-light"></i>
                  </button>
            <button kendoButton
                    class="btn btn-outline-primary default-action-button"
                    title="Edit this item"
                    [primary]="true"
                    (click)="routeToEditWithOrganization(dataItem.id)">
              <i class="fas fa-edit"></i></button>
            <button kendoButton
                    class="btn btn-outline-danger default-action-button"
                    title="Delete this item"
                    [primary]="true"
                    (click)="delete(dataItem.id)">
              <i class="fas fa-trash"></i>
            </button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="id" title="Equipment Id">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-numeric-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-numeric-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" title="Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manufacturerVendorName" title="Manufacturer">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manufacturerNumber" title="Manufacturer Number">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel [fileName]="excelFileName" [fetchData]="allData">
          <kendo-excelexport-column field="id" title="Supply Id">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="name" title="Supply Name">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="type" title="Supply Type">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="created" title="Date Created">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="lastUpdated" title="Date Last Updated">
          </kendo-excelexport-column>
      </kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>
