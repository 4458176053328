<autopilot-grid>
  <kendo-grid
    [data]="gridData"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    [loading]="isLoading"
    [filterable]="false"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    (dataStateChange)="dataStateChange($event)"
    (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)">
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto mb-2 me-auto" role="search">
                  <button kendoButton type="button" class="btn btn-secondary text-light" (click)="addNewWithOrganizationId(organizationId)" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}}</button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-column title="Actions" [width]="190">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button kendoGridEditCommand [primary]="true" class="btn btn-primary default-action-button"   (click)="routeToEditWithOrganization(dataItem.id)" title="Edit this item"><i class="fas fa-edit"></i></button>
                  <button kendoGridRemoveCommand [primary]="true" class="btn btn-primary default-action-button" title="Delete this item"><i class="fas fa-trash"></i></button>
                  <button kendoGridEditCommand [primary]="true" class="btn btn-primary default-action-button" (click)="copyItem(dataItem.id)" title="Copy this item"><i class="fas fa-copy"></i></button>
                  <button kendoGridSaveCommand [primary]="true" [disabled]="formGroup?.invalid">Update</button>
                  <button kendoGridCancelCommand>Cancel</button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="id" title="Pick Order #">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.id }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Date Created">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-date-filter-cell
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-date-filter-cell>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.createdDate | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dateNeededDate" title="Date Needed"> 
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <kendo-grid-date-filter-cell
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-date-filter-cell>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.dateNeededDate | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="pickOrderStatus" title="Status">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoGridEditCommand *ngIf="dataItem.pickOrderStatus === 'Started' || dataItem.pickOrderStatus === 'InProgress' || dataItem.pickOrderStatus === 'Complete'" title="Update Status"><span class="p-2">{{ dataItem.pickOrderStatus }}</span><i class="fas fa-edit"></i></button>
              <span class="p-2" *ngIf="dataItem.pickOrderStatus === 'Canceled' || dataItem.pickOrderStatus === 'Closed'">{{ dataItem.pickOrderStatus }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
            <kendo-dropdownlist
              [data]="getStatusList(dataItem.pickOrderStatus)"
              [valuePrimitive]="true"
              [formControl]="formGroup.get('pickOrderStatus')"
            >
            </kendo-dropdownlist>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="customerName" title="Customer">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.customerName }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="assigneeUserFullName" title="Assigned to">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton (click)="assignItem(dataItem.id)" title="Assign Pick Order"><span *ngIf="dataItem.assigneeUserFullName" class="p-2">{{ dataItem.assigneeUserFullName }}</span><i class="fas fa-user-tag"></i></button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>

<div kendoDialogContainer></div>
