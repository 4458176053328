import { Component, Input, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State, filterBy, orderBy } from '@progress/kendo-data-query';
import { ListUsersCommand, ListUsersCommandResult, UserService, UserViewModel } from '@wo-api/index';
import { ModalService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pick-order-user-selection-dialog',
  templateUrl: './pick-order-user-selection-dialog.component.html',
  styleUrls: ['./pick-order-user-selection-dialog.component.scss']
})
export class PickOrderUserSelectionDialogComponent implements OnInit {
  @Input() public inputs: any;
  @Input() public error: any;
  @Input() public outputs: any;

  public title: string;
  public buttons: string[] = [];

  public gridData: UserViewModel[] = [];
  public view: GridDataResult;
  public isLoading = false;
  public pageSize = 25;

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'name' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public selectableSettings: SelectableSettings;
  public selectedUserId: string;

  constructor(
    private modalService: ModalService,
    private dialogService: DialogService,
    private userService: UserService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger
  ) {
    this.state.sort = [{ dir: 'asc', field: 'name' }];
    this.pageSize = 25;
    this.setSelectableSettings();
  }

  private setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single',
      drag: false
    };
  }

  ngOnInit(): void {
    this.title = this.inputs.title ? this.inputs.title : 'Pick Order User Selection';
    this.defineButtons();
    this.reloadData();
  }

  private defineButtons() {
    if (this.inputs.buttons) {
      this.buttons = this.inputs.buttons;
    } else {
      this.buttons.push('Close');
    }
  }

  public reloadData(): void {
    this.isLoading = true;
    this.loadingBar.useRef('router').start();

    const listUsersCommand: ListUsersCommand = {
      skip: 0,
      take: 1000
    };
    this.userService.list(listUsersCommand).subscribe((value: ListUsersCommandResult) => {
      this.logger.debug('Got Data from List(ListUsersCommandResult)', value);
      this.loadingBar.useRef('router').complete();
      this.gridData = value.data as UserViewModel[];
      this.query();
      this.isLoading = false;
    });
  }

  public query() {
    const filteredData = filterBy(this.gridData, this.filter);
    const sortedGridData = orderBy(filteredData, this.state.sort ?? [{ dir: 'asc', field: 'name' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.pageSize),
      total: sortedGridData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.query();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public processRowSelection(event: SelectionEvent): void {
    if (event.selectedRows.length > 0) {
      this.selectedUserId = event.selectedRows[0].dataItem.id;
    } else {
      this.selectedUserId = '';
    }
  }

  public close(answer: any) {
    const result = {
      selectedUserId: '',
      answer
    };
    if (answer === 'Confirm') {
      result.selectedUserId = this.selectedUserId;
    }
    this.modalService.destroy(result);
    this.dialogService;
  }
}
