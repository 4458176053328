import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { ListSupplysCommand, ListSupplysCommandResult, SupplyService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-inventoryitems',
  templateUrl: './inventoryitems.component.html',
  styleUrls: ['./inventoryitems.component.scss']
})
export class InventoryItemsComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private SupplyService: SupplyService,
    private route: ActivatedRoute,
    private router: Router,
    injector: Injector
  ) {
    super(EntityGlobals.SUPPLIES, injector);
    this.allData = this.allData.bind(this);
  }

  gridData: any[];
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'name' }];

  ngOnInit() {
    this.reloadData();
  }

  public allData(): ExcelExportData {
    this.logger.debug('this.gridData', this.gridData);
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: this.sort
      }).data
    };
    return result;
  }

  public reloadData(): void {
    this.loadingBar.useRef('router').start();
    const cmd: ListSupplysCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort
    };
    this.SupplyService.list(cmd).subscribe((result: ListSupplysCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.gridData = result.data.map(item => ({
        ...item,
        createdDate: new Date(item.created),
        lastUpdatedDate: new Date(item.lastUpdated)
      }));
    });
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.SupplyService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }
}
