import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CommunicationBoardItemService,
  ListCommunicationBoardCalendarInformationCommand,
  ListCommunicationBoardCalendarInformationCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-communication-board-calendar',
  templateUrl: './communication-board-calendar.component.html',
  styleUrls: ['./communication-board-calendar.component.scss']
})
export class CommunicationBoardCalendarComponent extends EntityBaseComponent implements OnInit {
  data: any[] = [];
  hideElements: boolean;

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private communicationBoardItemService: CommunicationBoardItemService,
    private route: ActivatedRoute,
    private router: Router,
    injector: Injector
  ) {
    super(EntityGlobals.COMMUNICATION_BOARD, injector);
  }

  ngOnInit() {
    this.getCalendarItems();
  }

  getCalendarItems(): void {
    const command: ListCommunicationBoardCalendarInformationCommand = {};
    this.communicationBoardItemService
      .getCalendarItems(command)
      .subscribe((result: ListCommunicationBoardCalendarInformationCommandResult) => {
        this.logger.debug(result);
        if (result && result.data) {
          this.data = result.data;
          this.hideElements = false;
        }
      });
  }
}
