import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateLabMaintenanceTaskCommand,
  CreateLabMaintenanceTaskCommandResult,
  EnumValue,
  LabMaintenanceTaskService,
  ReadLabMaintenanceTaskCommandResult,
  UpdateLabMaintenanceTaskCommand,
  UpdateLabMaintenanceTaskCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-labmaintenancetask-detail',
  templateUrl: './labmaintenancetask-detail.component.html',
  styleUrls: ['./labmaintenancetask-detail.component.scss']
})
export class LabMaintenanceTaskDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readLabMaintenanceTaskCommandResult: ReadLabMaintenanceTaskCommandResult = {};
  dataModel: ReadLabMaintenanceTaskCommandResult;
  updateLabMaintenanceTaskCommand: UpdateLabMaintenanceTaskCommand = {};
  createLabMaintenanceTaskCommand: CreateLabMaintenanceTaskCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  labMaintenanceTaskTypes: EnumValue[] = [];

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private labMaintenanceTaskService: LabMaintenanceTaskService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public cachedDataService: CachedDataService,
    private dialogService: DialogService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(EntityGlobals.LAB_MAINTENANCE_TASKS, injector);
    this.createForm();
    this.OrganizationId.subscribe((value: number) => {
      this.cachedDataService.labMaintenanceTaskTypes.subscribe((taskTypes: EnumValue[]) => {
        this.labMaintenanceTaskTypes = taskTypes;
      });
      this.showForm = false;
      this.fromKey = 'FormModel';
      if (this.editMode) {
        this.getData();
      } else {
        this.showForm = true;
      }
    });
  }

  convertServerDataToFormModel(result) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.labMaintenanceTaskService.read(this.id).subscribe((result: ReadLabMaintenanceTaskCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  ngOnInit() {}

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      onlyWhenInUseDuringTimePeriod: [''],
      labMaintenanceTaskType: [''],
      organizationId: [''],
      organizationName: [''],
      buildingName: [''],
      buildingId: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    this.form.controls['labMaintenanceTaskType'].setValue(parseInt(this.form.controls['labMaintenanceTaskType'].value, 10));
    this.logger.debug(this.form.controls['labMaintenanceTaskType'].value);
    if (this.editMode) {
      this.updateLabMaintenanceTaskCommand = this.form.value;
      this.logger.debug(this.updateLabMaintenanceTaskCommand);
      this.labMaintenanceTaskService
        .update(this.updateLabMaintenanceTaskCommand.id.toString(), this.updateLabMaintenanceTaskCommand)
        .subscribe((result: UpdateLabMaintenanceTaskCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      this.createLabMaintenanceTaskCommand = this.form.value;
      this.logger.debug(this.createLabMaintenanceTaskCommand);
      this.labMaintenanceTaskService
        .create(this.createLabMaintenanceTaskCommand)
        .subscribe((result: CreateLabMaintenanceTaskCommandResult) => {
          this.convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this simmaintenancetask?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
