import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { NGXLogger } from 'ngx-logger';
import { Label, LabelType } from './qr-label.service';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from '../common/toast-message/shared/services';
import { Device } from 'zebra-browser-print-wrapper/lib/types';
import { LabelaryImageService } from './zplpreview-image.service';
import { ConvertImageToZplCommandResult } from '@wo-api/index';
const Zebra = require('zebra-browser-print-wrapper');

@Injectable({
  providedIn: 'root'
})
export class BluetoothPrinterService {
  public readonly ZebraService: ZebraBrowserPrintWrapper;
  public readonly PrinterLabelStatus = new BehaviorSubject<LabelPrintStatusWrapper[]>([]);
  public readonly PrinterLabelStatus$ = this.PrinterLabelStatus.asObservable();
  private readonly VariablePrefix: string = 'variable_';
  private DefaultPrinter: Device | null = null;

  private readonly QRCodeImageSpecs: any = {
    PositionX: 640,
    PositionY: 60,
    Resolution: 300,
    Width: 250,
    Height: 250
  };

  constructor(
    private ZPLPreviewImageService: LabelaryImageService,
    private toastService: ToastService,
    public http: HttpClient,
    public logger: NGXLogger,
    public readonly ble: BluetoothCore
  ) {
    // Create a new instance of the object
    this.ZebraService = new Zebra.default() as ZebraBrowserPrintWrapper;
    this.SetupPrintService();
  }

  private async SetupPrintService() {
    try {
      // Select default printer
      if (this.DefaultPrinter == null) {
        this.DefaultPrinter = await this.ZebraService.getDefaultPrinter();
        this.logger.log(this.DefaultPrinter);
      }

      // Set the printer
      this.ZebraService.setPrinter(this.DefaultPrinter);
      const printerStatus = await this.ZebraService.checkPrinterStatus();
      if (!printerStatus.isReadyToPrint) {
        this.showPrinterConnectionError();
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  // Zebra Bluetooth LE services and characteristics UUIDs
  ZPRINTER_DIS_SERVICE_UUID: BluetoothServiceUUID = '0000180A-0000-1000-8000-00805F9B34FB'.toLowerCase(); // Or "180A". Device Information Services UUID
  ZPRINTER_SERVICE_UUID: BluetoothServiceUUID = '38EB4A80-C570-11E3-9507-0002A5D5C51B'.toLowerCase(); // Zebra Bluetooth LE Parser Service
  READ_FROM_ZPRINTER_CHARACTERISTIC_UUID: BluetoothServiceUUID = '38EB4A81-C570-11E3-9507-0002A5D5C51B'.toLowerCase(); // Read from printer characteristic
  WRITE_TO_ZPRINTER_CHARACTERISTIC_UUID: BluetoothServiceUUID = '38EB4A82-C570-11E3-9507-0002A5D5C51B'.toLowerCase(); // Write to printer characteristic

  RequestDeviceOptions: RequestDeviceOptions = {
    //acceptAllDevices: true,
    filters: [
      {
        //name: "WO Label Printer"
        namePrefix: 'WO'
        /*services: [
          this.ZPRINTER_DIS_SERVICE_UUID
        ],*/
      } as BluetoothLEScanFilter
    ],
    optionalServices: [
      this.ZPRINTER_DIS_SERVICE_UUID,
      this.ZPRINTER_SERVICE_UUID,
      this.READ_FROM_ZPRINTER_CHARACTERISTIC_UUID,
      this.WRITE_TO_ZPRINTER_CHARACTERISTIC_UUID
    ]
  };

  public async GetZplTemplateForLabel(label: Label, labelType: LabelType): Promise<string> {
    let templatePromise: Observable<string>;
    templatePromise = await this.getLabelTemplate(labelType);
    return new Promise<string>(resolve => {
      templatePromise.subscribe(async (templateString: string) => {
        (
          await this.ZPLPreviewImageService.ConvertImageToZPLGraphic(
            label.qrCode,
            this.QRCodeImageSpecs.PositionX,
            this.QRCodeImageSpecs.PositionY,
            this.QRCodeImageSpecs.Resolution,
            this.QRCodeImageSpecs.Width,
            this.QRCodeImageSpecs.Height
          )
        ).subscribe((result: ConvertImageToZplCommandResult) => {
          const zplTemplate = this.replaceTemplateVariablesForLabel(templateString, label, result.zplCode);
          resolve(zplTemplate);
        });
      });
    });
  }

  public async printLabels(labelsToPrint: Label[], labelType: LabelType) {
    this.PrinterLabelStatus.next([]);
    const printerLabelStatusArray = labelsToPrint.map((label: Label) => {
      return new LabelPrintStatusWrapper(label, LabelPrintStatus.Queued, []);
    });
    this.PrinterLabelStatus.next(printerLabelStatusArray);

    const getLabelTemplate = await this.getLabelTemplate(labelType);
    getLabelTemplate.subscribe(template => {
      this.printLabelsUsingTemplate(LabelType.Supply, template, labelsToPrint);
    });
  }

  private async getLabelTemplate(labelType: LabelType) {
    let labelTemplateLocation = '/lib/zebra-printer-templates/';
    if (labelType == LabelType.Supply) {
      labelTemplateLocation += 'supply-label.prn';
    } else if (labelType == LabelType.Equipment) {
      labelTemplateLocation += 'equipment-label.prn';
    } else if (labelType == LabelType.ManikinAndTrainer) {
      labelTemplateLocation += 'manikin-label.prn';
    } else {
      throw new Error('This is not a valid label type.');
    }
    return this.http.get(labelTemplateLocation, { responseType: 'text' });
  }

  private async printLabelsUsingTemplate(labelType: LabelType, templateString: string, labelsToPrint: Label[]) {
    try {
      // Check printer status
      const printerStatus = await this.ZebraService.checkPrinterStatus();

      // Check if the printer is ready
      if (printerStatus.isReadyToPrint) {
        // ZPL script to print a simple barcode
        labelsToPrint.forEach(async label => {
          const localPrinterStatusValue = this.PrinterLabelStatus.getValue();
          const printerStatusItem = localPrinterStatusValue.find((statusItem: LabelPrintStatusWrapper) => statusItem.Label == label);
          this.logger.log('FOUND STATUS ITEM', printerStatusItem);
          printerStatusItem.LabelPrintStatus = LabelPrintStatus.Printing;
          this.PrinterLabelStatus.next(localPrinterStatusValue);
          try {
            (
              await this.ZPLPreviewImageService.ConvertImageToZPLGraphic(
                label.qrCode,
                this.QRCodeImageSpecs.PositionX,
                this.QRCodeImageSpecs.PositionY,
                this.QRCodeImageSpecs.Resolution,
                this.QRCodeImageSpecs.Width,
                this.QRCodeImageSpecs.Height
              )
            ).subscribe((result: ConvertImageToZplCommandResult) => {
              const zplTemplate = this.replaceTemplateVariablesForLabel(templateString, label, result.zplCode);
              this.ZebraService.print(zplTemplate);
              printerStatusItem.LabelPrintStatus = LabelPrintStatus.Printed;
            });
          } catch (error) {
            this.logger.error(error);
            printerStatusItem.LabelPrintStatus = LabelPrintStatus.Failed;
            printerStatusItem.Errors.push(error.toString());
          }
          this.PrinterLabelStatus.next(localPrinterStatusValue);
        });
      } else {
        this.logger.error('Errors with Printer', printerStatus.errors);
        let toastMessage = 'There was an error with the printer. Please check your connection and try again.';
        if (printerStatus.errors.toLowerCase().indexOf('paused') > -1) {
          toastMessage = 'The printer is not ready and seems to be paused. Unpause the printer and try again.';
        }
        this.toastService.error('Error with Printer', toastMessage);
      }
    } catch (error) {
      this.logger.error(error);
      this.showPrinterConnectionError();
    }
  }

  private showPrinterConnectionError() {
    this.toastService.error(
      'Cannot Find Printer',
      'The application cannot connect to the printer. Please make sure the printer is turned on, is ready, and that the Browser Print service is running on this computer. Refresh this page to try again.'
    );
  }

  private convertLabelValuesToFlatVariableArray(label: Label): any[] {
    const variables = [];
    variables.push({
      key: this.VariablePrefix + 'DatePrinted',
      value: label.datePrinted
    });
    variables.push({
      key: this.VariablePrefix + 'QRCode',
      value: label.qrCode
    });
    for (let [key, value] of Object.entries(label.customProperties)) {
      /* Do something... */

      value = this.sanitizeLabelValue(key, value);

      variables.push({
        key: this.VariablePrefix + key,
        value: value
      });
    }
    return variables;
  }

  sanitizeLabelValue(key: string, value: string): string {
    switch (key) {
      case 'supplyName':
        return this.labelValueMaxLength(value, 60);
      case 'manufacturerName':
        return this.labelValueMaxLength(value, 40);
      case 'locationName':
        return this.labelValueMaxLength(value, 30);
      case 'buildingName':
        return this.labelValueMaxLength(value, 30);
      case 'roomName':
        return this.labelValueMaxLength(value, 30);
      case 'dimension1':
        return this.labelValueMaxLength(value, 5);
      case 'dimension2':
        return this.labelValueMaxLength(value, 5);
      case 'dimension3':
        return this.labelValueMaxLength(value, 5);
      default:
        return value;
    }
  }

  labelValueMaxLength(value: string, maxLength: number, suffix = '...'): string {
    value = value.substring(0, maxLength);
    if (value.length >= maxLength) {
      value += suffix;
    }
    return value;
  }

  private replaceTemplateVariablesForLabel(templateString: string, label: Label, qrCodeAsZPL: string): string {
    const variables = this.convertLabelValuesToFlatVariableArray(label);

    this.logger.log('variable values', variables);

    variables.forEach(variable => {
      if (variable.key.toLowerCase().indexOf('QRCode'.toLowerCase()) < 0) {
        var pattern = new RegExp(variable.key, 'gi');
        templateString = templateString.replace(pattern, variable.value);
      } else {
        var pattern = new RegExp(/^.*QRCodeString.*$/gm);
        templateString = templateString.replace(pattern, qrCodeAsZPL);
      }
    });

    const variablePrefix = new RegExp(/\^FDvariable_(.*)\^FS/, 'gi');
    const unresolvedVariables = (templateString || '').match(variablePrefix) || [];
    unresolvedVariables.forEach(match => {
      const localMatch = match.replace('^FD', '').replace('^FS', '');
      templateString = templateString.replace(match, '');
      this.logger.warn('The value for "' + localMatch + '" was not replaced. The label will not print correctly.');
    });
    return templateString;
  }
}

export enum LabelPrintStatus {
  Queued = 'Queued',
  Printing = 'Printing',
  Printed = 'Printed',
  Failed = 'Failed'
}
export class LabelPrintStatusWrapper {
  constructor(label: Label, labelPrintStatus: LabelPrintStatus, errors: string[]) {
    this.Label = label;
    this.LabelPrintStatus = labelPrintStatus;
    this.Errors = errors;
  }

  public Label: Label;
  public LabelPrintStatus: LabelPrintStatus;
  public Errors: string[];
}
