<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
  <div class="row">
    <div class="col-sm-12 col-md-6" [hidden]="editMode">
      <kendo-buttongroup>
        <button kendoButton
          type="submit"
          [disabled]="!form.valid"
          [primary]="true"
          [icon]="'save'">
          Create {{ this.GetSingleEntityName() }}
        </button>
        <button kendoButton
            type="button"
            [primary]="false"
            [icon]="'cancel-circle'"
            (click)="resetForm()">
            Cancel
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <kendo-buttongroup>
        <button kendoButton
          type="button"
          [primary]="true"
          [disabled]="!form.valid"
          [icon]="'save'"
          (click)="submitAndClose()">
          Save and Close
        </button>
        <button kendoButton
          type="button"
          [primary]="false"
          [icon]="'cancel-circle'"
          (click)="resetForm()">
          Cancel
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <div class="float-end">
        <div kendoDialogContainer></div>
      </div>
    </div>
    <div class="col-sm-12" [hidden]="true">
      <kendo-buttongroup>
        <button kendoButton
          type="button"
          [primary]="true"
          [icon]="'user'">
          View Users
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12">
      <hr />
    </div>
  </div>

  <app-validation-errors></app-validation-errors>
  <input type="hidden" id="id" formControlName="id" />

  <kendo-tabstrip>
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="row">
          <div class="col-sm-12">
            <label for="customId" class="col-form-label col-form-label-sm">
              Station
            </label>
            <input
              kendoTextBox
              formControlName="customId"
              id="customId"
              class="form-control"
              placeholder="Station"
              add-required-asterisk />
            <validation-error-message [control]="customId"></validation-error-message>
          </div>
          <div class="col-md-12">
            <label for="description" class="col-form-label col-form-label-sm">
              Room
            </label>
            <kendo-dropdownlist
              class="form-control"
              [data]="($rooms | async)?.data"
              textField="name"
              add-required-asterisk
              valueField="id"
              [valuePrimitive]="true"
              formControlName="roomId">
            </kendo-dropdownlist>
            <validation-error-message [control]="room"></validation-error-message>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
      <ng-template kendoTabContent>
        <app-metadata [metadata]="dataModel"></app-metadata>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
