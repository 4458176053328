import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CreateUserCommand, ReadUserCommandResult, UpdateUserCommand, UserService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent, IdentityToken } from '@wo-app/shared/models';
import { decodeToken } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readLocationCommandResult: ReadUserCommandResult = {};
  dataModel: ReadUserCommandResult;
  updateCommand: UpdateUserCommand = {};
  createCommand: CreateUserCommand = {};
  form: FormGroup;
  passwordForm: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  public IdToken: IdentityToken | null = null;

  public onFormGroupChangeEvent(passwordFormGroup: FormGroup) {
    this.passwordForm = passwordFormGroup;
    this.form.addControl('password', passwordFormGroup);
  }

  constructor(
    private authService: NbAuthService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.MYPROFILE, injector);
    this.editMode = true;
    this.createForm();
  }

  ngOnInit() {
    this.authService.getToken().subscribe((authToken: NbAuthToken) => {
      const decoded_token = decodeToken(authToken.getPayload().id_token);
      this.IdToken = new IdentityToken('', '', decoded_token['email'], decoded_token['cognito:username'], decoded_token['phone_number']);

      if (this.editMode) {
        this.getData();
      } else {
        this.showForm = true;
      }
    });
    this.showForm = true;
    this.fromKey = 'FormModel';
  }

  convertServerDataToFormModel(result: ReadUserCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    this.form.setControl('password', this.passwordForm);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.userService
      .read(this.IdToken.username)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadUserCommandResult) => {
        this.UpdateBreadcrumbPageTitle(1, result.username);
        this.logger.debug(result);
        this.convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: ['', []],
      username: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailAddress: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      password: [],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit() {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateCommand = this.form.value;
      this.updateCommand.password = this.passwordForm.controls['password'].value;
      this.logger.debug(this.updateCommand);

      this.userService
        .update(this.updateCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          this.logger.debug(result);
          this.convertServerDataToFormModel(result);

          const passwordFormGroup = this.form.controls['password'] as FormGroup;
          passwordFormGroup.controls['password'].setValue('');
          passwordFormGroup.controls['passwordConfirmation'].setValue('');

          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this location?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
