<kendo-dialog width="300" [title]="title" (close)="close('')">
  <!-- <div class="col"> -->
    <!-- <h6 class="text-start">Supplies</h6> -->
  <!-- </div> -->
  <kendo-grid
    id="inventory-item-fill-grid"
    [data]="view"
    [style.width.px]="1000"
    class="stock-information"
    [loading]="isLoading"
    [pageSize]="pageSize"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    [navigable]="true"
    [rowClass]="rowCallback"
    >
    <kendo-grid-column field="id" title="Id" [hidden]="true"></kendo-grid-column>
    <kendo-grid-column field="name" title="Item Name">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.name }}
        <span class="badge rounded-pill bg-primary">{{ dataItem.type.toUpperCase() }}</span>
        <span *ngIf="dataItem.status === 'CheckedOut'" class="ms-2 badge rounded-pill bg-success">{{ dataItem.status }}</span>
     </ng-template>
    </kendo-grid-column>
    <!-- <div *kendoGridDetailTemplate="let dataItem">
      <app-pick-order-item-fill-room-selection [inventoryItem]="dataItem" [rooms]="rooms"></app-pick-order-item-fill-room-selection>
    </div> -->



    <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showOnlyNonCheckedOutItems"
      >
      <app-pick-order-item-fill-room-selection [inventoryItem]="dataItem" [rooms]="rooms"></app-pick-order-item-fill-room-selection>
    </ng-template>




  </kendo-grid>
<kendo-dialog-actions layout="end">
  <ng-template ngFor [ngForOf]="buttons" let-btn>
      <button kendoButton [primary]="true" (click)="close(btn)">{{ btn }}</button>
  </ng-template>
</kendo-dialog-actions>
</kendo-dialog>
