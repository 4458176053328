import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import {
  HasOneLowerCaseLetterRegex,
  HasOneNumberRegex,
  HasOneSpecialCharacterRegex,
  HasOneUpperCaseLetterRegex,
  PasswordCharacterCountRangeRegex
} from '../../utils/custom-validators';

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const passwordConfirmation = control.get('passwordConfirmation');

  const results = {
    characterCountWithinRange: false,
    hasOneNumber: false,
    hasOneUppercaseLetter: false,
    hasOneLowercaseLetter: false,
    hasOneSpecialCharacter: false,
    passwordIsValid: false,
    passwordConfirmationIsValid: false
  };

  if (!password.value && !passwordConfirmation.value) {
    return null;
  }

  results.characterCountWithinRange = PasswordCharacterCountRangeRegex.test(password.value);
  results.hasOneNumber = HasOneNumberRegex.test(password.value);
  results.hasOneUppercaseLetter = HasOneUpperCaseLetterRegex.test(password.value);
  results.hasOneLowercaseLetter = HasOneLowerCaseLetterRegex.test(password.value);
  results.hasOneSpecialCharacter = HasOneSpecialCharacterRegex.test(password.value);
  results.passwordIsValid =
    results.characterCountWithinRange &&
    results.hasOneNumber &&
    results.hasOneUppercaseLetter &&
    results.hasOneLowercaseLetter &&
    results.hasOneSpecialCharacter;

  if (password?.value === passwordConfirmation?.value) {
    results.passwordConfirmationIsValid = password.value.length >= 8 && password.value == passwordConfirmation.value;
  }
  if (results.passwordConfirmationIsValid && results.passwordIsValid) {
    return null;
  } else {
    return results;
  }
};
@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss']
})
export class PasswordConfirmationComponent implements OnInit {
  form: FormGroup;
  originalFormState: FormGroup;

  @Output() onFormGroupChange = new EventEmitter<FormGroup>();

  constructor(private logger: NGXLogger, private fb: FormBuilder) {
    this.form = this.fb.group(
      {
        password: ['', []],
        passwordConfirmation: ['', []]
      },
      {
        updateOn: 'blur',
        validators: passwordValidator
      }
    );
  }

  public ngOnInit() {
    this.onFormGroupChange.emit(this.form);
  }

  shouldShowCheckMark(validationErrors: ValidationErrors | null, errorName: string): boolean {
    let result = false;
    const passwordLength = this.form.controls['password'].value.length ?? 0;

    if (passwordLength > 0 && validationErrors == null) return true;

    if (validationErrors == null) return result;

    try {
      result = validationErrors[errorName];
      return result;
    } catch {
      return result;
    }
  }

  shouldShowXMark(validationErrors: ValidationErrors | null, errorName: string): boolean {
    if (validationErrors == null) return false;
    let result = false;
    try {
      result = validationErrors?.[errorName];
      return result == false;
    } catch {
      return result == false;
    }
  }
}
