import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BedService,
  BuildingService,
  ListBedsCommand,
  ListBedsCommandViewModel,
  ListBuildingsCommand,
  ListBuildingsCommandViewModel,
  ListLocationsCommand,
  ListManikinMakeAndModelsCommand,
  ListManikinMakeAndModelsCommandViewModel,
  ListRoomsCommand,
  LocationService,
  LocationViewModel,
  ManikinInventoryItemService,
  ManikinMakeAndModelService,
  ManikinOrTrainerBaseType,
  ReadManikinInventoryItemCommandResult,
  RoomService,
  RoomViewModel
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, switchMap, take } from 'rxjs';

@Component({
  selector: 'manikin-qr-landing',
  templateUrl: './manikin-qr-landing.component.html'
})
export class ManikinQrLandingComponent extends EntityBaseComponent implements OnInit {
  public buildings: ListBuildingsCommandViewModel[];
  public filteredBuildings: ListBuildingsCommandViewModel[];
  public filteredRooms: RoomViewModel[];
  public filteredStations: ListBedsCommandViewModel[];
  public isDialogVisible = false;
  public locations: LocationViewModel[];
  public manikinLineItem: ReadManikinInventoryItemCommandResult;
  public manikinMakeAndModels: ListManikinMakeAndModelsCommandViewModel[];
  public rooms: RoomViewModel[];
  public selectedBuilding?: ListBuildingsCommandViewModel;
  public selectedLocation?: LocationViewModel;
  public selectedRoom?: RoomViewModel;
  public selectedStation?: ListBedsCommandViewModel;
  public stations: ListBedsCommandViewModel[];

  constructor(
    private _breadcrumbaService: BreadcrumbsService,
    private bedService: BedService,
    private buildingService: BuildingService,
    impersonationService: ImpersonationService,
    private locationService: LocationService,
    private logger: NGXLogger,
    private manikinInventoryItemService: ManikinInventoryItemService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    injector: Injector
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, injector);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    const locationCommand: ListLocationsCommand = {
      skip: 0,
      take: 1000
    };
    const locationsObservable = this.locationService.list(locationCommand);

    const buildingsListCommand: ListBuildingsCommand = {
      skip: 0,
      take: 1000
    };
    const buildingsObservable = this.buildingService.list(buildingsListCommand);

    const roomsListCommand: ListRoomsCommand = {
      skip: 0,
      take: 1000
    };
    const roomsObservable = this.roomService.list(roomsListCommand);

    const listBedsCommand: ListBedsCommand = {
      skip: 0,
      take: 1000
    };
    const stationObservable = this.bedService.list(listBedsCommand);

    const listManikinMakeAndModelsCommand: ListManikinMakeAndModelsCommand = {
      skip: 0,
      take: 1000
    };
    const manikinMakeAndModelObservable = this.manikinMakeAndModelService.list(listManikinMakeAndModelsCommand);

    const readManikinObservable = this.route.params.pipe(
      switchMap(params => {
        return this.manikinInventoryItemService.read(+params['manikinLineItemId']);
      }),
      take(1)
    );

    forkJoin([
      locationsObservable,
      buildingsObservable,
      manikinMakeAndModelObservable,
      roomsObservable,
      stationObservable,
      readManikinObservable
    ]).subscribe(([locations, buildings, manikinMakeAndModels, rooms, stations, manikinLineItem]) => {
      this.locations = locations.data;
      this.buildings = buildings.data;
      this.manikinMakeAndModels = manikinMakeAndModels.data;
      this.rooms = rooms.data;
      this.stations = stations.data;
      this.manikinLineItem = manikinLineItem;

      this.refreshDropdowns();
    });
  }

  private refreshDropdowns(): void {
    this.selectedLocation = this.locations.find(l => l.id === this.manikinLineItem.currentLocationLocationId);
    this.selectedBuilding = this.buildings.find(b => b.id === this.manikinLineItem.currentLocationBuildingId);
    this.selectedRoom = this.rooms.find(r => r.id === this.manikinLineItem.currentLocationRoomId);
    this.selectedStation = this.stations.find(s => s.id === this.manikinLineItem.currentLocationBedId);

    this.filteredBuildings = this.buildings.filter(b => b.locationId === this.selectedLocation.id);
    this.filteredRooms = this.rooms.filter(r => r.buildingId === this.selectedBuilding.id);
    this.filteredStations = this.prependNoneStationOption(this.stations.filter(s => s.roomId === this.selectedRoom.id));
  }

  public getModelName(): string {
    return this.manikinMakeAndModels?.find(m => m.id === this.manikinLineItem.manikinMakeAndModelId)?.modelName;
  }

  public getBaseType(): string {
    return this.manikinMakeAndModels?.find(m => m.id === this.manikinLineItem.manikinMakeAndModelId)?.manikinOrTrainerBaseType;
  }

  public getSubType(): string {
    if (this.getBaseType() === ManikinOrTrainerBaseType.Manikin) {
      return this.manikinMakeAndModels?.find(m => m.id === this.manikinLineItem.manikinMakeAndModelId)?.manikinType;
    }
    return this.manikinMakeAndModels?.find(m => m.id === this.manikinLineItem.manikinMakeAndModelId)?.trainerType;
  }

  public getLocationName(): string {
    return this.locations?.find(l => l.id === this.manikinLineItem.currentLocationLocationId)?.name;
  }

  public getBuildingName(): string {
    return this.buildings?.find(b => b.id === this.manikinLineItem.currentLocationBuildingId)?.name;
  }

  public getRoomName(): string {
    return this.rooms?.find(r => r.id === this.manikinLineItem.currentLocationRoomId)?.name;
  }

  public getStationName(): string {
    return this.stations?.find(s => s.id === this.manikinLineItem.currentLocationBedId)?.customId || 'Unknown';
  }

  public onSelectLocation(event: any) {
    if (this.selectedLocation?.id !== event.id) {
      this.selectedLocation = event;
      this.selectedBuilding = undefined;
      this.selectedRoom = undefined;
      this.selectedStation = undefined;
      this.filteredBuildings = this.buildings.filter(b => b.locationId === event.id);
    }
  }

  public onSelectBuilding(event: any) {
    if (this.selectedBuilding?.id !== event.id) {
      this.selectedBuilding = event;
      this.selectedRoom = undefined;
      this.selectedStation = undefined;
      this.filteredRooms = this.rooms.filter(r => r.buildingId === event.id);
    }
  }

  public onSelectRoom(event: any) {
    if (this.selectedRoom?.id !== event.id) {
      this.selectedRoom = event;
      this.selectedStation = undefined;
      this.filteredStations = this.prependNoneStationOption(this.stations.filter(s => s.roomId === event.id));
    }
  }

  public onSelectStation(event: any) {
    this.selectedStation = event;
  }

  private prependNoneStationOption(stations: ListBedsCommandViewModel[]): ListBedsCommandViewModel[] {
    stations.unshift({
      customId: 'None',
      value: null
    } as ListBedsCommandViewModel);
    return stations;
  }

  public toggleDialog(): void {
    this.isDialogVisible = !this.isDialogVisible;
  }

  public isRequiredFieldsDefined(): boolean {
    return !!this.selectedLocation && !!this.selectedBuilding && !!this.selectedRoom;
  }

  public onConfirm(): void {
    this.manikinInventoryItemService
      .update(this.manikinLineItem.id.toString(), {
        ...this.manikinLineItem,
        currentLocationLocationId: this.selectedLocation.id,
        currentLocationBuildingId: this.selectedBuilding.id,
        currentLocationRoomId: this.selectedRoom.id,
        currentLocationBedId: this.selectedStation?.id
      })
      .subscribe(_result => {
        this.fetchData();
        this.toggleDialog();
      });
  }
}
