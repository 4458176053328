<autopilot-grid>
  <kendo-grid
    data]="view"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    (dataStateChange)="dataStateChange($event)">
      <ng-template kendoGridToolbarTemplate>
          <div class="float-start">
              <button kendoButton class="btn btn-primary" type="button" (click)="routeToAddWithOrganization()" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}}</button>
          </div>
          <div class="float-end">
              <button kendoGridPDFCommand class="btn btn-secondary text-white me-1" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
              <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
          </div>
      </ng-template>
      <kendo-grid-column field="name" title="Name">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.name}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="created" title="Date Created">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.created | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastUpdated" title="Date Last Updated">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.lastUpdated | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Actions" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton [primary]="true" [icon]="'calendar'" (click)="routeToEditWithOrganization(organizationId)">Edit</button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
