import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { ListScenariosCommand, ScenarioService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];

  listScenariosCommand: ListScenariosCommand = {};
  public view: GridDataResult = { data: [], total: 0 };

  constructor(
    private loadingBar: LoadingBarService,
    impersonationService: ImpersonationService,
    private scenarioService: ScenarioService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router,
    injector: Injector
  ) {
    super(EntityGlobals.SCENARIOS, injector);
    this.loadingBar.useRef('router').start();
    this.listScenariosCommand.skip = this.state.skip;
    this.listScenariosCommand.take = this.state.take;
    this.listScenariosCommand.sort = this.state.sort;
    this.scenarioService.list(this.listScenariosCommand).subscribe((result: any) => {
      this.loadingBar.useRef('router').complete();
      this.gridData = result.data;
      this.query();
    });
  }

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }
}
