import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {
  transform(email: string): string {
    if (email == null || email.toString().length == 0) return '';
    const value = email.toString().trim().replace(/^\+/, '');
    return `<a href="mailto:${value}"><i class="fa-light fa-envelope"></i> ${value}</a>`;
  }
}
