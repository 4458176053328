import { Component, Input, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RowArgs, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid/data/change-event-args.interface';
import { GridDataResult } from '@progress/kendo-angular-grid/data/data.collection';
import { CompositeFilterDescriptor, State, orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { ListSupplyViewModel, ListSupplysCommand, ListSupplysCommandResult, PickOrderSupplyViewModel, SupplyService } from '@wo-api/index';
import { ModalService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pick-order-supplies-selection-dialog',
  templateUrl: './pick-order-supplies-selection-dialog.component.html',
  styleUrls: ['./pick-order-supplies-selection-dialog.component.scss']
})
export class PickOrderSuppliesSelectionDialogComponent implements OnInit {
  @Input() public inputs: any;
  @Input() public outputs: any;

  public title: string;
  public buttons: string[] = [];
  public gridData: GridDataResult = { data: [], total: 0 };
  public isLoading = false;

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'supplyName' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private preSelectedItems: PickOrderSupplyViewModel[] = [];
  public selectableSettings: SelectableSettings;
  public selectedIds: number[] = [];

  constructor(
    private modalService: ModalService,
    private supplyService: SupplyService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger
  ) {
    this.setSelectableSettings();
  }

  private setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
      drag: false
    };
  }

  ngOnInit(): void {
    this.preSelectedItems = this.inputs.selectedItems ? this.inputs.selectedItems : [];
    this.selectedIds = this.preSelectedItems.map(({ id }) => id);
    this.title = this.inputs.title ? this.inputs.title : 'Select Items';
    this.defineButtons();
    this.reloadData();
  }

  private defineButtons() {
    if (this.inputs.buttons) {
      this.buttons = this.inputs.buttons;
    } else {
      this.buttons.push('Close');
    }
  }

  public reloadData(): void {
    this.isLoading = true;
    this.loadingBar.useRef('router').start();

    const listSupplysCommand: ListSupplysCommand = {
      take: this.state.take,
      skip: this.state.skip
    };
    this.supplyService.list(listSupplysCommand).subscribe((value: ListSupplysCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.logger.debug('Got Data from List(ListSupplysCommand)', value);
      this.isLoading = false;
      this.gridData.data = value.data;
      this.gridData.total = value.totalRowRowCount;
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.reloadData();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public processRowSelection(event: SelectionEvent): void {
    if (event.selectedRows.length > 0) {
      this.addSelectedRows(event.selectedRows);
    }
    if (event.deselectedRows.length > 0) {
      this.removeSelectedRows(event.deselectedRows);
    }
  }

  private addSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      this.selectedIds = this.selectedIds.filter(f => f !== item.dataItem.id).concat([item.dataItem.id]);
    });
    
    // Final cleanup to ensure no null/undefined values exist
    //this.selectedIds = this.selectedIds.filter(id => id != null);
  }

  private removeSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      const index = this.selectedIds.indexOf(item.dataItem.Id, 0);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });
    
    // Final cleanup to ensure no null/undefined values exist
    //this.selectedIds = this.selectedIds.filter(id => id != null);
  }

  public close(answer: any) {
    const result = {
      items: [],
      answer
    };
    if (answer === 'Confirm') {
      const selectedSupplies: PickOrderSupplyViewModel[] = [];
      this.selectedIds.forEach(e => {
        const supply = this.gridData.data.filter(item => item.id == e)[0];
        if (supply != null && supply != undefined) {
          const pickOrderSupply: PickOrderSupplyViewModel = {
            id: supply.id,
            supplyName: supply.name,
            supplyManufacturerNumber: supply.manufacturerNumber
          };
          selectedSupplies.push(pickOrderSupply);
        }
      });
      result.items = selectedSupplies;
    }
    this.modalService.destroy(result);
  }
}
