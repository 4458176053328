import { Location } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { AppGlobals } from '@wo-app/app.global';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  logoUrl: string;
  constructor(private _location: Location) {
    this.logoUrl = AppGlobals.LOGO_URL;
  }

  ngOnInit() {}

  backClicked() {
    this._location.back();
  }
}
