import { ElementRef, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class HamburgerService {
  hamburgerElement: ElementRef | null  = null;
  activeClass = "is-active";

  constructor(
    private logger:NGXLogger
  ) { }

  setHamburgerElement(el:ElementRef){
    this.hamburgerElement = el;
  }

  setInactive(){
    if(this.hamburgerElement != null){
      this.hamburgerElement.nativeElement.classList.remove(this.activeClass);
    }
  }

  setActive(){
    if(this.hamburgerElement != null){
      this.hamburgerElement.nativeElement.classList.add(this.activeClass);
    }
  }
}
