import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  DataStateChangeEvent,
  FilterService,
  GridDataResult,
  MultipleSortSettings,
  PageChangeEvent,
  SelectAllCheckboxState,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  EquipmentStatus,
  Gender,
  ListBedsCommandResult,
  ListBuildingsCommandResult,
  ListLocationsCommandResult,
  ListManikinInventoryItemsCommand,
  ListManikinInventoryItemsCommandResult,
  ListManikinMakeAndModelsCommandViewModel,
  ListRoomsCommandResult,
  LocationService,
  ManikinInventoryItemService,
  ManikinInventoryItemViewModel,
  ManikinMakeAndModelService,
  ManikinOrTrainerBaseType,
  RoomService,
  SkinTone
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import ArrayUtilities from '@wo-app/shared/utils/array-utils';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-manikin-inventory-item-list',
  templateUrl: './manikin-inventory-item-list.component.html',
  styleUrls: ['./manikin-inventory-item-list.component.scss']
})
export class ManikinInventoryItemListComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private manikinInventoryItemService: ManikinInventoryItemService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService,
    private _breadCrumbService: BreadcrumbsService,
    private _titleService: Title,
    private qrLabelService: QrLabelService,
    injector: Injector
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, injector);
  }

  public gridData: GridDataResult = { data: [], total: 0 };
  public manikinMakeAndModelId = 0;
  public modelInfo: ListManikinMakeAndModelsCommandViewModel;
  public ArrayUtilities = ArrayUtilities;
  public locations: ListLocationsCommandResult;
  public buildings: ListBuildingsCommandResult;
  public rooms: ListRoomsCommandResult;
  public beds: ListBedsCommandResult;
  public qrLink: string;
  public isQrModalVisible = false;
  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public equipmentStatuses = Object.values(EquipmentStatus);
  public skinTones = Object.values(SkinTone);
  public genders = Object.values(Gender);
  public enumFilterPlaceholder = 'Select one or more filter values';

  reloadData() {
    this.loadingBar.useRef('router').start();

    const listManikinInventoryItemsCommand: ListManikinInventoryItemsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort,
      manikinMakeAndModelId: this.id
    };

    this.manikinInventoryItemService.list(listManikinInventoryItemsCommand).subscribe((result: ListManikinInventoryItemsCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.gridData.data = result.data;
      this.gridData.total = result.totalRowRowCount;
    });
  }

  ngOnInit() {
    this.manikinMakeAndModelId = this.route.snapshot.params['manikinMakeAndModelId'];
    this.reloadData();
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.manikinInventoryItemService._delete(result.id.toString()).subscribe((newResult: any) => {
          this.reloadData();
        });
      }
    });
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public enumFilterChange(values: string[], filterService: FilterService, fieldName: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: fieldName,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  public onQrClick(manikinLineItemId: number): void {
    this.qrLink = `${location.origin}/${EntityGlobals.MANIKIN_MAKES_AND_MODELS.baseRoute}/line-item/${manikinLineItemId}/qr`;
    this.isQrModalVisible = true;
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.id)) {
        this.selectedIds.push(row.dataItem.id);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.data.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.data.map(item => item.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.ManikinAndTrainer;
    const selectedItems = this.gridData.data.filter(gridRow => this.selectedIds.includes(gridRow.id));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.MANIKIN_MAKES_AND_MODELS.baseRoute}/line-item/${item.id}/qr`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
