import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  GetLocationQuantityListForRoomCommand,
  GetLocationQuantityListForRoomCommandResult,
  ListBuildingsCommandViewModel,
  ReadRoomCommandResult,
  RoomService,
  RoomSupplyReportViewModel
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import ArrayUtilities from '@wo-app/shared/utils/array-utils';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-room-inventory-list',
  templateUrl: './room-inventory-list.component.html',
  styleUrls: ['./room-inventory-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoomInventoryListComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private roomService: RoomService,
    _breadcrumbService: BreadcrumbsService,
    _titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.ROOMS, injector);
    this.editMode = false;
  }

  dataModel: ReadRoomCommandResult;
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  buildingsForOrganization: ListBuildingsCommandViewModel[];
  buildingId: number;
  public GetLocationQuantityListForRoomCommand: Observable<GetLocationQuantityListForRoomCommandResult>;

  gridData: GridDataResult = { data: [], total: 0 };
  ArrayUtilities = ArrayUtilities;

  ngOnInit(): void {
    this.state.sort = [{ dir: 'asc', field: 'supplyName' }];
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();

    const cmd: GetLocationQuantityListForRoomCommand = {
      //filter: this.filter,
      id: this.id,
      skip: this.state.skip,
      take: this.state.take,
      sort: this.state.sort
    };

    this.roomService.getLocationQuantityListForRoom(cmd).subscribe((result: GetLocationQuantityListForRoomCommandResult) => {
      this.logger.debug('Got Data from GetLocationQuantityListForRoomCommandResult', result);
      this.loadingBar.useRef('router').complete();
      this.gridData.data = result.data;
      this.gridData.total = result.totalRowRowCount;
      this.UpdateBreadcrumbPageTitle(
        result.data[0].roomId,
        'View Inventory for ' + result.data[0].roomName + ` (${result.data[0].roomId})`
      );
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.reloadData();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public getQuantityWrapperTitle(dataItem: RoomSupplyReportViewModel): string {
    let returnMessage = '';
    if (dataItem.quantity <= dataItem.stockingQuantity) {
      returnMessage = 'This items quantity in this room is below the stocking quantity.';
    } else if (dataItem.quantity <= dataItem.stockingQuantity / 2) {
      returnMessage = 'This items quantity in this room is approaching the stocking quantity.';
    }
    return `${returnMessage}`;
  }
}
