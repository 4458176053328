import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ListLocationsCommand, ListLocationsCommandResult, LocationService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent extends EntityBaseComponent implements OnInit, OnDestroy {
  constructor(
    injector: Injector,
    private dialogService: DialogService,
    private logger: NGXLogger,
    private locationService: LocationService,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService
  ) {
    super(EntityGlobals.LOCATIONS, injector);
  }

  gridData: GridDataResult = { data: [], total: 0 };

  listBuildingsCommand: ListLocationsCommand = {};
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'name' }];

  ngOnInit() {
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    const cmd: ListLocationsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort
    };
    this.locationService
      .list(cmd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListLocationsCommandResult) => {
        this.loadingBar.useRef('router').complete();
        if (result && result.data) {
          this.gridData.data = result.data.map(item => ({
            ...item,
            createdDate: new Date(item.created),
            lastUpdatedDate: new Date(item.lastUpdated)
          }));
          this.gridData.total = result.totalRowRowCount;
        }
      });
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.locationService._delete(result.id.toString()).subscribe((result: any) => {
          this.logger.debug(result);
          this.reloadData();
        });
      }
    });
  }
}
