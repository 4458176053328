import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelEvent, EditEvent, GridComponent, GridDataResult, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import {
  BeginImportJobCommand,
  BeginImportJobCommandResult,
  BuildingService,
  ImportJobLineItemEditorModel,
  ImportJobLineItemStatus,
  ImportJobLineItemViewModel,
  ImportJobService,
  ListBuildingsCommand,
  ListBuildingsCommandResult,
  ListBuildingsCommandViewModel,
  ReadImportJobCommandResult,
  UpdateImportJobLineItemCommand,
  UpdateImportJobLineItemCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-view-import-job',
  templateUrl: './view-import-job.component.html',
  styleUrls: ['./view-import-job.component.scss']
})
export class ViewImportJobComponent extends EntityBaseComponent implements OnInit {
  private editedRowIndex: number;

  public readyForImport = false;
  public editableJobLineItems: any;
  public formGroup: FormGroup;
  public readImportJobCommandResult: Observable<ReadImportJobCommandResult>;
  public $buildings: Observable<ListBuildingsCommandResult>;
  public buildings: ListBuildingsCommandViewModel[];
  public columnsToCreate: Observable<any[]>;
  public gridData: Observable<GridDataResult>;
  public gridState: State = { sort: [], skip: 0, take: 5 };

  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private importJobService: ImportJobService,
    public cachedDataService: CachedDataService,
    public buildingService: BuildingService,
    private route: ActivatedRoute,
    private router: Router,
    injector: Injector
  ) {
    super(EntityGlobals.IMPORT_JOBS, injector);
    const query: ListBuildingsCommand = {
      skip: 0,
      take: 1000,
      sort: []
    };
    this.$buildings = this.buildingService.list(query);
  }

  public ngOnInit(): void {
    this.$buildings.subscribe(b => (this.buildings = b.data));
    this.refreshDataOnPage();
  }

  public hasValidationErrors(propertyName: string, errors: any[]): boolean {
    return this.getValidationErrorsForProperty(propertyName, errors)?.length !== 0;
  }

  public getPopoverContent(columnName: string, errors: any[]): string {
    const validationErrors = this.getValidationErrorsForProperty(columnName, errors);
    const content = validationErrors.map((lineItem: any) => `${lineItem.errorMessage}`);
    return `${content}`;
  }

  public beginImport() {
    this.logger.debug('Beginning import...');
    const cmd: BeginImportJobCommand = {
      importJobId: this.id
    };
    this.importJobService.begin(cmd).subscribe((result: BeginImportJobCommandResult) => {
      this.logger.debug('Import Job has started successfully.', result);
      this.refreshDataOnPage();
    });
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.importJobService.read(this.id);
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.closeEditor(args.sender);

    const objectKeys = (Object.keys(dataItem) as string[]).filter((key: string) => {
      return key.indexOf('editable_') > -1;
    });

    this.formGroup = new FormGroup({ id: new FormControl(dataItem.id, Validators.required) });

    objectKeys.forEach((key: string) => {
      this.formGroup.addControl(key, new FormControl(dataItem[key]));
    });

    this.editedRowIndex = args.rowIndex;

    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    this.readImportJobCommandResult.subscribe((value: ReadImportJobCommandResult) => {
      this.logger.debug(sender, rowIndex, formGroup);

      const formData = formGroup.value;
      const editableObjectKeys = (Object.keys(formData) as string[]).filter((key: string) => {
        return key.indexOf('editable_') > -1;
      });

      editableObjectKeys.forEach((key: string) => {
        const newKeyName = key.replace('editable_', '');
        formData[newKeyName] = formData[key];
      });

      const request: UpdateImportJobLineItemCommand = {
        id: formData.id,
        importJobType: value.importJobType,
        jsonData: JSON.stringify(formGroup.value)
      };

      this.logger.debug('Update Request', request);

      this.importJobService.updateImportJobLineItem(request).subscribe((result: UpdateImportJobLineItemCommandResult) => {
        this.refreshDataOnPage();
      });
      sender.closeRow(rowIndex);
    });
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source,
    // `editService` in this example
    //this.editService.remove(args.dataItem);
  }

  public getBuildingName(id: number) {
    return this.buildings.find(b => b.id === id)?.name;
  }

  private refreshDataOnPage() {
    this.readImportJobCommandResult = this.importJobService.read(this.id);

    this.gridData = this.readImportJobCommandResult.pipe(
      map(data => {
        const jobLineItems = data.jobLineItems
          .map((li: ImportJobLineItemViewModel) => {
            const mainItem = li as any;
            li.editableLineItems.forEach((eli: ImportJobLineItemEditorModel) => {
              mainItem['editable_' + eli.name.toLowerCase()] = eli.value;
            });
            return li;
          })
          .filter((li: ImportJobLineItemViewModel) => {
            return li.importJobLineItemStatus == ImportJobLineItemStatus.Failed;
          }) as any[];
        this.readyForImport = jobLineItems.length == 0;
        return process(jobLineItems, this.gridState);
      })
    );

    this.columnsToCreate = this.readImportJobCommandResult.pipe(
      map((value: ReadImportJobCommandResult) => {
        const returnValue = value.jobLineItems[0].editableLineItems.map((item: ImportJobLineItemEditorModel) => {
          return {
            columnField: 'editable_' + item.name.toLowerCase(),
            columnTitle: item.displayName ?? item.name
          };
        });
        return returnValue;
      })
    );
  }

  private getValidationErrorsForProperty(propertyName: string, errors: any[]): any[] {
    propertyName = propertyName.replace('editable_', '');
    errors = errors.filter((item: any) => {
      return item.propertyName.toLowerCase() == propertyName.toLowerCase();
    });
    return errors;
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
