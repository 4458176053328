import { Component, Input, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
  RowArgs,
  SelectableSettings,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import {
  ListManikinMakeAndModelsCommand,
  ListManikinMakeAndModelsCommandResult,
  ManikinMakeAndModelService,
  PickOrderManikinViewModel
} from '@wo-api/index';
import { ModalService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pick-order-manikins-selection-dialog',
  templateUrl: './pick-order-manikins-selection-dialog.component.html',
  styleUrls: ['./pick-order-manikins-selection-dialog.component.scss']
})
export class PickOrderManikinsSelectionDialogComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private manikinService: ManikinMakeAndModelService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger
  ) {
    this.setSelectableSettings();
  }

  @Input() public inputs: any;
  @Input() public error: any;
  @Input() public outputs: any;

  public title: string;
  public buttons: string[] = [];

  public gridData: GridDataResult = { data: [], total: 0 };
  public isLoading = false;

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'name' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private preSelectedItems: PickOrderManikinViewModel[] = [];
  public selectableSettings: SelectableSettings;
  public selectedIds: number[] = [];

  private setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
      drag: false
    };
  }

  ngOnInit(): void {
    this.preSelectedItems = this.inputs.selectedItems ? this.inputs.selectedItems : [];
    this.selectedIds = this.preSelectedItems.map(({ id }) => id);
    this.title = this.inputs.title ? this.inputs.title : 'Select Items';
    this.defineButtons();
    this.reloadData();
  }

  private defineButtons() {
    if (this.inputs.buttons) {
      this.buttons = this.inputs.buttons;
    } else {
      this.buttons.push('Close');
    }
  }

  public reloadData(): void {
    this.isLoading = true;
    this.loadingBar.useRef('router').start();

    const listManikinMakeAndModelsCommand: ListManikinMakeAndModelsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.state.sort
      //filter: this.filter
    };
    this.manikinService.list(listManikinMakeAndModelsCommand).subscribe((value: ListManikinMakeAndModelsCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.isLoading = false;
      this.logger.debug('Got Data from List(ListManikinMakeAndModelsCommand)', value);
      this.gridData.data = value.data;
      this.gridData.total = value.totalRowRowCount;
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.reloadData();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public processRowSelection(event: SelectionEvent): void {
    if (event.selectedRows.length > 0) {
      this.addSelectedRows(event.selectedRows);
    }
    if (event.deselectedRows.length > 0) {
      this.removeSelectedRows(event.deselectedRows);
    }
  }

  private addSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      this.selectedIds = this.selectedIds.filter(f => f !== item.dataItem.id).concat([item.dataItem.id]);
    });
  }

  private removeSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      const index = this.selectedIds.indexOf(item.dataItem.Id, 0);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });
  }

  public close(answer: any) {
    const result = {
      items: [],
      answer
    };
    if (answer === 'Confirm') {
      const selectedManikins: PickOrderManikinViewModel[] = [];
      this.selectedIds.forEach(e => {
        const manikin = this.gridData.data.filter(item => item.id == e)[0];
        if (manikin != null && manikin != undefined) {
          const pickOrderManikins: PickOrderManikinViewModel = {
            id: manikin.id,
            manikinModelName: manikin.modelName,
            manikinModelNumber: manikin.modelNumber,
            manikinType: manikin.manikinType,
            manikinOrTrainerBaseType: manikin.manikinOrTrainerBaseType
          };
          selectedManikins.push(pickOrderManikins);
        }
      });
      result.items = selectedManikins;
    }
    this.modalService.destroy(result);
  }
}
