import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Keys } from '@progress/kendo-angular-common';
import { CellClickEvent, CellCloseEvent, EditEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { PickOrderSupplyViewModel } from '@wo-api/index';
import { PickOrderStatus } from '../../../../../lib/wo-api/model/pickOrderStatus';
import { PickOrderInventoryItemStatus } from '../../../../../lib/wo-api/model/pickOrderInventoryItemStatus';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pick-order-supplies-grid',
  templateUrl: './pick-order-supplies-grid.component.html',
  styleUrls: ['./pick-order-supplies-grid.component.scss']
})
export class PickOrderSuppliesGridComponent implements OnChanges {
  @Input() public gridDataInput: PickOrderSupplyViewModel[] = [];
  public isInProgress = false;
  public isStarted = false;
  @Input() public pickOrderStatus: string | undefined;
  @Output() gridDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() inventoryItemReservationEmitter: EventEmitter<PickOrderSupplyViewModel[]> = new EventEmitter<PickOrderSupplyViewModel[]>();
  public gridData: PickOrderSupplyViewModel[] = [];
  public formGroups: FormGroup = new FormGroup({ items: new FormArray([]) });

  constructor(
    private logger: NGXLogger,
    private formBuilder: FormBuilder
  ) {}

  ngOnChanges() {
    this.gridData = this.gridDataInput;
    this.isInProgress = this.pickOrderStatus === PickOrderStatus.InProgress ? true : false;
    this.isStarted = this.pickOrderStatus === PickOrderStatus.Started ? true : false;
  }

  // public getFormControl(dataItem: PickOrderSupplyViewModel, field: string): FormControl {
  //   return <FormControl>(this.formGroups.get('items') as FormArray).controls.find(i => i.value.quantityRequested === dataItem.quantityRequested).get(field);
  // }

  public cellClickHandler(args: CellClickEvent): void {
    if (
      this.pickOrderStatus === undefined ||
      this.pickOrderStatus === PickOrderStatus.Started ||
      this.pickOrderStatus === PickOrderStatus.InProgress
    ) {
      if (
        !args.isEdited &&
        args.dataItem.status !== PickOrderInventoryItemStatus.Pending &&
        args.dataItem.status !== PickOrderInventoryItemStatus.Reserved &&
        args.dataItem.status !== PickOrderInventoryItemStatus.CheckedOut
      ) {
        args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem));
      }
    }
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem }: { formGroup: FormGroup; dataItem: PickOrderSupplyViewModel } = args;

    this.logger.debug('cellCloseHandler', dataItem, this.gridDataInput);

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }

      this.gridDataInput.forEach(e => {
        if (e.id == dataItem.id) {
          e.quantityRequested = formGroup.value.quantityRequested;
          e.status = PickOrderInventoryItemStatus.NotSaved;
        }
      });
      this.gridDataChange.emit(this.gridDataInput);
    }
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    this.gridDataInput = this.gridDataInput.filter(obj => obj !== dataItem);
    this.gridDataChange.emit(this.gridDataInput);
  }

  public createFormGroup(dataItem: PickOrderSupplyViewModel): FormGroup {
    return this.formBuilder.group({
      quantityRequested: [
        dataItem.quantityRequested,
        Validators.compose([Validators.required, Validators.min(1), Validators.pattern('^[0-9]{1,4}')])
      ]
    });
  }

  public reserveHandler({ dataItem }: EditEvent): void {
    const arr: PickOrderSupplyViewModel[] = [dataItem];
    this.inventoryItemReservationEmitter.emit(arr);
  }

  public getStatusColumnClass(status: string): string {
    if (status === 'NotSaved') {
      return 'bg-warning';
    } else if (status === 'Reserved') {
      return 'bg-primary';
    } else {
      return 'bg-secondary';
    }
  }
}
