import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberRegex, ZipCodeRegex, UrlRegex } from '@wo-app/app.global';

export const getErrorMessage = (validatorName: string, validationErrors?: ValidationErrors): string | undefined => {
  // Handle pattern validation errors specifically
  if (validatorName === 'pattern' && validationErrors?.['requiredPattern']) {
    return getPatternErrorMessage(validationErrors['requiredPattern']);
  }

  // Continue with handling other types of validation errors
  const errorMsg = messages.get(validatorName);
  if (!errorMsg) return undefined;

  const args = errorMsg.key?.map(name => validationErrors?.[name]);

  return args ? stringFormat(errorMsg.message, ...args) : errorMsg.message;
};

function getPatternErrorMessage(pattern: RegExp): string {
  if (pattern.source === ZipCodeRegex.source) {
    return 'Please enter a valid zip code, such as 12345 or 12345-6789.';
  } else if (pattern.source === PhoneNumberRegex.source) {
    return 'Please enter a valid phone number, such as +1 234-567-8900.';
  } else if (pattern.source === UrlRegex.source) {
    return 'Please enter a valid url.';
  } else {
    // Fallback message or specific handling for unrecognized patterns
    console.warn(`Unrecognized pattern: ${pattern}`);
    return 'The entered value does not match the required format.';
  }
}

const messages = new Map<string, { message: string; key?: string[] }>([
  ['required', { message: 'This field is required.' }],
  ['minlength', { message: 'Value must be at least {0} characters long.', key: ['requiredLength'] }],
  ['maxlength', { message: 'Value cannot be more than {0} characters long.', key: ['requiredLength'] }],
  ['email', { message: 'Please enter a valid email address, such as user@example.com.' }]
]);

function stringFormat(template: string | undefined, ...args: any[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined' ? args[index] : match;
    });
  }
  return undefined;
}

// Angular's built-in Validators.pattern() returns patterns as a string with added escape characters.
// This custom validator is required to return the regex pattern as a RegEx and not a string.
export function patternValidator(pattern: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && !pattern.test(value)) {
      return { pattern: { requiredPattern: pattern } }; // Return pattern error if the value doesn't match the regex
    }
    return null; // Return null if validation succeeds
  };
}


export function optionalMaskValidator(pattern: RegExp) {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!value || value.trim() === '') {
      return null; // valid if empty
    }
    return pattern.test(value) ? null : { invalidPattern: true };
  };
}