<div class="component-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <h3>Add New Drug</h3>
                <kendo-autocomplete style="width:100%;" #drugSearchList [filterable]="false" [data]="drugSearchView" [valueField]="'drugName'" (valueChange)="drugSearchListValueChange($event)" [placeholder]="'Search Drug and Add to List...'"></kendo-autocomplete>
            </div>
            <div class="col-sm-12">
                <!-- [navigatable]="true" [animation]="true" (dataAdd)="onDataAdd($event)" (dataRemove)="onDataRemove($event)" (dragEnd)="onDragEnd($event)" (dragOver)="onDragOver($event)" (dragStart)="onDragStart($event)"-->
                <kendo-sortable emptyText="Please add a new drug" [data]="drugList">
                    <ng-template let-item="item" let-i="index">
                        <div class="drawer">
                            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                                <span class="navbar-brand">Drug {{i + 1}}</span>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent"></div>
                                <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                    <button kendoButton type="button" class="btn btn-primary" (click)="deleteRow(i)" [icon]="'delete'">Delete</button>
                                </div>
                            </nav>
                            <div class="drug row">
                                <input type="hidden" [(ngModel)]="item.sortOrder" value="{{item.sortOrder}}" />
                                <div class=" col-md-4">
                                    <label for="{{i}}_brandName" class="col-form-label col-form-label-sm">Brand Name</label>
                                    <input class="form-control" kendoTextBox type="text" [(ngModel)]="item.brandName" id='{{i}}_brandName' placeholder="Brand Name" />
                                </div>
                                <div class=" col-md-4">
                                    <label for="{{i}}_genericName" class="col-form-label col-form-label-sm">Generic Name</label>
                                    <input class="form-control" kendoTextBox type="text" [(ngModel)]="item.genericName" id='{{i}}_genericName' placeholder="Generic Name" />
                                </div>
                                <div class=" col-md-4">
                                    <label for="{{i}}_quantity" class="col-form-label col-form-label-sm">Quantity</label>
                                    <kendo-numerictextbox class="form-control" [(ngModel)]="item.quantity" id='{{i}}_quantity' placeholder="Total Needed" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                                </div>
                                <div class=" col-md-12">
                                    <label class="col-form-label col-form-label-sm">Drug Delivery Method</label><br/>
                                    <app-radio-button-list [(ngModel)]="item.deliveryMethod" [orientation]="'horizontal'" [data]="drugDeliveryMethods" groupName="{{i}}_drugDeliveryMethods" [showInline]="true" [dataLabelField]="'name'" [dataValueField]="'value'"></app-radio-button-list>
                                </div>
                                <div class=" col-md-12">
                                    <label for="{{i}}_notes" class="col-form-label col-form-label-sm">Notes</label>
                                    <textarea kendoTextBox class="form-control" type="text" [(ngModel)]="item.notes" id='{{i}}_notes' placeholder="Notes"></textarea>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </kendo-sortable>
                {{drugList | json}}
            </div>
        </div>
    </div>
</div>

<!--<div class="float-end">
    <kendo-buttongroup>
        <button kendoButton type="button" (click)='saveDrugs()' [primary]='true'>Save Drugs</button>
        <button type="button" kendoButton (click)='addDrug()'>Add Drug</button>
    </kendo-buttongroup>
</div>
<div class="clearfix"></div>

-->