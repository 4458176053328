import { Component } from '@angular/core';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { DashboardService, GetDashboardReportsQueryResult } from '@wo-api/index';
import ArrayUtilities from '@wo-app/shared/utils/array-utils';
import { NGXLogger } from 'ngx-logger';
import { ItemsWithSoonPreventiveMaintenanceViewModel } from '../../../../lib/wo-api/model/itemsWithSoonPreventiveMaintenanceViewModel';

@Component({
  selector: 'app-dashboard-reports',
  templateUrl: './dashboard-reports.component.html',
  styleUrls: ['./dashboard-reports.component.scss']
})
export class DashboardReportsComponent {
  public SupplyStockingQuantityAlertsView: GridDataResult;
  public SmallGridItemClass = 'col-12 col-md-6 col-lg-6 col-xxl-3 d-flex align-items-stretch';
  public stockingQuantityGridState: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'differenceInStockingQuantity' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  ReportData: GetDashboardReportsQueryResult = null;
  ArrayUtilities = ArrayUtilities;

  public pagerSettings: PagerSettings = {
    responsive: true,
    info: false,
    pageSizes: false,
    type: 'numeric',
    position: 'bottom',
    buttonCount: 4
  };

  public loadPickOrderChart = false;

  public itemsWithSoonExpiringWarrantiesTotal = 0;

  constructor(private logger: NGXLogger, private dashboardService: DashboardService) {
    this.dashboardService.getDashboardReportData().subscribe((value: GetDashboardReportsQueryResult) => {
      this.ReportData = value;
      this.loadStockingQuantityAlertItems();
      this.manikinsAndTrainersGridData = this.ReportData.manikinsWithSoonExpiringWarranties;
      this.equipmentGridData = this.ReportData.equipmentWithSoonExpiringWarranties;
      this.itemsWithSoonExpiringWarrantiesTotal = this.manikinsAndTrainersGridData?.length + this.equipmentGridData?.length;
      this.pickOrdersGridData = this.ReportData.openPickOrders?.listPickOrdersCommandViewModels;
      this.pickOrderChartData = this.ReportData.openPickOrders?.statusTotals;
      this.itemsWithSoonPreventiveMaintenance = this.ReportData.itemsWithSoonPreventiveMaintenance;
      this.preventiveMaintenanceEquipmentGridData = this.ReportData.itemsWithSoonPreventiveMaintenance?.equipment;
      this.preventiveMaintenanceManikinsGridData = this.ReportData.itemsWithSoonPreventiveMaintenance.manikins;
      this.equipmentQuery();
      this.manikinsAndTrainersQuery();
      this.pickOrdersQuery();
      this.equipmentPreventiveMaintenanceQuery();
      this.manikinsPreventiveMaintenanceQuery();
    });
  }

  private loadStockingQuantityAlertItems(): void {
    const stockingQuantityAlertGridData = orderBy(this.ReportData.supplyStockingQuantityAlerts, this.stockingQuantityGridState.sort);
    this.SupplyStockingQuantityAlertsView = {
      data: stockingQuantityAlertGridData.slice(
        this.stockingQuantityGridState.skip,
        this.stockingQuantityGridState.skip + this.stockingQuantityGridState.take
      ),
      total: this.ReportData.supplyStockingQuantityAlerts.length
    };
  }

  public stockingQuantityAlertGridPageChange(event: PageChangeEvent): void {
    this.stockingQuantityGridState.skip = event.skip;
    this.loadStockingQuantityAlertItems();
  }

  public StockingQuantityAlertItemsDataStateChange(state: DataStateChangeEvent): void {
    this.logger.log(state);
    this.stockingQuantityGridState = state;
    this.loadStockingQuantityAlertItems();
  }

  public getLabelForManikinTrainersByStatusReport(e: SeriesLabelsContentArgs): string {
    return `${e.category} (${e.value})`;
  }

  public manikinsAndTrainersState: State = {
    skip: 0,
    take: 5,
    sort: [{ dir: 'asc', field: 'warrantyExpirationDate' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public manikinsAndTrainersGridPageChange(event: PageChangeEvent): void {
    this.logger.info('manikinsAndTrainersGridPageChangeEvent', event);
    this.manikinsAndTrainersState.skip = event.skip;
    this.equipmentQuery();
  }
  public manikinsAndTrainersView: GridDataResult;
  manikinsAndTrainersGridData: any[];

  public manikinsAndTrainersDataStateChange(state: DataStateChangeEvent): void {
    this.logger.info('manikinsAndTrainersDataStateChangeEvent', state);
    this.manikinsAndTrainersState = state;
    this.manikinsAndTrainersQuery();
  }

  public manikinsAndTrainersQuery() {
    const sortedGridData = orderBy(this.manikinsAndTrainersGridData, this.manikinsAndTrainersState.sort);
    this.manikinsAndTrainersView = {
      data: sortedGridData.slice(
        this.manikinsAndTrainersState.skip,
        (this.manikinsAndTrainersState.skip ?? 0) + this.manikinsAndTrainersState.take
      ),
      total: sortedGridData.length
    };
  }

  //#region Equipment with Soon Expiring Warranties
  public equipmentView: GridDataResult;

  public equipmentGridData: any[];

  public equipmentsState: State = {
    skip: 0,
    take: 5,
    sort: [{ dir: 'asc', field: 'warrantyExpirationDate' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public equipmentGridPageChange(event: PageChangeEvent): void {
    this.logger.info('equipmentGridPageChangeEvent', event);
    this.equipmentsState.skip = event.skip;
    this.equipmentQuery();
  }

  public equipmentDataStateChange(state: DataStateChangeEvent): void {
    this.logger.info('equipmentDataStateChangeEvent', state);
    this.equipmentsState = state;
    this.equipmentQuery();
  }

  public equipmentQuery() {
    const sortedGridData = orderBy(this.equipmentGridData, this.equipmentsState.sort);
    this.equipmentView = {
      data: sortedGridData.slice(this.equipmentsState.skip, (this.equipmentsState.skip ?? 0) + this.equipmentsState.take),
      total: sortedGridData.length
    };
  }
  //#endregion Equipment with Soon Expiring Warranties

  //#region Open PickOrders
  public pickOrdersView: GridDataResult;
  public pickOrdersGridData: any[];
  public pickOrderChartData: any[]; //Array<{ key: string, value: number }>;

  public pickOrdersState: State = {
    skip: 0,
    take: 5,
    sort: [{ dir: 'asc', field: 'dateNeeded' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public pickOrdersGridPageChange(event: PageChangeEvent): void {
    this.logger.info('pickOrdersGridPageChangeEvent', event);
    this.pickOrdersState.skip = event.skip;
    this.pickOrdersQuery();
  }

  public pickOrdersDataStateChange(state: DataStateChangeEvent): void {
    this.logger.info('pickOrdersDataStateChangeEvent', state);
    this.pickOrdersState = state;
    this.pickOrdersQuery();
  }

  public pickOrdersQuery() {
    const sortedGridData = orderBy(this.pickOrdersGridData, this.pickOrdersState.sort);
    this.pickOrdersView = {
      data: sortedGridData.slice(this.pickOrdersState.skip, (this.pickOrdersState.skip ?? 0) + this.pickOrdersState.take),
      total: sortedGridData.length
    };
  }

  //#region SoonPreventiveMaintenance Equipment
  public itemsWithSoonPreventiveMaintenance: ItemsWithSoonPreventiveMaintenanceViewModel;
  public preventiveMaintenanceEquipmentGridData: any[] = [];
  public equipmentPreventiveMaintenanceView: GridDataResult;

  public equipmentPreventiveMaintenanceState: State = {
    skip: 0,
    take: 5,
    sort: [{ dir: 'asc', field: 'preventativeMaintenanceExpirationDate' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public equipmentPreventiveMaintenanceGridPageChange(event: PageChangeEvent): void {
    this.logger.info('equipmentPreventiveMaintenanceGridPageChangeEvent', event);
    this.equipmentPreventiveMaintenanceState.skip = event.skip;
    this.equipmentPreventiveMaintenanceQuery();
  }

  public equipmentPreventiveMaintenanceDataStateChange(state: DataStateChangeEvent): void {
    this.logger.info('equipmentPreventiveMaintenanceDataStateChangeEvent', state);
    this.equipmentPreventiveMaintenanceState = state;
    this.equipmentPreventiveMaintenanceQuery();
  }

  public equipmentPreventiveMaintenanceQuery() {
    const sortedGridData = orderBy(this.preventiveMaintenanceEquipmentGridData, this.equipmentPreventiveMaintenanceState.sort);
    this.equipmentPreventiveMaintenanceView = {
      data: sortedGridData.slice(
        this.equipmentPreventiveMaintenanceState.skip,
        (this.equipmentPreventiveMaintenanceState.skip ?? 0) + this.equipmentPreventiveMaintenanceState.take
      ),
      total: sortedGridData.length
    };
  }

  //#region SoonPreventiveMaintenance Manikins
  public preventiveMaintenanceManikinsGridData: any[] = [];
  public manikinsPreventiveMaintenanceView: GridDataResult;

  public manikinsPreventiveMaintenanceState: State = {
    skip: 0,
    take: 5,
    sort: [{ dir: 'asc', field: 'preventativeMaintenanceExpirationDate' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public manikinsPreventiveMaintenanceGridPageChange(event: PageChangeEvent): void {
    this.logger.info('manikinsPreventiveMaintenanceGridPageChangeEvent', event);
    this.manikinsPreventiveMaintenanceState.skip = event.skip;
    this.manikinsPreventiveMaintenanceQuery();
  }

  public manikinsPreventiveMaintenanceDataStateChange(state: DataStateChangeEvent): void {
    this.logger.info('manikinsPreventiveMaintenanceDataStateChangeEvent', state);
    this.manikinsPreventiveMaintenanceState = state;
    this.manikinsPreventiveMaintenanceQuery();
  }
  public manikinsPreventiveMaintenanceQuery() {
    const sortedGridData = orderBy(this.preventiveMaintenanceManikinsGridData, this.manikinsPreventiveMaintenanceState.sort);
    this.manikinsPreventiveMaintenanceView = {
      data: sortedGridData.slice(
        this.manikinsPreventiveMaintenanceState.skip,
        (this.manikinsPreventiveMaintenanceState.skip ?? 0) + this.manikinsPreventiveMaintenanceState.take
      ),
      total: sortedGridData.length
    };
  }

  public getLabelForPickOrderChart(e: SeriesLabelsContentArgs): string {
    return e.value;
  }
}
