import { Injectable } from '@angular/core';

import { NbAuthService } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleProviderService implements NbRoleProvider {
  constructor(private authService: NbAuthService, private logger: NGXLogger) {}

  getRole(): Observable<string> {
    return this.authService.onTokenChange().pipe(
      map(token => {
        const value = token.isValid() ? token.getPayload()['role'] : 'guest';
        this.logger.debug('Get Role is below...');
        this.logger.debug(value);
        return value;
      })
    );
  }
}
