<autopilot-grid>
  <kendo-grid
    kendoGridSelectBy="equipmentLineItemId"
    [data]="gridData"
    [selectedKeys]="selectedIds"
    [selectable]="{ checkboxOnly: true }"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [filterable]="false"
    [sortable]="false"
    [sort]="state.sort"
    [loading]="isLoading" [pageable]="true"
    (selectionChange)="onSelectionChange($event)"
    (pageChange)="pageChangeEvent($event)"
    (dataStateChange)="dataStateChangeEvent($event)"
    >
    <ng-template kendoGridToolbarTemplate>
      <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
        <form class="col-auto me-auto" role="search">
          <button kendoButton type="button" class="btn btn-secondary text-light" routerLink="/equipment/edit-list">
            <i class="fa-light fa-gears"></i> Manage {{ this.GetPluralEntityName() }}
          </button>
          <button
            kendoButton
            type="button"
            class="btn btn-secondary text-light"
            [disabled]="selectedIds.length == 0"
            title="To print labels, select one or more from below."
            (click)="onPrintLabels()">
            <i class="fa-light fa-qrcode"></i> Print Labels
          </button>
        </form>
        <div class="text-end">
          <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF">
            <i class="fa-light fa-file-pdf"></i>
          </button>
          <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white">
            <i class="fa-light fa-file-excel"></i>
          </button>
        </div>
      </div>
    </ng-template>
    <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item">
      <ng-template kendoGridHeaderTemplate>
        <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing" (click)="clickSelectAllCheckbox()">
          <i
            class="fa-light fa-print"
            [ngClass]="{ 'text-purple': selectAllState === 'checked', 'fw-bold': selectAllState === 'checked' }"></i>
          <input
            #selectAllCheckbox
            class="d-none k-checkbox k-checkbox-md k-rounded-md"
            type="checkbox"
            title="Select item(s) to print label(s)"
            kendoCheckbox
            kendoGridSelectAllCheckbox
            [state]="selectAllState"
            (selectAllChange)="onSelectAll($event)" />
        </button>
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="wingmanId" title="Wingman ID">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a href="#" class="link" [routerLink]="'/equipment/' + dataItem.equipmentId + '/line-items/edit/' + dataItem.id"
          >{{ dataItem.wingmanId }} <i class="fa-light fa-arrow-up-right-from-square"></i
        ></a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="manufacturerName" title="Manufacturer">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="name" title="Model Name">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="modelNumber" title="Model Number">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a href="#" class="link" [routerLink]="'/equipment/' + dataItem.equipmentId + '/line-items'"
          >{{ dataItem.modelNumber }} <i class="fa-light fa-arrow-up-right-from-square"></i
        ></a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serialNumber" title="Serial Number">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customId" title="Custom Id">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="equipmentStatus" title="Status">
      <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
        <kendo-multiselect
          [data]="equipmentStatuses"
          [placeholder]="enumFilterPlaceholder"
          [value]="filter | filterValues"
          (valueChange)="enumFilterChange($event, filterService, 'equipmentStatus')">
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
    <!-- <kendo-grid-column-group title="Current Location"> -->
    <kendo-grid-column field="fullLocationName" title="Location">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-pdf
      [fileName]="pdfFileName"
      paperSize="A4"
      [scale]="0.8"
      [repeatHeaders]="true"
      [allPages]="true"
      [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>
<router-outlet></router-outlet>
<div kendoDialogContainer></div>
