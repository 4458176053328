import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor, filterBy, groupBy, orderBy } from '@progress/kendo-data-query';
import {
  BuildingService,
  CreateRoomCommand,
  GetLocationQuantityListForBuildingCommand,
  GetLocationQuantityListForBuildingCommandResult,
  GetLocationQuantityListForRoomCommandResult,
  ListBuildingsCommand,
  ListBuildingsCommandViewModel,
  OrganizationService,
  ReadRoomCommandResult,
  RoomSupplyReportViewModel,
  UpdateRoomCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-building-inventory-list',
  templateUrl: './building-inventory-list.component.html',
  styleUrls: ['./building-inventory-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildingInventoryListComponent extends EntityBaseComponent implements OnInit {
  readRoomCommandResult: ReadRoomCommandResult = {};
  dataModel: ReadRoomCommandResult;
  listBuildingsCommand: ListBuildingsCommand = {};
  updateRoomCommand: UpdateRoomCommand = {};
  createRoomCommand: CreateRoomCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  buildingsForOrganization: ListBuildingsCommandViewModel[];
  buildingId: number;
  GetLocationQuantityListForRoomCommand: Observable<GetLocationQuantityListForRoomCommandResult>;
  gridData: GridDataResult = { data: [], total: 0 };
  public aggregates: AggregateDescriptor[] = [{ field: 'quantity', aggregate: 'sum' }];

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    public cachedDataService: CachedDataService,
    private buildingService: BuildingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    _breadcrumbService: BreadcrumbsService,
    _titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.ROOMS, injector);
    this.editMode = false;
    this.state.sort = [{ dir: 'asc', field: 'supplyName' }];
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    this.isLoading = true;

    const cmd: GetLocationQuantityListForBuildingCommand = {
      id: this.id,
      skip: this.state.skip,
      take: this.state.take,
      sort: this.state.sort
    };

    this.buildingService.getLocationQuantityListForBuilding(cmd).subscribe((value: GetLocationQuantityListForBuildingCommandResult) => {
      this.logger.debug('Got Data from GetLocationQuantityListForRoomCommandResult', value);
      this.loadingBar.useRef('router').complete();
      this.gridData.data = value.data;
      if (value && value.data.length) {
        this.UpdateBreadcrumbPageTitle(
          value.data[0].buildingId,
          'View Inventory for ' + value.data[0].buildingName + ` (${value.data[0].buildingId})`
        );
      }
      this.gridData.total = value.totalRowRowCount;
      this.isLoading = false;
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.reloadData();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }

  public getQuantityWrapperTitle(dataItem: RoomSupplyReportViewModel): string {
    let returnMessage = '';
    if (dataItem.quantity <= dataItem.stockingQuantity) {
      returnMessage = 'This items quantity in this room is below the stocking quantity.';
    } else if (dataItem.quantity <= dataItem.stockingQuantity / 2) {
      returnMessage = 'This items quantity in this room is approaching the stocking quantity.';
    }
    return `${returnMessage}`;
  }

  public groupChange(groups: GroupDescriptor[]): void {
    groups.map(group => (group.aggregates = this.aggregates));

    this.state.group = groups;
    this.reloadData();
  }
}
