import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NGXLogger } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { ConfigLoaderService, ImpersonationService } from '../services';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class LoggedInAuthGuard {
  constructor(
    private logger: NGXLogger,
    private configLoader: ConfigLoaderService,
    private authService: NbAuthService,
    impersonationService: ImpersonationService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageMap
  ) {}

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  canActivateChild() {
    return true;
  }

  canActivate() {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap(authenticated => {
        if (!authenticated) {
          this.configLoader.Config.subscribe(config => {
            this.logger.debug(config.applicationLoginRoute);
            this.delay(500).then(any => {
              this.router.navigate([config.applicationLoginRoute]);
            });
          });
        }
      })
    );
  }
}
