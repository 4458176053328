<app-manikin-and-trainer-info-header [modelInfo]="modelInfo"></app-manikin-and-trainer-info-header>

<autopilot-grid>
  <kendo-grid
      [data]="gridData"
      [style.maxHeight.%]="100"
      [pageSize]="state.take"
      [filterable]="false"
      [sortable]="false"
      [sort]="state.sort"
      [loading]="isLoading" [pageable]="true"
      [selectable]="{ checkboxOnly: true }"
      kendoGridSelectBy="id"
      [selectedKeys]="selectedIds"
      (pageChange)="pageChangeEvent($event)"
      (dataStateChange)="dataStateChangeEvent($event)"
      (selectionChange)="onSelectionChange($event)"
      class="stock-information"
      >
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
                <form class="col-auto mb-2 me-auto" role="search">
                    <button kendoButton type="button"  class="btn btn-primary text-white" [routerLink]="'/manikin-make-and-model/edit-list'"><i class="fa-light fa-arrow-left-long"></i> Return to Manage Manikins & Trainers</button>
                    <button kendoButton type="button" [routerLink]="'/manikin-make-and-model/' + this.manikinMakeAndModelId + '/line-items/add'"  class="btn btn-primary" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}} Line Item</button>
                    <kendo-button (click)="onPrintLabels()"><i class="fa-light fa-qrcode"></i> Print Labels</kendo-button>
                </form>
                <div class="text-end">
                    <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                    <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
                </div>
          </div>
      </ng-template>
      <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item">
        <ng-template kendoGridHeaderTemplate>
          <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing" (click)="clickSelectAllCheckbox()">
            <i
              class="fa-light fa-print"
              [ngClass]="{ 'text-purple': selectAllState === 'checked', 'fw-bold': selectAllState === 'checked' }"></i>
            <input
              #selectAllCheckbox
              class="d-none k-checkbox k-checkbox-md k-rounded-md"
              type="checkbox"
              title="Select item(s) to print label(s)"
              kendoCheckbox
              kendoGridSelectAllCheckbox
              [state]="selectAllState"
              (selectAllChange)="onSelectAll($event)" />
          </button>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column title="Actions" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton
                    class="btn btn-primary default-action-button"
                    [primary]="true"
                    title="Edit this item"
                    [routerLink]="'/manikin-make-and-model/' + this.manikinMakeAndModelId + '/line-items/edit/' + dataItem.id">
                <i class="fas fa-edit"></i>
              </button>
              <button kendoButton
                    [primary]="true"
                    class="btn btn-primary default-action-button"
                    (click)="delete(dataItem.id)"
                    title="Delete this item">
                <i class="fas fa-trash"></i>
              </button>
              <button kendoButton
                    class="btn btn-primary default-action-button"
                    [primary]="true"
                    (click)="onQrClick(dataItem.id)">
                    <i class="fa-light fa-qrcode"></i>
              </button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="wingmanId" title="Wingman ID">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                [extra]="false">
            </kendo-grid-string-filter-menu>
        </ng-template>
    </kendo-grid-column>
      <kendo-grid-column field="nickname" title="Nick Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="status" title="Status">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="equipmentStatuses"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'status')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="skinTone" title="Skin Tone">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="skinTones"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'skinTone')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="gender" title="Gender">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="genders"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'gender')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column-group title="Current Location">
          <kendo-grid-column field="locationName" title="Location">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="buildingName" title="Building">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="roomName" title="Room">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="bedName" title="Bed">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>

<kendo-dialog *ngIf="isQrModalVisible" title="QR Code" (close)="isQrModalVisible=false">
  <kendo-qrcode [value]="qrLink">
  </kendo-qrcode>
</kendo-dialog>
