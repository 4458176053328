<autopilot-grid>
    <kendo-grid
        [kendoGridBinding]="gridData"
        [style.maxHeight.%]="100"
        [pageSize]="state.take"
        [filterable]="false"
        [sortable]="false"
        [sort]="sort"
        [loading]="isLoading" [pageable]="true">
        <ng-template kendoGridToolbarTemplate>
            <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
                <form class="col-auto me-auto" role="search">
                    <button kendoButton type="button" class="btn btn-secondary text-light" (click)="discoverDevicesClickEvent($event)"><i class="fa-light fa-print-magnifying-glass"></i> Discover Printers</button>
                    <button kendoButton type="button" class="btn btn-secondary text-light mx-2" [routerLink]="'/printer/print-labels'" ><i class="fa-light fa-print"></i> Print Labels</button>
                </form>
            </div>
        </ng-template>
        <kendo-grid-column title="Actions">
            <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton type="button" class="btn" (click)="setAsDefaultPrinter(dataItem)" [disabled]="defaultPrinter?.uid == dataItem.uid">
                    Set As Default Printer
                </button>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Connection">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.connection}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Name">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.name}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Manufacturer">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.manufacturer}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Device Type">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.deviceType}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="UID">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.uid}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Provider">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.provider}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Version">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.version}}
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
            The system cannot find any available and compatible label printers. Make sure that Browser Print is running, that your Zebra printer is connected to this computer, and that it is turned on.
        </ng-template>
    </kendo-grid>
  </autopilot-grid>  
  <router-outlet></router-outlet>
  <div kendoDialogContainer></div>
  