import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  CreatePickOrderCommand,
  ListPickOrderInventoryListQuery,
  ListPickOrderInventoryListQueryResult,
  PickOrderCheckInCheckOutService,
  PickOrderService,
  PickOrderStatus,
  ReadPickOrderCommandResult,
  UpdatePickOrderCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService, CachedDataService, ModalService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-pull-or-return-pick-order',
  templateUrl: './pull-or-return-pick-order.component.html',
  styleUrl: './pull-or-return-pick-order.component.scss'
})
export class PullOrReturnPickOrderComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private pickOrderService: PickOrderService,
    private pickOrderCheckInCheckOutService: PickOrderCheckInCheckOutService,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    private modalService: ModalService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(EntityGlobals.PICKORDERS, injector);
    this.editMode = true;
  }

  public dataModel: ReadPickOrderCommandResult;
  public updatePickOrderCommand: UpdatePickOrderCommand = {};
  public createPickOrderCommand: CreatePickOrderCommand = {};

  public form: FormGroup;
  public originalFormState: FormGroup;

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };

  public pickOrderStatus: PickOrderStatus;

  public ListPickOrderInventoryListQueryResult: ListPickOrderInventoryListQueryResult = null;

  private createForm() {
    this.originalFormState = this.form;
  }

  ngOnInit(): void {
    this.createForm();
    if (this.editMode || this.copyMode) {
      this.getData(this.copyMode);
    } else {
      this.showForm = true;
    }
  }

  private getData(isCopy?: boolean) {
    this.loadingBar.useRef('router').start();
    const request: ListPickOrderInventoryListQuery = { pickOrderId: this.id };

    this.pickOrderCheckInCheckOutService
      .listPickOrderInventoryList(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListPickOrderInventoryListQueryResult) => {
        this.ListPickOrderInventoryListQueryResult = result;
        this.UpdateBreadcrumbPageTitle(result.pickOrderPickListPickOrder.pickOrderId);
        this.logger.debug(result);

        this.showForm = true;
        this.loadingBar.useRef('router').complete();
      });
  }
}
