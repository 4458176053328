<autopilot-grid>
  <kendo-grid
    [data]="gridData"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="false"
    [loading]="isLoading" [pageable]="true"
    (dataStateChange)="dataStateChange($event)"
    (pageChange)="pageChangeEvent($event)"
    >
      <ng-template kendoGridToolbarTemplate>

          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto me-auto" role="search">
                  <button kendoButton type="button"  class="btn btn-secondary text-white" (click)="addNewWithOrganizationId(organizationId)" ><i class="fa-light fa-plus"></i> Add {{this.GetSingleEntityName()}}</button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-column title="Actions" [width]="108">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton
                    class="btn btn-outline-primary default-action-button"
                    title="View this item"
                    [primary]="true"
                    (click)="routeToViewEntity(dataItem.id)">
              <i class="fas fa-eye"></i>
            </button>
            <button kendoButton
                    class="btn btn-outline-danger default-action-button"
                    title="Delete this item"
                    [primary]="true"
                    (click)="delete(dataItem.id)">
              <i class="fas fa-trash"></i>
            </button>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ImportFileSignedUrl" title="Import File">
          <ng-template kendoGridCellTemplate let-dataItem>
              <a href="{{dataItem.importFileSignedUrl}}" target="_blank">View File</a>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="importJobStatus" title="Import Job Status">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.importJobStatus}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="importJobType" title="Import Job Type">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.importJobType}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="created" title="Date Created">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.created | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastUpdated" title="Date Last Updated">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.lastUpdated | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>
