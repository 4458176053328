import { Directive, Injector, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GridComponent, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { EntityInformation } from '@wo-app/app.global';
import { IBreadcrumb } from '@wo-app/breadcrumbs/shared/models';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Directive()
export class EntityBaseComponent implements OnDestroy {
  constructor(
    public entityInformation: EntityInformation,
    injector: Injector
  ) {
    this.entityRouter = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.breadcrumbService = injector.get(BreadcrumbsService);
    this.titleService = injector.get(Title);
    this.LoadingBarService = injector.get(LoadingBarService);
    this.entityLogger = injector.get(NGXLogger);
    this.impersonationService = injector.get(ImpersonationService);

    this.LoadingBarService.useRef('router').value$.subscribe(value => {
      if (value === 0) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
    });

    this.showForm = false;
    this.entityLogger.log('Loading EntityComponentBaseClass for ' + entityInformation.pluralName);
    this.setupEntityInformation(entityInformation);
    this.id = this.activatedRoute.snapshot.params['id'];
    this.editMode = this.entityRouter.url.indexOf('/edit/') > -1 && this.id !== undefined;
    this.copyMode = this.entityRouter.url.indexOf('/add/') > -1 && this.id !== undefined;

    this.impersonationService.getImpersonatedOrganizationId().then(organizationId => {
      if (organizationId !== null) {
        this.organizationId = organizationId as number;
        this._organizationId.next(organizationId as number);
      }
    });

    // Resize the Grid
    fromEvent(window, 'resize')
      .pipe(debounceTime(750))
      .subscribe(event => {
        this.resizeGrid(event as MyEvent);
      });
  }

  titleService: Title;
  breadcrumbService: BreadcrumbsService;
  activatedRoute: ActivatedRoute;
  impersonationService: ImpersonationService;

  public destroy$ = new Subject<void>();

  @ViewChild('defaultdatagrid') defaultdatagrid: GridComponent;
  public id: any;
  public pageTitle = '';
  public showForm: boolean;
  public isLoading = false;
  public organizationId: number;
  public editMode = false;
  public copyMode = false;
  private singleEntityName: string;
  private pluralEntityName: string;
  private url: string;
  private entityRouter: Router;
  private icon?: string;
  private entityLogger: NGXLogger;
  public FroalaEditorOptions: object = {
    key: 'zG5D4H4D3kB6B5C4F6F3E3D3F2C6A2D-13pmhiwF-7ffD-13xdaacI1B-22A-16==',
    charCounterCount: true,
    toolbarButtons: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'outdent',
      'indent',
      'clearFormatting',
      'insertTable',
      'html'
    ],
    toolbarButtonsXS: ['undo', 'redo', '-', 'bold', 'italic', 'underline']
  };

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public breadcrumbs: IBreadcrumb[];

  private _organizationId = new BehaviorSubject<number>(null);
  public readonly OrganizationId: Observable<number> = this._organizationId.asObservable().pipe(filter(value => value !== null));

  private _itemId = new BehaviorSubject<any>(null);
  public readonly ItemId: Observable<any> = this._itemId.asObservable().pipe(filter(value => value !== null));

  private LoadingBarService: LoadingBarService = null;

  ngOnDestroy() {
    this.entityLogger.log('Destroying EntityComponentBaseClass');
    this._organizationId.unsubscribe();
    this._itemId.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  /** Setup the various Properties related to the Entity's Information */
  setupEntityInformation(entityInformation: EntityInformation) {
    this.singleEntityName = entityInformation.singleName;
    this.pluralEntityName = entityInformation.pluralName;
    this.url = entityInformation.baseRoute;
    this.icon = entityInformation.icon;
  }

  resizeGrid(e: MyEvent) {}

  public GetSingleEntityName() {
    return this.singleEntityName;
  }

  public GetPluralEntityName() {
    return this.pluralEntityName;
  }

  public GetEntityUrl() {
    return this.url;
  }

  public UpdateBreadcrumbPageTitle(id: number, name: string = null, wingmanId: string = null): void {
    this.pageTitle = this.editMode
      ? `Edit ${this.GetSingleEntityName()}${name == null ? '' : ' "' + name}"`
      : name == null
        ? id.toString()
        : name;

    if (this.breadcrumbService != null) {
      //change the title of the breadcrumb
      this.entityLogger.log('Attempting to change Title of the Breadcrumb');
      this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1].label = this.pageTitle;
    }
    if (this.titleService != null) {
      //change the title of the page
      this.titleService.setTitle(this.pageTitle);
    }
  }

  public addNew() {
    const route = this.GetEntityUrl() + '/' + 'add';
    this.entityLogger.log('Route to Add new ' + this.GetSingleEntityName() + ' at ');
    this.entityRouter.navigate([route]);
  }

  public addNewWithOrganizationId(organizationId) {
    this.entityLogger.log('Route to Add new ' + this.GetSingleEntityName());
    this.entityRouter.navigate([this.GetEntityUrl() + '/' + 'add']);
  }

  public routeToEntityList() {
    const route = '/' + this.GetEntityUrl() + '/';
    this.entityLogger.log(route);
    this.entityRouter.navigate([route]);
  }

  public routeToEditorTemplate(id: any) {
    const route = '/' + this.GetEntityUrl() + '/edit/' + id;
    this.entityLogger.log(route);
    this.entityRouter.navigate([route]);
  }

  public routeToAddWithOrganization() {
    const route = '/' + this.GetEntityUrl() + '/add';
    this.entityLogger.log(route);
    this.entityRouter.navigate([route]);
  }

  /** Returns a Date object given a time in format "hh:mm A" */
  public todayAtTime(time: string): Date {
    return new Date(moment(moment().format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm:ss').valueOf());
  }

  public routeToEditWithOrganization(id: any) {
    this.entityRouter.navigate(['/' + this.GetEntityUrl() + '/edit/' + id]);
  }

  public routeToViewEntity(id: any) {
    this.entityRouter.navigate(['/' + this.GetEntityUrl() + '/view/' + id]);
  }

  get pdfFileName() {
    return this.pluralEntityName + '_' + moment().format('YYYYMMDDHH') + '.pdf';
  }

  get excelFileName() {
    return this.pluralEntityName + '_' + moment().format('YYYYMMDDHH') + '.xlsx';
  }
}

interface MyEventTarget extends EventTarget {
  innerHeight: number;
}

interface MyEvent extends Event {
  target: MyEventTarget;
}
