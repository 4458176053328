import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, GridDataResult, MultipleSortSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { ListRoomsCommand, ListRoomsCommandResult, RoomService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    impersonationService: ImpersonationService,
    private roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBar: LoadingBarService,
    injector: Injector
  ) {
    super(EntityGlobals.ROOMS, injector);
    this.allData = this.allData.bind(this);
  }

  gridData: GridDataResult = { data: [], total: 0 };

  public sort: SortDescriptor[] = [
    { dir: 'asc', field: 'buildingName' },
    { dir: 'asc', field: 'name' }
  ];

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    const cmd: ListRoomsCommand = {
      take: this.state.take,
      skip: this.state.skip,
      sort: this.sort
    };
    this.roomService
      .list(cmd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListRoomsCommandResult) => {
        this.logger.debug('Room information received', result);
        this.loadingBar.useRef('router').complete();
        this.gridData.data = result.data.map(item => ({
          ...item,
          createdDate: new Date(item.created),
          lastUpdatedDate: new Date(item.lastUpdated)
        }));
        this.gridData.total = result.totalBedCount;
      });
  }

  public allData(): ExcelExportData {
    this.logger.debug('this.gridData', this.gridData);
    const result: ExcelExportData = {
      data: process(this.gridData.data, {
        sort: this.sort
      }).data
      //group: this.group,
    };
    return result;
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.roomService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }
  public pageChangeEvent(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.reloadData();
  }

  public dataStateChangeEvent(state: DataStateChangeEvent): void {
    this.state = state;
    this.reloadData();
  }
}
