<kendo-dialog width="300"  [title]="title" (close)="close('')">   
    <kendo-grid
        [data]="gridData"
        [style.height.px]="500"
        [style.width.px]="1000"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filterable]="false"
        [sortable]="false"
        [loading]="isLoading" [pageable]="true"
        [filter]="filter"
        (filterChange)="filterChange($event)"
        (dataStateChange)="dataStateChange($event)"
        class="stock-information"
        [loading]="isLoading"
        kendoGridSelectBy
        [selectable]="selectableSettings"
        (selectionChange)="processRowSelection($event)"
        [kendoGridSelectBy]="'id'"
        [(selectedKeys)]="selectedIds"
        (pageChange)="pageChangeEvent($event)"
        (dataStateChange)="dataStateChange($event)"
        >
        <kendo-grid-column field="id" title="Id" [hidden]="true"></kendo-grid-column>
        <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item"></kendo-grid-checkbox-column>
        <kendo-grid-column field="name" title="Item Name" [width]="500"></kendo-grid-column>
        <kendo-grid-column field="manufacturerNumber" title="Manufacturer #"></kendo-grid-column>
    </kendo-grid>
    <kendo-dialog-actions layout="end">
        <ng-template ngFor [ngForOf]="buttons" let-btn>
            <button kendoButton [primary]="true" (click)="close(btn)">{{ btn }}</button>
        </ng-template>
    </kendo-dialog-actions>
    <!-- Selected items by row index: {{ selectedIds | json }} -->
</kendo-dialog>     
