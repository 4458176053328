import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateLabRequestCommand,
  CreateLabRequestCommandResult,
  LabRequestService,
  ReadLabRequestCommandResult,
  UpdateLabRequestCommand,
  UpdateLabRequestCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'lab-request-detail',
  templateUrl: './lab-request-detail.component.html',
  styleUrls: ['./lab-request-detail.component.scss']
})
export class LabRequestDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readLabRequestCommandResult: ReadLabRequestCommandResult = {};
  dataModel: ReadLabRequestCommandResult;
  updateLabRequestCommand: UpdateLabRequestCommand = {};
  createLabRequestCommand: CreateLabRequestCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  patients: any[];
  drugs: any[];
  labRequestStatuses: any[] = [];
  labRequestDatesAndTimes: any[] = [];
  timePickerMin: Date;
  timePickerMax: Date;

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private labrequestService: LabRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public cachedDataService: CachedDataService,
    private dialogService: DialogService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(EntityGlobals.LAB_REQUESTS, injector);
    this.loadingBar.useRef('router').start();
    this.setupForm();
    this.timePickerMin = this.todayAtTime('7:00');
    this.timePickerMax = this.todayAtTime('20:00');
    this.fromKey = 'FormModel';
    this.originalFormState = this.form;
    this.OrganizationId.subscribe(() => {
      if (this.editMode) {
        this.getData();
      } else {
        this.showForm = true;
      }
    });
  }

  ngOnInit() {}

  setupForm() {
    this.form = this.fb.group({
      id: [''],
      patients: [''],
      drugs: [''],
      courseName: [''],
      labRequestStatus: [''],
      totalNumberOfStudents: [''],
      supplies: new FormControl([]),
      equipment: new FormControl([]),
      labRequestDatesAndTimes: new FormControl([]),
      rotatingThroughPeriodOfDates: [''],
      rotatingThroughPeriodOfDatesMaxStudents: [''],
      numberOfStudentsInGroup: [''],
      requestorName: [''],
      numberOfSimRoomsRequested: [''],
      numberOfSimRoomsNeedingRecording: [''],
      usingSimRoomsSimultaneously: [''],
      numberOfPeopleToDebrief: [''],
      numberOfPeopleLiveStreamingToDebriefingRoom: [''],
      liveStreamToDebriefingRoom: [''],
      requestorEmail: [''],
      requestorPhone: [''],
      organizationId: [''],
      learningObjectives: [''],
      roomSetting: [''],
      lengthOfScenarioInMinutes: [''],
      patientMonitorDisplay: [''],
      specialInstructions: [''],
      description: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
  }

  getData() {
    this.labrequestService.read(this.id).subscribe((result: ReadLabRequestCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.logger.debug(result);
      this.form.patchValue(result);
      this.showForm = true;
      //this.patients = result.patients;
      //this.drugs = result.drugs;
    });
  }

  resetForm() {
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  convertLabDatesAndTimesToPushableData(array): any[] {
    this.logger.debug(array);
    const dateFormatUTC = 'YYYY-MM-DD HH:mm:ssZ';
    const newArray = [];
    for (const item of array) {
      this.logger.debug(item);
      let newItem;
      if (item.controls) {
        const dateTimeStart = moment(item.controls.dateTimeStart.value);
        let dateTimeEnd = moment(item.controls.dateTimeEnd.value);
        dateTimeEnd = moment(dateTimeStart.format('YYYY-MM-DD') + dateTimeEnd.format('HH:mm:ssZ'));
        newItem = {
          dateTimeStart: dateTimeEnd.toString(),
          dateTimeEnd: dateTimeEnd.toString()
        };
        newArray.push(newItem);
      } else {
        const dateTimeStart = moment(item.dateTimeStart);
        let dateTimeEnd = moment(item.dateTimeEnd);
        dateTimeEnd = moment(dateTimeStart.format('YYYY-MM-DD') + dateTimeEnd.format('HH:mm:ssZ'));
        item.dateTimeStart = dateTimeStart.toString();
        item.dateTimeEnd = dateTimeEnd.toString();
        newArray.push(item);
      }
    }
    return newArray;
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateLabRequestCommand = this.form.value;
      this.labrequestService
        .update(this.updateLabRequestCommand.id.toString(), this.updateLabRequestCommand)
        .subscribe((result: UpdateLabRequestCommandResult) => {
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.form.controls['labRequestStatus'].setValue(0);
      this.createLabRequestCommand = this.form.value;
      this.labrequestService.create(this.createLabRequestCommand).subscribe((result: CreateLabRequestCommandResult) => {
        this.routeToEntityList();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }

  submitAndClose() {
    this.onSubmit(true);
  }

  disable() {}

  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this labrequest?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }

  onNotificationOfDrugs(drugs) {
    this.form.controls['drugs'].setValue(drugs);
    this.onSubmit(false);
  }

  onNotificationOfPatients(patients) {
    this.form.controls['patients'].setValue(patients);
    this.onSubmit(false);
  }

  onNotificationOfSupplies(supplies) {
    this.logger.debug(supplies);
  }
}
