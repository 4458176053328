<div *ngIf="dataResult != null">
    <div>
        <div class="row">
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Location Name</label>
                <span class="form-control">{{dataResult.locationName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Building Name</label>
                <span class="form-control">{{dataResult.buildingName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Room Name</label>
                <span class="form-control">{{dataResult.roomName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Supply Name</label>
                <span class="form-control">{{dataResult.supplyName}}</span>
            </div>
        </div>
    </div>
    <div class="mt-3">
      <autopilot-grid>
        <kendo-grid
        [data]="gridData"
        [style.maxHeight.%]="100"
        [pageSize]="state.take"
        [skip]="state.skip"
        [filterable]="false"
        [sort]="state.sort"
        [loading]="isLoading" [pageable]="true"
        (pageChange)="pageChangeEvent($event)"
        (dataStateChange)="dataStateChangeEvent($event)"
          >
            <kendo-grid-column field="quantityBefore" title="Quantity Before"></kendo-grid-column>
            <kendo-grid-column field="quantityAfter" title="Quantity After"></kendo-grid-column>
            <kendo-grid-column field="quantityChange" title="Quantity Change"></kendo-grid-column>
            <kendo-grid-column field="notes" title="Notes"></kendo-grid-column>
            <kendo-grid-column field="createByUsername" title="Created By"></kendo-grid-column>
            <kendo-grid-column field="created" title="Date Created">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.created | amCalendar }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
        </kendo-grid>
      </autopilot-grid>

        <div kendoDialogContainer></div>
    </div>
</div>
