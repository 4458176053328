import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmailTemplatesService,
  ListEmailTemplateViewModel,
  ListEmailTemplatesCommand,
  ListEmailTemplatesCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-email-template-listing',
  templateUrl: './email-template-listing.component.html',
  styleUrls: ['./email-template-listing.component.scss']
})
export class EmailTemplateListingComponent extends EntityBaseComponent implements OnInit {
  public emailTemplates: ListEmailTemplateViewModel[] = [];
  constructor(
    injector: Injector,
    private emailTemplateService: EmailTemplatesService,
    public cachedDataService: CachedDataService
  ) {
    super(EntityGlobals.EMAIL_TEMPLATES, injector);
  }

  ngOnInit() {
    const cmd: ListEmailTemplatesCommand = {};
    this.emailTemplateService.list(cmd).subscribe((result: ListEmailTemplatesCommandResult) => {
      if (result && result.data) {
        this.emailTemplates = result.data;
      }
    });
  }
}
