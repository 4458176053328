import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { DrugService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-drugs',
  templateUrl: './drugs.component.html',
  styleUrls: ['./drugs.component.scss']
})
export class DrugsComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    impersonationService: ImpersonationService,
    private drugService: DrugService,
    private route: ActivatedRoute,
    private router: Router,
    injector: Injector
  ) {
    super(EntityGlobals.DRUGS, injector);
  }
  gridData: any[];

  public view: GridDataResult;

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }
}
