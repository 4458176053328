import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import {
  BuildingService,
  CreatePickOrderCommand,
  CreatePickOrderCommandResult,
  CustomerService,
  CustomerViewModel,
  InventoryItemType,
  InventoryReservationViewModel,
  ListBuildingsCommandResult,
  ListBuildingsCommandViewModel,
  ListCoursesCommandResult,
  ListCustomersCommandResult,
  ListPickOrderEquipmentCommand,
  ListPickOrderEquipmentCommandResult,
  ListPickOrderManikinCommand,
  ListPickOrderManikinCommandResult,
  ListPickOrderSuppliesCommand,
  ListPickOrderSuppliesCommandResult,
  ListUsersCommandResult,
  PickOrderEquipmentService,
  PickOrderEquipmentViewModel,
  PickOrderInventoryItemStatus,
  PickOrderManikinService,
  PickOrderManikinViewModel,
  PickOrderService,
  PickOrderSupplyService,
  PickOrderSupplyViewModel,
  ReadPickOrderCommandResult,
  ReservePickOrderEquipmentCommand,
  ReservePickOrderManikinsCommand,
  ReservePickOrderSuppliesCommand,
  UpdatePickOrderCommand,
  UpdatePickOrderCommandResult,
  UserService,
  UserViewModel,
  FillPickOrderCommand,
  FillPickOrderCommandResult,
  ListBuildingsCommand,
  ListCustomersCommand,
  ListUsersCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService, ModalService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, takeUntil } from 'rxjs';
import { PickOrderStatus } from '../../../../../lib/wo-api/model/pickOrderStatus';
import { PickOrderEquipmentSelectionDialogComponent } from '../pick-order-equipment-selection-dialog/pick-order-equipment-selection-dialog.component';
import { PickOrderManikinsSelectionDialogComponent } from '../pick-order-manikins-selection-dialog/pick-order-manikins-selection-dialog.component';
import { PickOrderSuppliesSelectionDialogComponent } from '../pick-order-supplies-selection-dialog/pick-order-supplies-selection-dialog.component';
import { PickOrderItemFillRoomSelectionComponent } from '../pick-order-item-fill-room-selection/pick-order-item-fill-room-selection.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PickOrderSupplyRoomSelectionDialogComponent } from '../pick-order-supply-room-selection-dialog/pick-order-supply-room-selection-dialog.component';

@Component({
  selector: 'app-pick-order-detail',
  templateUrl: './pick-order-detail.component.html',
  styleUrls: ['./pick-order-detail.component.scss']
})
export class PickOrderDetailComponent extends EntityBaseComponent implements OnInit {
  public dataModel: ReadPickOrderCommandResult;
  public updatePickOrderCommand: UpdatePickOrderCommand = {};
  public createPickOrderCommand: CreatePickOrderCommand = {};

  public form: FormGroup;
  public originalFormState: FormGroup;

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };

  public pickOrderStatus: PickOrderStatus;
  public inventoryItemTypeSupply = InventoryItemType.Supply;
  public inventoryItemTypeEquipment = InventoryItemType.Equipment;
  public inventoryItemTypeManikin = InventoryItemType.Manikin;

  public selectedSupplies: PickOrderSupplyViewModel[] = [];
  public selectedEquipment: PickOrderEquipmentViewModel[] = [];
  public selectedManikins: PickOrderManikinViewModel[] = [];

  // TODO - is this meant for courses form control as dropdown data?
  public $courses: Observable<ListCoursesCommandResult>;

  public customersDataSource: CustomerViewModel[] = [];
  public customersData: CustomerViewModel[] = [];
  public $customers: Observable<ListCustomersCommandResult>;

  public buildingsDataSource: ListBuildingsCommandViewModel[] = [];
  public buildingsData: ListBuildingsCommandViewModel[] = [];
  public $buildings: Observable<ListBuildingsCommandResult>;

  public usersDataSource: UserViewModel[] = [];
  public usersData: UserViewModel[] = [];
  public $users: Observable<ListUsersCommandResult>;

  get dateNeeded() {
    return this.form.get('dateNeeded');
  }

  get studentCount() {
    return this.form.get('studentCount');
  }

  get customerId() {
    return this.form.get('customerId');
  }

  get deliveryLocation() {
    return this.form.get('deliveryLocation');
  }

  get dateOfReturn() {
    return this.form.get('dateOfReturn');
  }

  get buildingId() {
    return this.form.get('buildingId');
  }

  get assigneeUserId() {
    return this.form.get('assigneeUserId');
  }

  get canAssignUser() {
    const hasSuppliesWithQuantity = this.selectedSupplies.length > 0 && this.selectedSupplies.some(s => s.quantityRequested > 0);
    const hasEquipmentWithQuantity = this.selectedEquipment.length > 0 && this.selectedEquipment.some(s => s.quantityRequested > 0);
    const hasManikinsWithQuantity = this.selectedManikins.length > 0 && this.selectedManikins.some(s => s.quantityRequested > 0);
    const canAssignUser = hasSuppliesWithQuantity || hasEquipmentWithQuantity || hasManikinsWithQuantity;
    return canAssignUser;
  }

  constructor(
    private logger: NGXLogger,
    private customerService: CustomerService,
    public cachedDataService: CachedDataService,
    impersonationService: ImpersonationService,
    private pickOrderService: PickOrderService,
    private pickOrderSupplyService: PickOrderSupplyService,
    private pickOrderEquipmentService: PickOrderEquipmentService,
    private pickOrderManikinService: PickOrderManikinService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    private modalService: ModalService,
    private buildingService: BuildingService,
    private userService: UserService,
    private loadingBar: LoadingBarService,
    injector: Injector
  ) {
    super(EntityGlobals.PICKORDERS, injector);

    const customersQuery: ListCustomersCommand = {
      skip: 0,
      take: 1000
    };
    this.$customers = this.customerService.list(customersQuery);
    this.$customers.subscribe(res => {
      if (res) {
        this.customersDataSource = res.data as CustomerViewModel[];
        this.customersData = this.customersDataSource.slice();
      }
    });

    const buildingsListQuery: ListBuildingsCommand = {
      skip: 0,
      take: 1000,
      sort: []
    };
    this.$buildings = this.buildingService.list(buildingsListQuery);
    this.$buildings.subscribe(res => {
      if (res) {
        this.buildingsDataSource = res.data as ListBuildingsCommandViewModel[];
        this.buildingsData = this.buildingsDataSource.slice();
      }
    });

    const usersQuery: ListUsersCommand = {
      skip: 0,
      take: 1000,
      sort: []
    };
    this.$users = this.userService.list(usersQuery);
    this.$users.subscribe(res => {
      this.usersDataSource = res.data as UserViewModel[];
      this.usersData = this.usersDataSource.slice();
    });
  }

  public ngOnInit() {
    this.createForm();

    if (this.editMode || this.copyMode) {
      this.getData(this.copyMode);
    } else {
      this._setAssignUserControlState();
      this.showForm = true;
    }
  }

  private convertServerDataToFormModel(result: ReadPickOrderCommandResult) {
    this.dataModel = result;
    this.patchFormValues(this.form, this.dataModel);

    if (this.originalFormState && this.originalFormState.pristine) {
      this.patchFormValues(this.originalFormState, this.dataModel);
    }
  }

  private patchFormValues(formGroup: FormGroup, dataModel: ReadPickOrderCommandResult) {
    formGroup.get('id').patchValue(this.dataModel.id);
    formGroup.get('dateNeeded').patchValue(new Date(this.dataModel.dateNeeded));
    formGroup.get('dateOfReturn').patchValue(new Date(this.dataModel.dateOfReturn));
    formGroup.get('courseName').patchValue(this.dataModel.courseName);
    formGroup.get('studentCount').patchValue(this.dataModel.studentCount);
    formGroup.get('customerId').patchValue(this.dataModel.customerId);
    formGroup.get('buildingId').patchValue(this.dataModel.buildingId);
    formGroup.get('assigneeUserId').patchValue(this.dataModel.assigneeUserId);
    formGroup.get('deliveryLocation').patchValue(this.dataModel.deliveryLocation);
    formGroup.get('orderNotes').patchValue(this.dataModel.orderNotes);
  }

  private getData(isCopy?: boolean) {
    this.loadingBar.useRef('router').start();
    this.pickOrderService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadPickOrderCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id);
        this.logger.debug(result);

        if (isCopy) {
          result.dateNeeded = new Date().toString();
          result.dateOfReturn = new Date().toString();
        }

        this.convertServerDataToFormModel(result);
        this.pickOrderStatus = result.pickOrderStatus!;

        if (
          this.pickOrderStatus === PickOrderStatus.Complete ||
          this.pickOrderStatus === PickOrderStatus.Closed ||
          this.pickOrderStatus === PickOrderStatus.Canceled
        ) {
          this.form.controls['dateNeeded'].disable();
          this.form.controls['courseName'].disable();
          this.form.controls['studentCount'].disable();
          this.form.controls['studentCount'].disable();
          this.form.controls['customerId'].disable();
          this.form.controls['deliveryLocation'].disable();
          this.form.controls['dateOfReturn'].disable();
          this.form.controls['orderNotes'].disable();
          this.form.controls['buildingId'].disable();
          this.form.controls['assigneeUserId'].disable();
        }

        this.showForm = true;
        this.loadingBar.useRef('router').complete();
      });

    this.getSupplies();
    this.getEquipment();
    this.getManikins();
  }

  public getSupplies() {
    const parmListPickOrderSuppliesCommand: ListPickOrderSuppliesCommand = {
      pickOrderId: this.id,
      take: this.pickOrderSuppliesState.take,
      skip: this.pickOrderSuppliesState.skip
    };
    this.pickOrderSupplyService
      .list(parmListPickOrderSuppliesCommand)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListPickOrderSuppliesCommandResult) => {
        if (result) {
          this.selectedSupplies = result.data as PickOrderSupplyViewModel[];
          this.logger.log('selectedSupplies has changed', this.selectedSupplies);
          if (this.copyMode) {
            this.selectedSupplies.forEach(e => (e.status = PickOrderInventoryItemStatus.NotSaved));
          }
        }
        this._setAssignUserControlState();
      });
  }

  public getEquipment() {
    const parmListPickOrderEquipmentCommand: ListPickOrderEquipmentCommand = {
      pickOrderId: this.id,
      take: this.pickOrderEquipmentState.take,
      skip: this.pickOrderEquipmentState.skip
    };
    this.pickOrderEquipmentService
      .list(parmListPickOrderEquipmentCommand)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListPickOrderEquipmentCommandResult) => {
        if (result) {
          this.selectedEquipment = result.data as PickOrderEquipmentViewModel[];
          if (this.copyMode) {
            this.selectedEquipment.forEach(e => (e.status = PickOrderInventoryItemStatus.NotSaved));
          }
        }
        this._setAssignUserControlState();
      });
  }

  public getManikins() {
    const parmListPickOrderManikinCommand: ListPickOrderManikinCommand = {
      pickOrderId: this.id,
      take: this.pickOrderManikinsState.take,
      skip: this.pickOrderManikinsState.skip
    };
    this.pickOrderManikinService
      .list(parmListPickOrderManikinCommand)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListPickOrderManikinCommandResult) => {
        if (result) {
          this.selectedManikins = result.data as PickOrderManikinViewModel[];
          if (this.copyMode) {
            this.selectedManikins.forEach(e => (e.status = PickOrderInventoryItemStatus.NotSaved));
          }
        }

        this._setAssignUserControlState();
      });
  }

  private createForm() {
    this.form = this.fb.group(
      {
        id: [''],
        dateNeeded: [new Date(), [Validators.required]],
        courseName: [''],
        studentCount: ['', Validators.required],
        customerId: ['', Validators.required],
        deliveryLocation: ['', Validators.required],
        dateOfReturn: [new Date(), [Validators.required]],
        orderNotes: [''],
        buildingId: ['', Validators.required],
        assigneeUserId: ['']
      },
      {
        validators: [this.customDateValidator]
      }
    );

    this.form.controls['dateNeeded'].markAsDirty();
    this.form.controls['dateOfReturn'].markAsDirty();
    this.originalFormState = this.form;
  }

  private customDateValidator(control: AbstractControl): ValidationErrors | null {
    const dateNeededErrors: {
      isAfterDateOfReturn?: true;
      isInPast?: true;
    } = {};

    const today = new Date(new Date().toDateString());

    if (control.get('dateNeeded').value < today) {
      dateNeededErrors.isInPast = true;
    }

    if (control.get('dateNeeded').value <= control.get('dateOfReturn').value) {
      control.get('dateOfReturn').setErrors(null);
    } else {
      dateNeededErrors.isAfterDateOfReturn = true;
      control.get('dateOfReturn').setErrors({ isBeforeNeeded: true });
      return { isBeforeNeeded: true };
    }

    if (Object.keys(dateNeededErrors).length) {
      control.get('dateNeeded').setErrors(dateNeededErrors);
      return { dateNeededErrors: dateNeededErrors };
    } else {
      control.get('dateNeeded').setErrors(null);
    }

    return null;
  }

  private _setAssignUserControlState() {
    if (!this.canAssignUser) {
      this.assigneeUserId.disable();
    } else {
      this.assigneeUserId.enable();
    }
  }

  public showSuppliesSelectionDialog() {
    const inputs = {
      title: 'Supplies Selection',
      selectedItems: this.selectedSupplies,
      buttons: ['Confirm', 'Cancel']
    };
  
    this.modalService.init(PickOrderSuppliesSelectionDialogComponent, inputs, {});
  
    const disposable = this.modalService.GetAnswer().subscribe(res => {
      disposable.unsubscribe();

      if (res && res.answer === 'Confirm') {
        const supplies = res.items as PickOrderSupplyViewModel[];

        supplies.forEach(e => {
          const existingItem = this.selectedSupplies.find(item => item.id === e.id);
          if (existingItem) {
            // Update existing item
            existingItem.quantityRequested = e.quantityRequested ?? existingItem.quantityRequested;
            existingItem.quantityAvailable = e.quantityAvailable ?? existingItem.quantityAvailable;
            existingItem.status = e.status ?? existingItem.status;
          } else {
            // Add new item if it doesn’t exist
            e.quantityRequested = 1;
            e.quantityAvailable = e.quantityAvailable ?? 0;
            e.status = PickOrderInventoryItemStatus.NotSaved;
            this.selectedSupplies.push(e);
          }
        });
      }
    });
  }

  public showEquipmentSelectionDialog() {
    const inputs = {
      title: 'Equipment Selection',
      selectedItems: this.selectedEquipment,
      buttons: ['Confirm', 'Cancel']
    };
  
    this.modalService.init(PickOrderEquipmentSelectionDialogComponent, inputs, {});
  
    const disposable = this.modalService.GetAnswer().subscribe(res => {
      disposable.unsubscribe();
  
      if (res && res.answer === 'Confirm') {
        const equipment = res.items as PickOrderEquipmentViewModel[];
  
        equipment.forEach(e => {
          const existingItem = this.selectedEquipment.find(item => item.id === e.id);
  
          if (existingItem) {
            // Update existing item
            existingItem.quantityRequested = e.quantityRequested ?? existingItem.quantityRequested;
            existingItem.quantityAvailable = e.quantityAvailable ?? existingItem.quantityAvailable;
            existingItem.status = e.status ?? existingItem.status;
          } else {
            // Add new item if it doesn’t exist
            e.quantityRequested = 1;
            e.quantityAvailable = e.quantityAvailable ?? 0;
            e.status = PickOrderInventoryItemStatus.NotSaved;
            this.selectedEquipment.push(e);
          }
        });
      }
    });
  }

  public showManikinsSelectionDialog() {
    const inputs = {
      title: 'Manikins Selection',
      selectedItems: this.selectedManikins,
      buttons: ['Confirm', 'Cancel']
    };
  
    this.modalService.init(PickOrderManikinsSelectionDialogComponent, inputs, {});

    const disposable = this.modalService.GetAnswer().subscribe(res => {
      disposable.unsubscribe();

      if (res && res.answer === 'Confirm') {
        const manikins = res.items as PickOrderManikinViewModel[];
        this.logger.debug('manikins', manikins);

        manikins.forEach(e => {
          const existingItem = this.selectedManikins.find(item => item.id === e.id);

          if (existingItem) {
            // Update existing item
            existingItem.quantityRequested = e.quantityRequested ?? existingItem.quantityRequested;
            existingItem.quantityAvailable = e.quantityAvailable ?? existingItem.quantityAvailable;
            existingItem.status = e.status ?? existingItem.status;
          } else {
            // Add new item if it doesn’t exist
            e.quantityRequested = 1;
            e.quantityAvailable = e.quantityAvailable ?? 0;
            e.status = PickOrderInventoryItemStatus.NotSaved;
            this.selectedManikins.push(e);
          }
        });
      }
    });
  }
  

  public pickOrderSuppliesState: State = {
    skip: 0,
    take: 1000,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public pickOrderManikinsState: State = {
    skip: 0,
    take: 1000,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public pickOrderEquipmentState: State = {
    skip: 0,
    take: 1000,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public save() {
    this.onSubmit(false);
  }

  public cancel() {
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this._updatePickOrder(closeOnSuccess);
    } else {
      this._createPickOrder();
    }
  }

  private _updatePickOrder(closeOnSuccess) {
    this.updatePickOrderCommand = this.form.value;

    this.selectedSupplies.forEach(e => {
      e.pickOrderId = this.updatePickOrderCommand.id;
    });
    this.updatePickOrderCommand.pickOrderSupplies = this.selectedSupplies;

    this.selectedEquipment.forEach(e => {
      e.pickOrderId = this.updatePickOrderCommand.id;
    });
    this.updatePickOrderCommand.pickOrderEquipment = this.selectedEquipment;

    this.selectedManikins.forEach(e => {
      e.pickOrderId = this.updatePickOrderCommand.id;
    });
    this.updatePickOrderCommand.pickOrderManikins = this.selectedManikins;

    this.pickOrderService
      .update(this.updatePickOrderCommand.id.toString(), this.updatePickOrderCommand)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: UpdatePickOrderCommandResult) => {
        if (closeOnSuccess) {
          this.convertServerDataToFormModel(result);
          this.toastService.success('Success!', 'This item has been updated.');
          this.routeToEntityList();
        } else {
          this.convertServerDataToFormModel(result);
          this.toastService.success('Success!', 'This item has been saved.');
          this.getData();
        }
      });
  }

  private _createPickOrder() {
    this.createPickOrderCommand = this.form.value;
    // this.createPickOrderCommand.buildingId;
    this.createPickOrderCommand.pickOrderSupplies = this.selectedSupplies;
    this.createPickOrderCommand.pickOrderEquipment = this.selectedEquipment;
    this.createPickOrderCommand.pickOrderManikins = this.selectedManikins;
    this.pickOrderService
      .create(this.createPickOrderCommand)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: CreatePickOrderCommandResult) => {
        this.convertServerDataToFormModel(result);
        this.routeToEntityList();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
        this.getData();
      });
  }

  public onCustomerFilterChange(value) {
    this.customersData = this.customersDataSource.filter(s => s.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onBuildingsFilterChange(value) {
    this.buildingsData = this.buildingsDataSource.filter(s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onUsersFilterChange(value) {
    this.usersData = this.usersDataSource.filter(s => s.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onCustomerSelectionChange(value: CustomerViewModel): void {
    const customer = this.customersDataSource.filter(e => e.id == value.id)![0];
    if (customer && customer.buildingId) {
      this.form.patchValue({ buildingId: customer.buildingId });
    }
  }

  public onInventoryItemChange(items: any, inventoryItemType: InventoryItemType): void {
    switch (inventoryItemType) {
      case InventoryItemType.Supply:
        this.selectedSupplies = items;
        break;
      case InventoryItemType.Equipment:
        this.selectedEquipment = items;
        break;
      case InventoryItemType.Manikin:
        this.selectedManikins = items;
        break;
    }

    this._setAssignUserControlState();
  }

  public reserveAllAvailable(inventoryItemType: InventoryItemType) {
    switch (inventoryItemType) {
      case InventoryItemType.Supply:
        this.reserveSupplies(this.selectedSupplies);
        break;

      case InventoryItemType.Equipment:
        this.reserveEquipment(this.selectedEquipment);
        break;

      case InventoryItemType.Manikin:
        this.reserveManikins(this.selectedManikins);
        break;
    }
  }

  public reserveSupplies(items: any[]) {
    items = items.filter(
      e =>
        e.quantityRequested <= e.quantityAvailable &&
        e.id &&
        e.status != PickOrderInventoryItemStatus.Pending &&
        e.status != PickOrderInventoryItemStatus.Reserved
    );

    if (this.editMode && this.pickOrderStatus === PickOrderStatus.InProgress && items.length) {
      const reservePickOrderSuppliesCommand: ReservePickOrderSuppliesCommand = {
        pickOrderId: this.id,
        inventoryReservationViewModels: []
      };

      items.forEach(e => {
        const reservationItem: InventoryReservationViewModel = {
          id: e.id,
          inventoryItemId: e.inventoryItemId,
          quantity: e.quantityRequested,
          inventoryItemType: InventoryItemType.Supply
        };
        reservePickOrderSuppliesCommand.inventoryReservationViewModels.push(reservationItem);
      });

      this.pickOrderSupplyService
        .reserveSupplies(reservePickOrderSuppliesCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toastService.success('Success!', 'Reservation submitted.');
          this.getSupplies();
        });
    } else {
      this.toastService.information(
        'Info!',
        "We're unable to process this request at the moment. The Pick Order either isn't in Edit mode or there aren't any available inventory items to be processed."
      );
    }
  }

  public reserveEquipment(items: any[]) {
    items = items.filter(
      e =>
        e.quantityRequested <= e.quantityAvailable &&
        e.id &&
        e.status != PickOrderInventoryItemStatus.Pending &&
        e.status != PickOrderInventoryItemStatus.Reserved
    );

    if (this.editMode && this.pickOrderStatus === PickOrderStatus.InProgress && items.length) {
      const reservePickOrderEquipmentCommand: ReservePickOrderEquipmentCommand = {
        pickOrderId: this.id,
        inventoryReservationViewModels: []
      };

      items.forEach(e => {
        const reservationItem: InventoryReservationViewModel = {
          id: e.id,
          inventoryItemId: e.inventoryItemId,
          quantity: e.quantityRequested,
          inventoryItemType: InventoryItemType.Equipment
        };
        reservePickOrderEquipmentCommand.inventoryReservationViewModels.push(reservationItem);
      });

      this.pickOrderEquipmentService
        .reserveEquipment(reservePickOrderEquipmentCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toastService.success('Success!', 'Reservation submitted.');
          this.getEquipment();
        });
    } else {
      this.toastService.information(
        'Info!',
        "We're unable to process this request at the moment. The Pick Order either isn't in Edit mode or there aren't any available inventory items to be processed."
      );
    }
  }

  public reserveManikins(items: any[]) {
    items = items.filter(
      e =>
        e.quantityRequested <= e.quantityAvailable &&
        e.id &&
        e.status != PickOrderInventoryItemStatus.Pending &&
        e.status != PickOrderInventoryItemStatus.Reserved
    );

    if (this.editMode && this.pickOrderStatus === PickOrderStatus.InProgress && items.length) {
      const reservePickOrderManikinsCommand: ReservePickOrderManikinsCommand = {
        pickOrderId: this.id,
        inventoryReservationViewModels: []
      };

      items.forEach(e => {
        const reservationItem: InventoryReservationViewModel = {
          id: e.id,
          inventoryItemId: e.inventoryItemId,
          quantity: e.quantityRequested,
          inventoryItemType: InventoryItemType.Manikin
        };
        reservePickOrderManikinsCommand.inventoryReservationViewModels.push(reservationItem);
      });

      this.pickOrderManikinService
        .reserveManikins(reservePickOrderManikinsCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toastService.success('Success!', 'Reservation submitted.');
          this.getManikins();
        });
    } else {
      this.toastService.information(
        'Info!',
        "We're unable to process this request at the moment. The Pick Order either isn't in Edit mode or there aren't any available inventory items to be processed."
      );
    }
  }

  public showInventoryItemsAction(action: string): boolean {
    switch (action) {
      case 'add':
        return this.pickOrderStatus === undefined ||
          this.pickOrderStatus === PickOrderStatus.Started ||
          this.pickOrderStatus === PickOrderStatus.InProgress
          ? true
          : false;

      case 'reserveAll':
      case 'refresh':
        return this.pickOrderStatus === PickOrderStatus.InProgress ? true : false;

      default:
        return false;
    }
  }

  public fillOrder(): void {
    //redirect user to the fill order page
    this.router.navigate([`/pick-orders/${this.id}/pull-or-return`]);
  }

  public canFillOrder(): boolean {
    if (this.pickOrderStatus === undefined || this.pickOrderStatus !== 'InProgress') {
      return false;
    }

    const areAllSuppliesReserved =
      !this.selectedSupplies.length ||
      (this.selectedSupplies.length > 0 &&
        this.selectedSupplies.every(
          s => s.status === PickOrderInventoryItemStatus.Reserved || s.status === PickOrderInventoryItemStatus.CheckedOut
        ));

    const areAllEquipmentReserved =
      !this.selectedEquipment.length ||
      (this.selectedEquipment.length > 0 &&
        this.selectedEquipment.every(
          s => s.status === PickOrderInventoryItemStatus.Reserved || s.status === PickOrderInventoryItemStatus.CheckedOut
        ));

    const areAllManikinsReserved =
      !this.selectedManikins.length ||
      (this.selectedManikins.length > 0 &&
        this.selectedManikins.every(
          s => s.status === PickOrderInventoryItemStatus.Reserved || s.status === PickOrderInventoryItemStatus.CheckedOut
        ));

    return areAllSuppliesReserved && areAllEquipmentReserved && areAllManikinsReserved;
  }

  public isSaveAvailable(): boolean {
    if (!this.form.valid) {
      return true;
    }

    if (
      this.pickOrderStatus === PickOrderStatus.Complete ||
      this.pickOrderStatus === PickOrderStatus.Closed ||
      this.pickOrderStatus === PickOrderStatus.Canceled
    ) {
      return true;
    }

    return false;
  }
}
