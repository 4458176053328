import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import {
  CategoryService,
  CreateCategoryCommand,
  CreateCategoryCommandResult,
  CreateEquipmentCommand,
  CreateEquipmentCommandResult,
  EntityType,
  EnumValue,
  EquipmentService,
  ListCategoryViewModel,
  ListCategorysCommand,
  ListCategorysCommandResult,
  ListEquipmentTypesCommandResult,
  ListInventoryItemLocationQuantitiesForInventoryItemCommandResult,
  ListVendorsCommand,
  ListVendorsCommandResult,
  OrganizationService,
  ReadEquipmentCommandResult,
  SupplyLocationQuantityService,
  UpdateEquipmentCommand,
  VendorService,
  VendorViewModel
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss']
})
export class EquipmentDetailComponent extends EntityBaseComponent implements OnInit {
  ReadEquipmentCommandResult: ReadEquipmentCommandResult = {};
  dataModel: ReadEquipmentCommandResult;
  UpdateEquipmentCommand: UpdateEquipmentCommand = {};
  CreateEquipmentCommand: CreateEquipmentCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  inventoryItemTaskTypes: EnumValue[] = [];
  public $inventoryItemTypes: Observable<ListEquipmentTypesCommandResult>;
  public formGroup: FormGroup;
  public locationsAndStocks: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };
  public listInventoryItemLocationQuantitiesForInventoryItemCommandResult: Observable<ListInventoryItemLocationQuantitiesForInventoryItemCommandResult>;
  public $categories: Observable<ListCategorysCommandResult>;
  public listItems: any[] = [];
  public $vendors: Observable<ListVendorsCommandResult>;
  public vendorDefaultItem: VendorViewModel = {
    id: -1,
    vendorName: 'Select an item...'
  };

  get name() {
    return this.form.get('name');
  }

  get stockingQuantityUnitOfMeasurement() {
    return this.form.get('stockingQuantityUnitOfMeasurement');
  }

  get description() {
    return this.form.get('description');
  }

  get notes() {
    return this.form.get('notes');
  }

  get modelNumber() {
    return this.form.get('modelNumber');
  }

  get trainingLink() {
    return this.form.get('trainingLink');
  }

  get manualLink() {
    return this.form.get('manualLink');
  }

  get manufacturerVendorId() {
    return this.form.get('manufacturerVendorId');
  }

  get manufacturerNumber() {
    return this.form.get('manufacturerNumber');
  }

  get categories() {
    return this.form.get('categories');
  }

  constructor(
    private logger: NGXLogger,
    private categoryService: CategoryService,
    public cachedDataService: CachedDataService,
    impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private equipmentService: EquipmentService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    injector: Injector
  ) {
    super(EntityGlobals.EQUIPMENT, injector);
    this.$inventoryItemTypes = this.equipmentService.listEquipmentTypes();

    const listVendorsCommand: ListVendorsCommand = {
      take: 1000,
      skip: 0
    };
    this.$vendors = this.vendorService.list(listVendorsCommand);

    const cmd: ListCategorysCommand = {
      entityType: EntityType.Equipment
    };
    this.$categories = this.categoryService.list(cmd);
    this.$categories.subscribe((value: ListCategorysCommandResult) => {
      this.logger.debug('Categories for Supplies', value);
      this.listItems = value.data;
    });
  }

  public ngOnInit() {
    this._createForm();

    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
    this._refreshDataOnPage();
  }

  private _refreshDataOnPage() {
    this.listInventoryItemLocationQuantitiesForInventoryItemCommandResult = this.SupplyLocationQuantityService.forInventoryItem(this.id);
    this.locationsAndStocks = this.listInventoryItemLocationQuantitiesForInventoryItemCommandResult.pipe(
      map(data => {
        return process(data.data, this.gridState);
      })
    );
  }

  private _convertServerDataToFormModel(result: ReadEquipmentCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData(): void {
    this.equipmentService.read(this.id).subscribe((result: ReadEquipmentCommandResult) => {
      this.UpdateBreadcrumbPageTitle(result.id, result.name);
      this._convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      minimumStockingQuantity: [0],
      stockingQuantityUnitOfMeasurement: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],
      customId: [''],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      manufacturerVendorId: [null, Validators.required],
      modelNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      //TODO: add URL validator for training and manual links
      trainingLink: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      manualLink: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      manufacturerNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],

      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: [''],
      categories: ['']
    });
    this.originalFormState = this.form;
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this._updateEquipment(closeOnSuccess);
    } else {
      this._createEquipment();
    }
  }

  private _updateEquipment(closeOnSuccess: boolean) {
    this.UpdateEquipmentCommand = this.form.value;
    this.UpdateEquipmentCommand.categories = this.categories.value ? this.categories.value?.map(item => item.id) : null;

    this.equipmentService.update(this.UpdateEquipmentCommand.id.toString(), this.UpdateEquipmentCommand).subscribe(result => {
      if (closeOnSuccess) {
        this.routeToEntityList();
      } else {
        this._convertServerDataToFormModel(result);
        this.toastService.success('Success!', 'This item has been saved.');
      }
    });
  }

  private _createEquipment() {
    this.CreateEquipmentCommand = this.form.value;
    this.CreateEquipmentCommand.categories = this.categories.value ? this.categories.value?.map(item => item.id) : null;

    this.equipmentService.create(this.CreateEquipmentCommand).subscribe((result: CreateEquipmentCommandResult) => {
      this.routeToEntityList();
      this.editMode = true;
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {
    //TODO: implement
  }

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this inventoryItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }

  public valueNormalizer = (text$: Observable<string>): any =>
    text$.pipe(
      switchMap((text: string) => {
        // Search in values
        const matchingValue: any = this.listItems.find((item: ListCategoryViewModel) => {
          // Search for matching item to avoid duplicates
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        if (matchingValue) {
          // Return the already selected matching value and the component will remove it
          return of(matchingValue);
        }

        // Search in data
        const matchingItem: any = this.listItems.find((item: ListCategoryViewModel) => {
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        this.logger.debug(text, matchingValue, matchingItem);

        if (matchingItem) {
          return of(matchingItem);
        } else {
          return of(text).pipe(switchMap(this.service$));
        }
      })
    );

  public service$ = (text: string): any => {
    const cmd: CreateCategoryCommand = {
      name: text,
      entityType: EntityType.Equipment
    };
    const done = this.categoryService.create(cmd);
    done.subscribe((value: CreateCategoryCommandResult) => {
      this.listItems.push(value);
    });
    return done;
  };
}
